import fetchStorageOffer from '../../energyBackupSetup/actions/fetchStorageOffer';
import {
    fetchFinancialProductsPlanMe,
    fetchFinancierInstallers,
} from '../../proposalGeneratorFinancingAndInsurance/actions';
import fetchSegments from '../../proposalGeneratorPanelsLayout/actions/fetchSegments';
import {
    fetchOfferProducts,
    fetchProposalCatalogs,
    fetchTemplates,
} from '../../proposalGeneratorQuote/actions';
import { fetchTimeShiftingConfiguration } from '../../timeShiftingConfiguration/actions';

import cleanView from './cleanView';
import fetchOfferConsumption from './fetchOfferConsumption';
import fetchOfferDetails from './fetchOfferDetails';
import fetchProfiles from './fetchProfiles';
import setIsLocked from './setIsLocked';

export default (id) => (dispatch) => {
    dispatch(cleanView());
    Promise.all([
        dispatch(fetchOfferDetails(id)),
        dispatch(fetchOfferConsumption(id)),
        dispatch(fetchFinancierInstallers()),
        dispatch(fetchFinancialProductsPlanMe(id)),
        dispatch(fetchOfferProducts(id)),
        dispatch(fetchProposalCatalogs(id)),
        dispatch(fetchSegments(id)),
        dispatch(fetchStorageOffer(id)),
        dispatch(fetchProfiles(id)),
        dispatch(fetchTimeShiftingConfiguration(id)),
    ])
        .then(
            () =>
                new Promise((resolve) => {
                    dispatch(fetchTemplates())
                        .then(() => resolve())
                        .catch(() => resolve());
                })
        )
        .catch(() => dispatch(setIsLocked(false)));
};
