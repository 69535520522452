import i18next from 'i18next';
import get from 'lodash/get';
import { types } from 'sunwise-template-core';

import {
    createSmartDocument,
    finishSmartDocumentProposal,
} from 'common/api/v1/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getDefaultName } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_OFFER_SMART_DOCUMENT,
    SAVE_OFFER_SMART_DOCUMENT_FAILURE,
    SAVE_OFFER_SMART_DOCUMENT_SUCCESS,
} from '../../actionTypes';
import { multiTemplateReviewActions } from '../../reducer';
import * as selectors from '../../selectors';

import fetchOfferSmartDocuments from './fetchOfferSmartDocuments';
import openOfferSmartDocumentModal from './openOfferSmartDocumentModal';

export default (handleClickDocumentItem, proposalId, values) =>
    (dispatch, getState) => {
        const state = getState();
        const documentTemplates = selectors.getDocumentTemplatesData(state);
        const offerSmartDocuments = selectors.getOfferSmartDocumentsData(state);

        const templateName = documentTemplates.find(
            (item) => item.id === values.custom_offer_template
        )?.title;

        const name = getDefaultName({
            arrayItems: offerSmartDocuments,
            preffix: i18next.t('Smart document'),
            projectName: templateName,
        });

        dispatch(multiTemplateReviewActions[SAVE_OFFER_SMART_DOCUMENT]());

        createSmartDocument({
            ...values,
            external_custom_template_id: values.custom_offer_template,
            name,
        })
            .then((response) => {
                const data = get(response, 'data.data', {});
                const id = get(data, 'id', null);
                if (id)
                    finishSmartDocumentProposal(id)
                        .then(() => {
                            const { id, name } = get(response, 'data.data', {});
                            dispatch(
                                multiTemplateReviewActions[
                                    SAVE_OFFER_SMART_DOCUMENT_SUCCESS
                                ](data)
                            );
                            dispatch(fetchOfferSmartDocuments(proposalId));
                            handleClickDocumentItem({
                                id,
                                name,
                                type: types.SMART_DOCUMENTS_TYPE,
                            });
                            dispatch(openOfferSmartDocumentModal(false));
                            showToast({
                                body: i18next.t('It was created successfully'),
                            });
                        })
                        .catch((error) => {
                            dispatch(
                                multiTemplateReviewActions[
                                    SAVE_OFFER_SMART_DOCUMENT_FAILURE
                                ](error?.response?.data?.errors)
                            );
                            showReponseErrorsAsAlert(dispatch, error?.response);
                        });
                else
                    dispatch(
                        multiTemplateReviewActions[
                            SAVE_OFFER_SMART_DOCUMENT_FAILURE
                        ]([])
                    );
            })
            .catch((error) => {
                dispatch(
                    multiTemplateReviewActions[
                        SAVE_OFFER_SMART_DOCUMENT_FAILURE
                    ](error?.response?.data?.errors)
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
