import get from 'lodash/get';

import { getItem } from 'common/api/v1/integrationsEmbeddedProposals';

import {
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
} from '../actionTypes';
import { handleLists } from '../helpers';
import { integrationWebActions } from '../reducer';

import initializeForm from './initializeForm';

export default (uuid) => (dispatch) => {
    dispatch(integrationWebActions[FETCH_DATA]());

    getItem(uuid)
        .then((response) => {
            const data = get(response, 'data', {});
            dispatch(integrationWebActions[FETCH_DATA_SUCCESS](data));

            const isGlobal = data.branch_office === null;

            const values = {
                ...data,
                agent: data.agent,
                branch_office: isGlobal ? 'company' : data.branch_office,
                install_type: handleLists(data.install_type),
                is_global: isGlobal,
                method_consumtion: handleLists(data.method_consumtion),
                service_number: { 1: data.with_service_number },
                style: data.styles,
            };

            dispatch(initializeForm(values));
        })
        .catch((error) =>
            dispatch(integrationWebActions[FETCH_DATA_FAILURE](error))
        );
};
