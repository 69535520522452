import React, { useEffect } from 'react';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionProduct from 'common/components/accordions/AccordionProduct';
import { IconPanel } from 'common/components/icons';
import { DEBOUNCE_TIME } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getDecimals, numberFormat } from 'common/utils/helpers';

import {
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFieldsBuild,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelFieldBuild,
    handleNormalizePercentageDiscountField,
    handleRemoveBuild,
} from '../proposalGeneratorQuote/helpers';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import * as selectors from './selectors';

const Container = ({
    createProduct,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    getPermissionsByCode,
    initialize,
    isFetching,
    isLoading,
    isLocked = false,
    isUpdating,
    offerPanels,
    panels,
    prepareDelete,
    prepareUpdate,
    proposalId,
    resetForm,
    totalCost,
    typeChange,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isEmpty(offerPanels)) {
            initialize(offerPanels);
        }

        return () => {
            resetForm();
        };
    }, [offerPanels]);

    const { canModify: canModifyProduct } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PANELS_PERMISSION
    );

    const { canModify: canModifyTotal } = getPermissionsByCode(
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS
    );

    const handleOnSave = React.useRef(
        debounce(prepareUpdate, DEBOUNCE_TIME)
    ).current;

    const handleNormalizeFields = handleNormalizeFieldsBuild(
        createProduct,
        handleOnSave,
        proposalId
    );

    const handleNormalizeModelField = handleNormalizeModelFieldBuild(
        createProduct,
        handleOnSave,
        proposalId
    );

    const handleRemove = handleRemoveBuild(prepareDelete, proposalId);

    const isLoadingForm = isFetching || isLoading || isUpdating;

    const isLockedForm = isLoadingForm || isLocked;

    return (
        <AccordionProduct
            caption={t('Total price')}
            cardId="proposal_generator_panel_card"
            isLoading={isLoadingForm}
            isOpen={false}
            svgIcon={<IconPanel />}
            title={t('Solar panel')}
            total={
                canModifyTotal
                    ? numberFormat(totalCost, {
                          currency: currencyIso,
                          decimals: getDecimals(totalCost),
                          locale: currencyLocale,
                          style: 'currency',
                      })
                    : null
            }
        >
            <CardForm
                canModifyProduct={canModifyProduct}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                handleClickDelete={handleClickDelete}
                handleNormalizeDiscountField={handleNormalizeDiscountField}
                handleNormalizeField={handleNormalizeField}
                handleNormalizeFields={handleNormalizeFields}
                handleNormalizeMarginField={handleNormalizeMarginField}
                handleNormalizeModel={handleNormalizeModel}
                handleNormalizeModelField={handleNormalizeModelField}
                handleNormalizePercentageDiscountField={
                    handleNormalizePercentageDiscountField
                }
                handleRemove={handleRemove}
                isLoading={isLoadingForm}
                isLocked={isLockedForm}
                itemsData={panels}
                offerItems={
                    offerPanels &&
                    offerPanels.map((item) => item.panel_branch_office)
                }
                typeChange={typeChange}
            />
        </AccordionProduct>
    );
};

const mapStateToProps = createStructuredSelector({
    panels: proposalGeneratorQuoteSelectors.getProposalPanelCatalogs,
    isFetching: selectors.getIsFetchingOfferPanel,
    isUpdating: selectors.getIsUpdating,
    offerPanels: proposalGeneratorQuoteSelectors.getOfferPanelsData,
    totalCost: proposalGeneratorQuoteSelectors.getFinalCostPanels,
});

const mapDispatchToProps = (dispatch) => ({
    createProduct: (proposalId, values) =>
        dispatch(actions.create(proposalId, values)),
    initialize: (values) => dispatch(actions.initialValues(values)),
    prepareDelete: (id, proposalId, successCallback) =>
        dispatch(actions.prepareDelete(id, proposalId, successCallback)),
    prepareUpdate: (proposalId, valuesForm) =>
        dispatch(actions.prepareUpdate(proposalId, valuesForm)),
    resetForm: () => dispatch(actions.resetForm()),
});

Container.propTypes = {
    createProduct: PropTypes.func,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    getPermissionsByCode: PropTypes.func,
    initialize: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isUpdating: PropTypes.bool,
    offerPanels: PropTypes.array,
    panels: PropTypes.array,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    proposalId: PropTypes.string,
    resetForm: PropTypes.func,
    totalCost: PropTypes.number,
    typeChange: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_PANELS_PERMISSION,
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
    ])
)(Container);
