import i18next from 'i18next';

import { updateTemplates } from 'common/api/v1/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

import filterTemplates from './filterTemplates';

export default (ids, value, filterData, callback) => (dispatch) => {
    dispatch(templateLibraryActions[BULK_UPDATE_ITEMS]());

    const templates = ids.map((id) => ({ id, status: value }));

    updateTemplates(templates)
        .then(() => {
            dispatch(templateLibraryActions[BULK_UPDATE_ITEMS_SUCCESS]());
            if (filterData) dispatch(filterTemplates(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            else showToast();
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
