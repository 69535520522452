import React, { useEffect, useRef, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import { DEBOUNCE_TIME } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';

const ListItems = ({
    canModify,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickUpdateTracking,
    isFetching,
    isOpenModalTracking,
    items,
    paginationData,
    setFilterData,
    tableSettings,
    users,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedBulkOption, setSelectedBulkOption] = useState('');
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        handleOnFilter(filterData);
    }, [filterData]);

    useEffect(() => {
        if (!isOpenModalTracking) setSelectedBulkOption('');
    }, [isOpenModalTracking]);

    const callback = () => {
        setSelectedBulkOption('');
        setSelectionModel([]);
    };

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSelectStatus = (item) => {
        const filter = {
            ...filterData,
            completed: item.value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSelectUser = (item) => {
        const filter = {
            ...filterData,
            userId: item.value,
            page: 0,
        };
        setFilterData(filter);
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <>
            <BulkOperationsCard
                callback={callback}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleClickUpdateTracking={handleClickUpdateTracking}
                handleOnSelectStatus={handleOnSelectStatus}
                handleOnSelectUser={handleOnSelectUser}
                selectedBulkOption={selectedBulkOption}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                users={users}
            />
            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={COLUMNS_CONFIG}
                        tableKey="trackigns"
                    />
                    <DataGrid
                        autoHeight
                        checkboxSelection={canModify ? true : false}
                        columns={Columns({
                            callback,
                            handleClickBulkItems,
                            hiddenColumns: getHiddenColumns(tableSettings),
                            orders: getOrderedColumns(tableSettings),
                            users,
                        })}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onRowModesModelChange={(newModel) =>
                            setRowModesModel(newModel)
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdateTracking: PropTypes.func,
    isFetching: PropTypes.bool,
    isOpenModalTracking: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

export default ListItems;
