import React, { useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';

import { arraytoDictionary } from 'common/utils/helpers';

const SplitButton = ({ callback, options, redirect }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('preview');
    const dictionaryOptions = arraytoDictionary(options, 'value');

    const handleClick = () => {
        const defaultButton = dictionaryOptions[selectedOption];
        callback();
        redirect(defaultButton?.url);
    };

    const handleMenuItemClick = ({ url, value }) => {
        setSelectedOption(value);
        setOpen(false);
        callback();
        redirect(url);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="outlined" ref={anchorRef}>
                <Button onClick={handleClick} sx={{ textTransform: 'initial' }}>
                    {dictionaryOptions[selectedOption]?.label}
                </Button>
                <Button size="small" onClick={handleToggle}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{ zIndex: 1 }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map(
                                        ({ label, url, value }, index) => (
                                            <MenuItem
                                                key={`option-${index}`}
                                                selected={
                                                    value === selectedOption
                                                }
                                                onClick={() =>
                                                    handleMenuItemClick({
                                                        url,
                                                        value,
                                                    })
                                                }
                                            >
                                                {label}
                                            </MenuItem>
                                        )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

SplitButton.propTypes = {
    callback: PropTypes.func,
    options: PropTypes.array,
    redirect: PropTypes.func,
};

export default SplitButton;
