import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import MasterButton from 'common/modules/masterButton';
import { formatDate, parseDate } from 'common/utils/dates';

import * as funnelProjectsActions from '../funnelProjects/actions';
import * as funnelProjectsSelectors from '../funnelProjects/selectors';
import * as listProjectsActions from '../listProjects/actions';
import * as listProjectsSelectors from '../listProjects/selectors';
import * as projectActions from '../project/actions';

import * as actions from './actions';
import Filters from './components/Filters';
import ProjectsContainer from './components/ProjectsContainer';
import { buildFilterQuery } from './helpers';
import * as selectors from './selectors';

const Container = ({
    downloadProjectsFile,
    fetchFunnelProjects,
    fetchListProjects,
    fetchStatusProject,
    filterItemSelected,
    isLoadingFunnelProjects,
    isLoadingListProjects,
    resetValues,
    selectFilterItem,
}) => {
    const { t } = useTranslation();
    const [filterQuery, setFilterQuery] = useState(
        `creation_range=${formatDate(
            parseDate(filterItemSelected.initial_date, 'dd/MM/yyyy'),
            'yyyy-MM-dd'
        )},${formatDate(
            parseDate(filterItemSelected.final_date, 'dd/MM/yyyy'),
            'yyyy-MM-dd'
        )}`
    );
    const [filterName, setFilterName] = useState('');
    const [viewMode, setviewMode] = useState(
        localStorage.getItem('funnelViewMode')
            ? localStorage.getItem('funnelViewMode')
            : 'column'
    );

    useEffect(() => {
        fetchStatusProject();
        return () => {
            resetValues();
        };
    }, []);

    useEffect(() => {
        const tempQuery = buildFilterQuery({
            ...filterItemSelected,
            project_name: filterName,
        });

        if (tempQuery !== filterQuery) setFilterQuery(tempQuery);
    }, [filterItemSelected, filterQuery, filterName]);

    const handleSetViewMode = (value) => {
        if (
            value === 'column' &&
            filterItemSelected &&
            filterItemSelected.type === 'status'
        ) {
            selectFilterItem({
                ...filterItemSelected,
                type: '',
                value: '',
            });
        }
        localStorage.setItem('funnelViewMode', value);
        setviewMode(value);
    };

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer mbDivider="8px">
                <Grid item xs={18} md={12}>
                    <TitleIcon fontWeight="bold" title={t('Project funnel')} />
                </Grid>
                <Grid item xs={18} md={6} textAlign="right">
                    <MasterButton.Container
                        defaultButton="create-project"
                        masterButtonMode={false}
                        onSucccess={() => {
                            fetchFunnelProjects(filterQuery);
                            fetchListProjects(filterQuery);
                        }}
                    />
                </Grid>
            </HeaderContainer>
            <Filters
                downloadProjectsFile={downloadProjectsFile}
                filterItemSelected={filterItemSelected}
                filterName={filterName}
                handleSetViewMode={handleSetViewMode}
                isLoadingFunnelProjects={isLoadingFunnelProjects}
                isLoadingListProjects={isLoadingListProjects}
                selectFilterItem={selectFilterItem}
                setFilterName={setFilterName}
                viewMode={viewMode}
            />
            <ProjectsContainer filterQuery={filterQuery} viewMode={viewMode} />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoadingFunnelProjects: funnelProjectsSelectors.getIsFetchingFunnelColumns,
    isLoadingListProjects: listProjectsSelectors.getIsFetchingProjects,
    filterItemSelected: selectors.getFilterItemSelected,
});

const mapDispatchToProps = (dispatch) => ({
    downloadProjectsFile: (downloadFilter, closeLoadingView) =>
        dispatch(
            actions.downloadProjectsFile(downloadFilter, closeLoadingView)
        ),
    fetchFunnelProjects: (filterQuery) =>
        dispatch(funnelProjectsActions.fetchFunnelProjects(filterQuery)),
    fetchListProjects: (filterQuery) =>
        dispatch(listProjectsActions.fetchProjects(filterQuery)),
    fetchStatusProject: () => dispatch(projectActions.fetchStatusProject()),
    resetValues: () => dispatch(actions.resetValues()),
    selectFilterItem: (filterItem) =>
        dispatch(actions.selectFilterItem(filterItem)),
});

Container.propTypes = {
    downloadProjectsFile: PropTypes.func,
    fetchFunnelProjects: PropTypes.func,
    fetchListProjects: PropTypes.func,
    fetchStatusProject: PropTypes.func,
    filterItemSelected: PropTypes.object,
    isLoadingFunnelProjects: PropTypes.bool,
    isLoadingListProjects: PropTypes.bool,
    resetValues: PropTypes.func,
    selectFilterItem: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
