import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { assignBranchOffice } from 'common/utils/helpers';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

import contactForm from '../../contactForm';

export default () => (dispatch, getState) => {
    const branchOffices = multiBranchesSelectors.getFilteredBranchesData(
        getState()
    );
    const session = getSessionLocalStorage();

    const newValues = {
        agent: session.id,
        branch_office: assignBranchOffice(branchOffices),
    };

    dispatch(contactForm.actions.initializeForm(newValues));
    dispatch(contactForm.actions.setIsNewRecord(true));
    dispatch(contactForm.actions.showModal(true));
};
