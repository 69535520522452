import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { Box, Button, Typography } from 'sunwise-ui';

import ModalDialog from 'common/components/ModalDialog';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import EmptyProposals from 'resources/EmptyProposals.png';

import * as proposalReviewProActions from '../proposalReviewPro/actions';
import * as proposalReviewProSelectors from '../proposalReviewPro/selectors';

import * as actions from './actions';
import Preview from './components/Preview';
import SplitButton from './components/SplitButton';
import * as selectors from './selectors';

const StyledImg = styled('img')`
    width: 248px;
    @media (min-width: 768px) and (max-width: 992px) {
        width: 200px;
    }
    @media (min-width: 0) and (max-width: 767px) {
        width: 140px;
    }
`;

const Container = ({
    canModify,
    fetchOfferInfoPatern,
    handleCloseModal,
    isFetchingOfferInfoPatern,
    isGenerated,
    isOpenModal,
    offerInfoPatern,
    proposalId,
    redirect,
    setCurrentProposal,
}) => {
    const { t } = useTranslation();
    const [editionDisabled, setEditionDisabled] = useState(true);

    const callback = () => {
        handleCloseModal();
        setCurrentProposal(null);
    };

    const renderContent = () => {
        if (isGenerated)
            return (
                <Preview
                    canModify={canModify}
                    editionDisabled={editionDisabled}
                    isFetching={isFetchingOfferInfoPatern}
                    offerInfoPatern={offerInfoPatern}
                    proposalId={proposalId}
                    setEditionDisabled={setEditionDisabled}
                    templateType={types.ONE_PROPOSAL_TYPE}
                />
            );
        return (
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                gap={1}
                p={1}
            >
                <StyledImg src={EmptyProposals} />
                <Typography variant="h6">
                    {t('The proposal has not yet been generated')}
                </Typography>
            </Box>
        );
    };

    return (
        <ModalDialog
            footerComponent={() => (
                <Box display="flex" gap={2} justifyContent="flex-end" py={1}>
                    {isGenerated ? (
                        <SplitButton
                            callback={callback}
                            options={[
                                {
                                    label: t('Go to preview'),
                                    url: `/proposal-review-pro/${proposalId}`,
                                    value: 'preview',
                                },
                                {
                                    label: t('Go to the proposer'),
                                    url: `/proposal/${proposalId}`,
                                    value: 'proposer',
                                },
                            ]}
                            redirect={redirect}
                        />
                    ) : (
                        <Button
                            component={RouterLink}
                            onClick={() => callback()}
                            to={`/proposal/${proposalId}`}
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                        >
                            {t('Go to the proposer')}
                        </Button>
                    )}
                </Box>
            )}
            onClose={() => callback()}
            onEnter={() => fetchOfferInfoPatern(proposalId)}
            open={isOpenModal}
            title={t('Preview')}
        >
            {renderContent()}
        </ModalDialog>
    );
};

const mapStateToProps = createStructuredSelector({
    offerInfoPatern: proposalReviewProSelectors.getOfferInfoPaternData,
    isFetchingOfferInfoPatern:
        proposalReviewProSelectors.getIsFetchingOfferInfoPatern,
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchOfferInfoPatern: (id) =>
        dispatch(proposalReviewProActions.fetchOfferInfoPatern(id)),
    handleCloseModal: () => dispatch(actions.setIsOpenModal(false)),
    redirect: (link) => dispatch(push(link)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    fetchOfferInfoPatern: PropTypes.func,
    handleCloseModal: PropTypes.func,
    isFetchingOfferInfoPatern: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    offerInfoPatern: PropTypes.object,
    proposalId: PropTypes.string,
    redirect: PropTypes.func,
    setCurrentProposal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.EDIT_PROPOSAL_PREVIEW_PERMISSION)
)(Container);
