import isArray from 'lodash/isArray';

import {
    saveCompanyFinancialProduct,
    updateCompanyFinancialProduct,
} from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_PRODUCT,
    SAVE_PRODUCT_FAILURE,
    SAVE_PRODUCT_SUCCESS,
} from '../actionTypes';
import { financialProductActions } from '../reducer';

import filterProducts from './filterProducts';
import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

const handleCheckboxField = (values) => {
    const object_keys = Object.keys(values);
    let temp = [];

    object_keys.forEach((item) => {
        if (values[item] === true) temp.push(item);
    });

    return temp.toString();
};

const getBlindObjects = (values) => {
    let blind_objects = [];

    blind_objects.push('rate');
    blind_objects.push('commission_credit');

    if (values.parameters_hitch && values.parameters_hitch.isChecked) {
        blind_objects.push('hitch');
    }

    if (values.parameters_residual && values.parameters_residual.isChecked) {
        blind_objects.push('residual');
    }

    if (values.parameters_insurance && values.parameters_insurance.isChecked) {
        blind_objects.push('insurance');
    }

    if (values.amount_values && values.amount_values.isChecked) {
        blind_objects.push('amount_values');
    }

    if (values.power_values && values.power_values.isChecked) {
        blind_objects.push('power_values');
    }
    return blind_objects;
};

export default (values, filterData) => (dispatch) => {
    const newValues = {
        amount_values:
            values.amount_values.min + ',' + values.amount_values.max,
        description: values.description,
        external_custom_template_id: values.external_custom_template_id,
        name: values.name,
        parameters: JSON.stringify({
            amount: values.parameters.amount,
            annual_increase: values.annual_increase,
            blind_objects: getBlindObjects(values),
            commission_credit: values.parameters_commission_credit.value,
            dealer_fee: values.dealer_fee,
            hitch: values.parameters_hitch.value,
            insurance: values.parameters_insurance.value,
            is_commission_percentage:
                values.parameters_commission_credit.unit === 0 ? true : false,
            is_percentage: values.parameters_hitch.unit === 0 ? true : false,
            opening_commission_vat:
                values.parameters_commission_credit.opening_commission_vat,
            rate: values.parameters_rate.value,
            residual: values.parameters_residual.value,
            tax: values.parameters.tax,
            terms: handleCheckboxField(values.parameters.terms),
        }),
        power_values: values.power_values.min + ',' + values.power_values.max,
        type: values.type,
        type_algorithm: values.type_algorithm,
    };

    if (isArray(values.file_percentage)) {
        newValues.file_percentage =
            values.file_percentage && values.file_percentage[0];
    }

    let action = saveCompanyFinancialProduct;

    if (values.id) {
        action = updateCompanyFinancialProduct;
        newValues.id = values.id;
    }

    dispatch(financialProductActions[SAVE_PRODUCT]());

    action(newValues)
        .then(() => {
            dispatch(financialProductActions[SAVE_PRODUCT_SUCCESS]());
            dispatch(toggleModalProduct(false));
            dispatch(resetForm());
            dispatch(filterProducts(filterData));
        })
        .catch((error) => {
            dispatch(
                financialProductActions[SAVE_PRODUCT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
