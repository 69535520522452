import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValuesGenerationAccuracy = createSelector(
    getModel,
    (model) => model.initialValuesGenerationAccuracy
);

export const getInitialValuesGenerationRatio = createSelector(
    getModel,
    (model) => model.initialValuesGenerationRatio
);

export const getInitialValuesMeasurementSource = createSelector(
    getModel,
    (model) => model.initialValuesMeasurementSource
);

export const getInitialValuesRoiProgress = createSelector(
    getModel,
    (model) => model.initialValuesRoiProgress
);

export const getAccumulatedSavingsModel = createSelector(
    getModel,
    (model) => model.accumulatedSavings
);

export const getAccumulatedSavingsData = createSelector(
    getAccumulatedSavingsModel,
    (model) => model.data
);

export const getIsFetchingAccumulatedSavings = createSelector(
    getAccumulatedSavingsModel,
    (model) => model.isLoading
);

export const getConsumptionHistoryModel = createSelector(
    getModel,
    (model) => model.consumptionHistory
);

export const getConsumptionHistoryData = createSelector(
    getConsumptionHistoryModel,
    (model) => model.data
);

export const getIsFetchingConsumptionHistory = createSelector(
    getConsumptionHistoryModel,
    (model) => model.isLoading
);

export const getMeasuredEnergyModel = createSelector(
    getModel,
    (model) => model.measuredEnergy
);

export const getMeasuredEnergyData = createSelector(
    getMeasuredEnergyModel,
    (model) => model.data
);

export const getIsFetchingMeasuredEnergy = createSelector(
    getMeasuredEnergyModel,
    (model) => model.isLoading
);

export const getFillEmptyReadsModel = createSelector(
    getModel,
    (model) => model.fillEmptyReads
);

export const getIsLoadingFillEmptyReads = createSelector(
    getFillEmptyReadsModel,
    (model) => model.isLoading
);

export const getHasFetchedFillEmptyReads = createSelector(
    getFillEmptyReadsModel,
    (model) => model.fetched
);

export const getReferenceInfoModel = createSelector(
    getModel,
    (model) => model.referenceInfo
);

export const getReferenceInfoData = createSelector(
    getReferenceInfoModel,
    (model) => model.data || {}
);

export const getReferenceInfoDataIsBimonthly = createSelector(
    getReferenceInfoData,
    (data) => data?.is_bimonthly
);

export const getReferenceInfoDataConsumptionHistory = createSelector(
    getReferenceInfoData,
    (data) => data?.periods || []
);

export const getIsFetchingReferenceInfo = createSelector(
    getReferenceInfoModel,
    (model) => model.isLoading
);

export const getIsFetchingData = createSelector(
    getIsFetchingAccumulatedSavings,
    getIsFetchingConsumptionHistory,
    getIsFetchingMeasuredEnergy,
    getIsFetchingReferenceInfo,
    (...args) => args.some((isFetching) => isFetching)
);
