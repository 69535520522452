import i18next from 'i18next';
import get from 'lodash/get';

import { getLocationsByZipCode } from 'common/api/v1/politicalDivisions';
import alerts from 'common/modules/alerts';
import setShowLisaLoading from 'common/modules/lisaFiles/actions/setShowLisaLoading';
import { doesRateRequireDivision } from 'common/utils/helpers/rates';

import {
    FETCH_LOCATIONS_ZIP_CODE,
    FETCH_LOCATIONS_ZIP_CODE_SUCCESS,
    FETCH_LOCATIONS_ZIP_CODE_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';
import { getIsManually } from '../selectors';

import fetchPoliticalDivisions2 from './fetchPoliticalDivisions2';
import fetchRateDivisions from './fetchRateDivisions';
import fetchRegionsByPoliticalDivision from './fetchRegionsByPoliticalDivision';
import handleFetchScheduleRateConfiguration from './handleFetchScheduleRateConfiguration';
import setIsOpenMissingFieldsModal from './setIsOpenMissingFieldsModal';

export default ({
        calculateConsumptionWithCsvData,
        getValues,
        rate,
        setValue,
        value,
    } = {}) =>
    (dispatch, getState) => {
        const isZipCodeRequired = doesRateRequireDivision(
            rate?.name,
            rate?.isCertified
        );

        if (!value && !isZipCodeRequired)
            dispatch(
                handleFetchScheduleRateConfiguration({
                    calculateConsumptionWithCsvData,
                    getValues,
                    rate,
                    rateDivision: null,
                    setValue,
                })
            );

        if (!value)
            return dispatch(
                projectConsumptionModalActions[
                    FETCH_LOCATIONS_ZIP_CODE_SUCCESS
                ]([])
            );

        dispatch(projectConsumptionModalActions[FETCH_LOCATIONS_ZIP_CODE]());

        getLocationsByZipCode(value)
            .then(({ data }) => {
                dispatch(
                    projectConsumptionModalActions[
                        FETCH_LOCATIONS_ZIP_CODE_SUCCESS
                    ](data || [])
                );

                if (data.length > 0) {
                    const values = data[0];
                    setValue('political_division1', values.state.id);

                    dispatch(fetchPoliticalDivisions2(values.state.id));

                    if (data.length === 1)
                        setValue('political_division2', values.municipality.id);

                    const promises = [];

                    if (rate?.isCertified)
                        promises.push(
                            dispatch(
                                fetchRateDivisions(
                                    values.state.id,
                                    values?.rate_division?.id || null,
                                    setValue
                                )
                            )
                        );
                    else
                        dispatch(
                            fetchRateDivisions(
                                values.state.id,
                                values?.rate_division?.id || null,
                                setValue
                            )
                        );

                    promises.push(
                        dispatch(
                            fetchRegionsByPoliticalDivision(
                                values.state.id,
                                setValue
                            )
                        )
                    );

                    Promise.all(promises).then(([rateDivision]) => {
                        dispatch(
                            handleFetchScheduleRateConfiguration({
                                calculateConsumptionWithCsvData,
                                getValues,
                                rate,
                                rateDivision,
                                setValue,
                            })
                        );
                    });
                }
            })
            .catch((error) => {
                const errors = get(error, ['response', 'data', 'errors'], []);

                dispatch(setShowLisaLoading(false));
                dispatch(
                    projectConsumptionModalActions[
                        FETCH_LOCATIONS_ZIP_CODE_FAILURE
                    ](errors)
                );
                dispatch(
                    projectConsumptionModalActions[
                        FETCH_LOCATIONS_ZIP_CODE_SUCCESS
                    ]([])
                );

                if (errors?.length > 0)
                    dispatch(
                        alerts.actions.show({
                            confirmText: i18next.t('Close'),
                            hideButtons: false,
                            messages: errors,
                            title: i18next.t('Message'),
                            type: alerts.ALERT_TYPE_ALERT,
                            variant: 'warning',
                        })
                    );

                const isManually = getIsManually(getState());

                if (isZipCodeRequired && !isManually) {
                    setValue('political_division1', null);
                    setValue('political_division2', null);
                    setValue('zip_code', null);
                    dispatch(setIsOpenMissingFieldsModal(true));
                }
            });
    };
