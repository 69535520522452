import { getAvailableBag } from 'common/api/v1/availableBag';
import { formatDate } from 'common/utils/dates';

import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import {
    FETCH_TODAY_AVAILABLE_BAG,
    FETCH_TODAY_AVAILABLE_BAG_FAILURE,
    FETCH_TODAY_AVAILABLE_BAG_SUCCESS,
} from '../actionTypes';
import { getHasBagEnergyField } from '../helpers';
import { actions } from '../reducer';

export default (projectId, callback) => (dispatch, getState) => {
    const state = getState();

    const rate = afterSalesSettingsSelectors.getAfterSalesSettingsRate(state);
    const compensationScheme =
        afterSalesSettingsSelectors.getAfterSalesSettingsCompensationScheme(
            state
        );

    if (!getHasBagEnergyField(rate, compensationScheme)) return;

    dispatch(actions[FETCH_TODAY_AVAILABLE_BAG]());

    getAvailableBag(projectId, formatDate(new Date()))
        .then((response) => {
            const data = response?.data?.data;
            dispatch(actions[FETCH_TODAY_AVAILABLE_BAG_SUCCESS](data));

            if (callback) callback(data?.available_bag);
        })
        .catch((error) =>
            dispatch(actions[FETCH_TODAY_AVAILABLE_BAG_FAILURE](error))
        );
};
