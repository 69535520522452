import { format as formatDate, parse } from 'date-fns';
import papa from 'papaparse';

import { HOURS_IN_DAY, HOURS_IN_YEAR, MS_IN_HOUR } from 'common/constants';
import { isInvalidLeapYearDay, isLeapYear } from 'common/utils/dates';
import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';

const DEFAULT_YEAR = 2023;
const DEFAULT_UTC_DATE = Date.UTC(DEFAULT_YEAR, 0, 1, 0, 0, 0);

export const buildCsv = (data, name) => {
    try {
        const csvData = papa.unparse(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        return new File([blob], name, { type: 'text/csv' });
    } catch {
        return null;
    }
};

const getValueByResolution = ({ currentValue, newValue, resolution }) => {
    const numberValue = Number(newValue) || 0;

    switch (resolution) {
        case 'sum':
            return (currentValue || 0) + numberValue || 0;
        case 'max':
            return newValue
                ? Math.max(numberValue, currentValue || 0)
                : currentValue;
        case 'min':
            return newValue
                ? Math.min(numberValue, currentValue || 0)
                : currentValue;
        case 'last':
            return newValue || currentValue;
        case 'first':
            return currentValue || newValue;
        default:
            return currentValue;
    }
};

const getTotalRowsByStep = (step) => {
    switch (step) {
        case 'hourly':
            return HOURS_IN_YEAR;
        case 'daily':
            return HOURS_IN_YEAR / HOURS_IN_DAY;
        default:
            return HOURS_IN_YEAR;
    }
};

const getIndexByDate = ({
    date,
    finalYear = DEFAULT_YEAR,
    initialDateUTC = DEFAULT_UTC_DATE,
    step,
    year = DEFAULT_YEAR,
}) => {
    if (!date) return 0;
    const dateUTC = Date.UTC(
        year,
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    );

    let index = 0;
    let intervalCorrection = 0;

    if (step === 'daily')
        index = (dateUTC - initialDateUTC) / MS_IN_HOUR / HOURS_IN_DAY;
    else index = (dateUTC - initialDateUTC) / MS_IN_HOUR;

    // Fix the offset if december 31st of a leap year was removed
    if (year + 1 === finalYear && isLeapYear(year)) {
        intervalCorrection = step === 'daily' ? 1 : HOURS_IN_DAY;
    }

    return Math.floor(index + intervalCorrection || 0);
};

/**
 *
 * @param columnsToFill: { allowDecimals: boolean = true, name: 'column name', resolution: 'sum' | 'average' | 'max' | 'min' | 'last' | 'first', returnTotal: boolean = false }
 * @param dateFormatOutput: 'dd/MM/yyyy'
 * @param initialDateUTC: number
 * @param rowsData: [{ date: string, time: string, ... }]
 * @param totalRows: number
 * @param step: 'hourly' | 'daily'
 * @returns
 */
export const normalizeCsvDataToHourlyArray = ({
    columnsFormat,
    columnsToFill = [],
    dateOutputFormat = 'dd/MM/yyyy HH:mm',
    generateDate = true,
    initialDateUTC = DEFAULT_UTC_DATE,
    rowsData,
    step = 'hourly',
}) => {
    if (!rowsData?.length || !initialDateUTC) return [];

    const localeForDateFns = getLocaleEquivalenceForDateFns();
    const initialDate = new Date(initialDateUTC);
    const totalRows = getTotalRowsByStep(step);

    const year = initialDate.getUTCFullYear();
    const month = initialDate.getUTCMonth();
    const day = initialDate.getUTCDate();
    let finalDate = initialDate;

    if (isInvalidLeapYearDay({ day, month, year }))
        initialDate.setUTCDate(day - 1);

    const currentDate = new Date(year, month, day, 0, 0, 0, 0);
    const resultData = [];

    let filledRows = 0;

    while (filledRows < totalRows) {
        if (step === 'daily') {
            currentDate.setUTCDate(day + filledRows);
        } else {
            currentDate.setUTCDate(day + Math.floor(filledRows / HOURS_IN_DAY));
            currentDate.setUTCHours(filledRows % HOURS_IN_DAY);
        }

        const year = currentDate.getUTCFullYear();
        const month = currentDate.getUTCMonth();
        const _day = currentDate.getUTCDate();

        if (isInvalidLeapYearDay({ day: _day, month, year })) continue;

        const row = {};

        for (const column of columnsToFill) {
            if (!column?.name) continue;
            row[column.name] = column.defaultValue ?? 0;
        }

        if (generateDate) {
            const dateWithOffset = new Date(
                year,
                month,
                _day,
                currentDate.getUTCHours(),
                currentDate.getUTCMinutes()
            );
            row.date = formatDate(dateWithOffset, dateOutputFormat, {
                locale: localeForDateFns,
            });
        }

        resultData.push(row);
        filledRows++;

        if (filledRows + 1 === totalRows) finalDate = currentDate;
    }

    const totals = columnsToFill.reduce((acc, column) => {
        if (!column?.name || !column.returnTotal) return acc;
        acc[column?.name] = 0;
        return acc;
    }, {});

    let format = columnsFormat?.date || 'dd/MM/yyyy';
    const dateHasTime = format.includes('HH');

    if (!dateHasTime && columnsFormat?.time)
        format = `${format} ${columnsFormat.time}`;

    for (const row of rowsData) {
        const dateValue = dateHasTime
            ? row.date
            : `${row.date || ''} ${row.time || ''}`;

        const parsedDate = parse(dateValue, format, initialDate, {
            locale: localeForDateFns,
        });

        const index = getIndexByDate({
            date: parsedDate,
            finalYear: finalDate.getUTCFullYear(),
            initialDateUTC,
            step,
            year,
        });

        if (index < 0 || index >= totalRows || (!index && index !== 0))
            continue;

        for (const columnToFill of columnsToFill) {
            if (!columnToFill?.name) continue;

            const { allowDecimals = true, name, resolution } = columnToFill;

            const value = getValueByResolution({
                currentValue: resultData[index][name],
                newValue: row?.[name],
                resolution,
            });

            const fixedValue = allowDecimals ? value : Math.floor(value);

            resultData[index][name] = fixedValue;

            if (columnToFill.returnTotal)
                totals[name] += Number(row?.[name]) || 0;
        }
    }

    return { resultData, totals };
};
