import { array, bool, number, object, string } from 'yup';

import {
    DOMESTIC_RATES,
    HIGH_TENSION_RATES,
    RATES_WITH_POWER_FACTOR,
} from 'common/constants/rates';
import getValidationTexts from 'common/utils/getValidationTexts';

import { getHasCalculatedValues, parseDateDefault } from './helpers';

export default (formValues, { isFromCsv, ratesDictionary }) => {
    const selectedRate = ratesDictionary[formValues.rate];
    const hasPowerFactor = RATES_WITH_POWER_FACTOR.includes(selectedRate?.name);
    const hourlyContractedDemandKeys = Object.keys(
        formValues?.hourly_contracted_demand || {}
    );

    const {
        DATE_VALIDATION_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
        getMaxValueText,
        getMinValueText,
        maximumNumberCharacters,
    } = getValidationTexts();

    const validationRules = {
        holder: string().nullable(),
        political_division1: string().required(REQUIRED_TEXT),
        political_division2: string().required(REQUIRED_TEXT),
        rate: string()
            .required(REQUIRED_TEXT)
            .test(
                'valid-rate',
                REQUIRED_TEXT,
                (value) => ratesDictionary[value]?.id
            ),
        service_number: string()
            .test(
                'len',
                maximumNumberCharacters(64),
                (val) => val?.replace(/ /g, '').length <= 64
            )
            .nullable(),
        summary: array()
            .of(
                object({
                    initial_date: string().required(REQUIRED_TEXT),
                    final_date: string()
                        .required(REQUIRED_TEXT)
                        .test(
                            'valid-date',
                            DATE_VALIDATION_TEXT,
                            function (value) {
                                const { initial_date } = this.parent;
                                return (
                                    parseDateDefault(initial_date) <
                                    parseDateDefault(value)
                                );
                            }
                        )
                        .nullable(),
                    power_factor: number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .min(0, getMinValueText(0))
                        .max(100, getMaxValueText(100))
                        .test('required', REQUIRED_TEXT, (v) =>
                            hasPowerFactor
                                ? v !== undefined && v !== null
                                : true
                        )
                        .nullable(),
                })
            )
            .min(1, REQUIRED_TEXT)
            .required(REQUIRED_TEXT),
    };

    if (getHasCalculatedValues(formValues?.summary))
        validationRules.terms_consumption = bool().oneOf([true], REQUIRED_TEXT);

    if (selectedRate?.isCertified) {
        if (selectedRate.name === 'DAC') {
            validationRules.rate_region = string().required(REQUIRED_TEXT);
            validationRules.subsidy_rate = string().required(REQUIRED_TEXT);
        }
        if (DOMESTIC_RATES.includes(selectedRate.name))
            validationRules.rate_division_summer =
                string().required(REQUIRED_TEXT);
        else validationRules.rate_division = string().required(REQUIRED_TEXT);

        if (HIGH_TENSION_RATES.includes(selectedRate.name))
            validationRules.contracted_demand = number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(1, getMinValueText(1));
    }
    if (
        !isFromCsv &&
        selectedRate &&
        ((selectedRate.isCertified &&
            ['DIST', 'DIT'].includes(selectedRate.name)) ||
            (!selectedRate.isCertified &&
                selectedRate.paymentType === 'Schedule'))
    )
        validationRules.consumption_profile = string().required(REQUIRED_TEXT);

    if (!selectedRate?.isCertified && hourlyContractedDemandKeys.length > 0) {
        const contractedDemandValidation = {};

        for (const key of hourlyContractedDemandKeys)
            contractedDemandValidation[key] = object().shape({
                value: number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0)),
            });

        validationRules.hourly_contracted_demand = object().shape(
            contractedDemandValidation
        );
    }

    return object().shape(validationRules);
};
