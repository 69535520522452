import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getAgents = createSelector(getModel, (model) => model.agents);

export const getIsFetchingAgents = createSelector(
    getAgents,
    (model) => model.isLoading
);

export const getAgentsData = createSelector(getAgents, (model) => model.data);

export const getPhoneCodes = createSelector(
    getModel,
    (model) => model.phoneCodes
);

export const getIsFetchingPhoneCodes = createSelector(
    getPhoneCodes,
    (value) => value.isFetching
);

export const getPhoneCodesData = createSelector(
    getPhoneCodes,
    (value) => value.data || []
);

export const getPhoneCodesErrors = createSelector(
    getPhoneCodes,
    (value) => value.errors
);

export const getSaveContact = createSelector(
    getModel,
    (model) => model.saveContact
);

export const getIsSavingContact = createSelector(
    getSaveContact,
    (value) => value.isSaving
);

export const getSaveContactErrors = createSelector(
    getSaveContact,
    (value) => value.errors
);

export const getEmails = createSelector(
    getModel,
    (model) => model.initialValues.emails
);

export const getOriginContact = createSelector(
    getModel,
    (model) => model.originContact
);

export const getIsFetchingOrigins = createSelector(
    getOriginContact,
    (model) => model.isLoading
);

export const getOriginContactToSelect = createSelector(
    getOriginContact,
    (originContacts) => {
        const data = originContacts.data
            ? originContacts.data.map((originContact) => ({
                  value: originContact.id,
                  label: originContact.name,
              }))
            : [];
        return [
            {
                label: i18next.t('Select'),
                value: '',
                disabled: true,
            },
            ...data,
        ];
    }
);

export const getOriginContactOptions = createSelector(
    getOriginContact,
    (originContacts) => {
        const originContactOptions = originContacts.data
            ? originContacts.data.map((originContact) => ({
                  value: originContact.id,
                  label: originContact.name,
              }))
            : [];

        return [
            {
                label: i18next.t('Select'),
                value: '',
                disabled: true,
            },
            ...originContactOptions,
        ];
    }
);

export const getStatusContact = createSelector(
    getModel,
    (model) => model.statusContact
);

export const getStatusContactToSelect = createSelector(
    getStatusContact,
    (statusContacts) => {
        const status = statusContacts.data
            ? statusContacts.data.map((statusContact) => ({
                  value: statusContact.id,
                  label: statusContact.name,
              }))
            : [];
        return [
            {
                label: i18next.t('Select'),
                value: '',
                disabled: true,
            },
            ...status,
        ];
    }
);

export const getIsOpenDuplicateModal = createSelector(
    getModel,
    (model) => model.isOpenDuplicateModal
);

export const getDataDuplicateModal = createSelector(
    getModel,
    (model) => model.dataDuplicateModal || {}
);
