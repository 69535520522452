import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Tooltip } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInputConsumption,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

import { DEMAND_METHODS } from '../../constants';
import { getSectionHasErrors } from '../../helpers';

import FormAccordion from './FormAccordion';

const DemandWithoutSolarFields = ({ control, disabled, errors, rate }) => {
    const [demandMethod, fieldsKeys] = useWatch({
        control,
        name: ['demand_method', 'fields_keys'],
    });
    const { t } = useTranslation();

    if (!fieldsKeys?.demand?.length) return null;

    const isDemandMethodManual = demandMethod === DEMAND_METHODS.MANUAL;
    return (
        <FormAccordion
            hasFormErrors={getSectionHasErrors(errors, [
                'demand_method',
                'no_solar_demand',
            ])}
            title={t('Demand without solar')}
        >
            <Grid container>
                <Grid item alignItems="center" display="flex" gap={1} xs={18}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={disabled}
                        label={t('Method')}
                        name="demand_method"
                        options={[
                            {
                                label: t('Measurement source', { count: 2 }),
                                value: DEMAND_METHODS.MEASUREMENT_SOURCE,
                            },
                            {
                                label: t('Max between estimated and current'),
                                value: DEMAND_METHODS.ESTIMATED,
                                visible: rate?.certified,
                            },
                            {
                                label: t('Current'),
                                value: DEMAND_METHODS.CURRENT,
                            },
                            {
                                label: t('Manual'),
                                value: DEMAND_METHODS.MANUAL,
                            },
                        ]}
                        variant="standard"
                    />

                    <Tooltip
                        title={t(
                            'This option allows you to select the demand used for billing without solar'
                        )}
                    >
                        <InfoIcon />
                    </Tooltip>
                </Grid>

                {isDemandMethodManual &&
                    fieldsKeys.demand.map((key) => (
                        <Grid key={`no-solar-${key}`} item xs={18} lg>
                            <ReactHookFormIntlNumberInputConsumption
                                append="kW"
                                control={control}
                                decimalsLimit={2}
                                disabled={disabled}
                                fullWidth
                                name={`no_solar_demand.${key}`}
                                variant="standard"
                            />
                        </Grid>
                    ))}
            </Grid>
        </FormAccordion>
    );
};

DemandWithoutSolarFields.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    rate: PropTypes.object,
};

export default DemandWithoutSolarFields;
