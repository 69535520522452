import React, { useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Stack, StackItem } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { fullNameBuild } from 'common/utils/helpers';
import { scroll } from 'common/utils/mixins';

import * as actions from './actions';
import Item from './components/Item';
import PlaceholderList from './components/PlaceholderList';
import * as selectors from './selectors';

const ProjectList = styled(Box)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 230px);
    overflow-y: auto;
    width: 100%;

    ${scroll.custom()}
`;

const Container = ({
    canModify,
    fetchNextPage,
    fetchProjects,
    filterQuery,
    hasNextPage,
    isFetchingNextPage,
    isLoadingProjects,
    projects = [],
}) => {
    const { t } = useTranslation();
    const columnDivRef = useRef();

    useEffect(() => {
        fetchProjects(filterQuery);
    }, [filterQuery]);

    const loadMoreProjects = (nextPage) => {
        fetchNextPage(nextPage, filterQuery);
        if (columnDivRef.current) {
            columnDivRef.current.scrollTop =
                columnDivRef.current.scrollTop - 700;
        }
    };

    return (
        <ProjectList ref={columnDivRef}>
            <PlaceholderList
                isEmpty={isEmpty(projects)}
                ready={!isLoadingProjects}
            >
                <InfiniteScroll
                    getScrollParent={() => columnDivRef.current}
                    hasMore={hasNextPage && !isFetchingNextPage}
                    loadMore={loadMoreProjects}
                    loader={null}
                    pageStart={1}
                    threshold={500}
                    useWindow={false}
                >
                    <Stack spacing={0}>
                        {Object.keys(projects).map((projectId) => {
                            const project = projects[projectId];
                            const projectData = {
                                canModify,
                                contact: project.contact,
                                createdBy: fullNameBuild({
                                    firstName: get(
                                        project,
                                        'agent.first_name',
                                        ''
                                    ),
                                    lastName: get(
                                        project,
                                        'agent.last_name',
                                        ''
                                    ),
                                }),
                                date: project.created_at,
                                documents: project.proposals_numbers,
                                id: project.id,
                                proposalsCount: project.proposals_count,
                                statusProject: project.status_project,
                                title: project.name,
                                rate: get(project, 'rate.name', ''),
                                status_project: project.status_project,
                            };

                            return (
                                <StackItem key={project.id} sx={{ mb: 2 }}>
                                    <Item {...projectData} />
                                </StackItem>
                            );
                        })}
                    </Stack>
                    {isFetchingNextPage && (
                        <Box
                            key={0}
                            sx={{ textAlign: 'center', width: '100%', p: 2 }}
                        >
                            {t('Loading').concat('...')}
                        </Box>
                    )}
                </InfiniteScroll>
            </PlaceholderList>
        </ProjectList>
    );
};

const mapStateToProps = createStructuredSelector({
    projects: selectors.getProjectsData,
    isLoadingProjects: selectors.getIsFetchingProjects,
    hasNextPage: selectors.getHasNextPage,
    isFetchingNextPage: selectors.getIsFetchingNextPage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjects: (filterQuery) =>
        dispatch(actions.fetchProjects(filterQuery)),
    fetchNextPage: (nextPage, filterQuery) =>
        dispatch(actions.fetchNextPage(nextPage, filterQuery)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchProjects: PropTypes.func,
    fetchNextPage: PropTypes.func,
    filterQuery: PropTypes.string,
    handleClickArchive: PropTypes.func,
    hasNextPage: PropTypes.bool,
    isFetchingNextPage: PropTypes.bool,
    isLoadingProjects: PropTypes.bool,
    prepareDelete: PropTypes.func,
    projects: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION)
)(Container);
