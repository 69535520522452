import React, { useEffect } from 'react';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionProduct from 'common/components/accordions/AccordionProduct';
import { IconStructure } from 'common/components/icons';
import { DEBOUNCE_TIME } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { numberFormat } from 'common/utils/helpers';

import {
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFieldsBuild,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelFieldBuild,
    handleNormalizePercentageDiscountField,
    handleRemoveBuild,
} from '../proposalGeneratorQuote/helpers';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import * as selectors from './selectors';

const Container = ({
    createProduct,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    deleteProduct,
    getPermissionsByCode,
    initialize,
    isFetching,
    isLoading,
    isLocked = false,
    isUpdating,
    offerStructures,
    proposalId,
    save,
    structures,
    totalCost,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isEmpty(offerStructures)) {
            initialize(offerStructures);
        }
    }, [offerStructures]);

    const { canModify: canModifyProduct } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_STRUCTURES_PERMISSION
    );

    const { canModify: canModifyTotal } = getPermissionsByCode(
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS
    );

    const handleOnSave = React.useRef(debounce(save, DEBOUNCE_TIME)).current;

    const handleNormalizeFields = handleNormalizeFieldsBuild(
        createProduct,
        handleOnSave,
        proposalId
    );

    const handleNormalizeModelField = handleNormalizeModelFieldBuild(
        createProduct,
        handleOnSave,
        proposalId
    );

    const handleRemove = handleRemoveBuild(deleteProduct, proposalId);

    const isLoadingForm = isFetching || isLoading || isUpdating;

    const isLockedForm = isLoadingForm || isLocked;

    return (
        <AccordionProduct
            caption={t('Total price')}
            cardId="proposal_generator_structure_card"
            isLoading={isLoadingForm}
            svgIcon={<IconStructure />}
            title={t('Structure', { count: 2 })}
            total={
                canModifyTotal
                    ? numberFormat(totalCost, {
                          currency: currencyIso,
                          locale: currencyLocale,
                          style: 'currency',
                      })
                    : null
            }
        >
            <CardForm
                canModifyProduct={canModifyProduct}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                handleClickDelete={handleClickDelete}
                handleNormalizeDiscountField={handleNormalizeDiscountField}
                handleNormalizeField={handleNormalizeField}
                handleNormalizeFields={handleNormalizeFields}
                handleNormalizeMarginField={handleNormalizeMarginField}
                handleNormalizeModel={handleNormalizeModel}
                handleNormalizeModelField={handleNormalizeModelField}
                handleNormalizePercentageDiscountField={
                    handleNormalizePercentageDiscountField
                }
                handleRemove={handleRemove}
                isLoading={isLoadingForm}
                isLocked={isLockedForm}
                itemsData={structures}
                offerItems={
                    offerStructures &&
                    offerStructures.map((item) => item.product_branch_office)
                }
            />
        </AccordionProduct>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingOfferStructures,
    isUpdating: selectors.getIsUpdating,
    offerStructures: proposalGeneratorQuoteSelectors.getOfferStructuresData,
    structures: proposalGeneratorQuoteSelectors.getProposalStructureCatalogs,
    totalCost: proposalGeneratorQuoteSelectors.getFinalCostStructures,
});

const mapDispatchToProps = (dispatch) => ({
    createProduct: (proposalId, values) =>
        dispatch(actions.create(proposalId, values)),
    deleteProduct: (id, proposalId, successCallback) =>
        dispatch(actions.delete(id, proposalId, successCallback)),
    initialize: (values) => dispatch(actions.initialValues(values)),
    save: (proposalId, valuesForm) =>
        dispatch(actions.save(proposalId, valuesForm)),
});

Container.propTypes = {
    createProduct: PropTypes.func,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    deleteProduct: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    initialize: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isUpdating: PropTypes.bool,
    offerStructures: PropTypes.array,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    structures: PropTypes.array,
    totalCost: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_STRUCTURES_PERMISSION,
        PERMISSION_LIST.SEE_TOTAL_PRODUCTS,
    ])
)(Container);
