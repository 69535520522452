export const NAME = 'solar-generation';

export const COLORS = {
    NASA: {
        backgroundColor: '#FF9A00',
        order: 5,
    },
    'NASA -20%': {
        backgroundColor: '#2F4DFF',
        order: 4,
    },
    'NASA -25%': {
        backgroundColor: '#FF9A00',
        order: 3,
    },
    'NASA -30%': {
        backgroundColor: '#848BAB',
        order: 2,
    },
    'NASA -35%': {
        backgroundColor: '#2F4DFF',
        order: 1,
    },
};
