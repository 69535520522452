import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { Box, Button, Divider, Grid, Typography } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import { PROPOSAL_SUMMARY_TYPE } from '../listSettings/constants';
import * as projectCommercialOfferTableActions from '../projectCommercialOfferTable/actions';
import * as projectCommercialOfferTableSelectors from '../projectCommercialOfferTable/selectors';
import FilterDropdown from '../projectWorkTabs/components/FilterDropdown';

import * as actions from './actions';
import SummaryModal from './components/SummaryModal';
import SummaryTable from './components/SummaryTable';
import { getGeneratedCommercialOffers } from './helpers';
import * as selectors from './selectors';

export const TextFilterBy = styled('p')`
    color: #cdcdcd;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
    margin-bottom: 0;
`;

const Container = ({
    canDelete,
    canModify,
    canView,
    branchOffice,
    catalogs,
    commercialOffers,
    fetchCatalogs,
    fetchCommercialOffers,
    fetchOfferTemplates,
    fetchSummaries,
    handleClickClose,
    handleClickDownloadCSV,
    isFetchingCommercialOffers,
    isFetchingSummaries,
    isFetchingTemplates,
    isOpen,
    prepareSummaryCreate,
    projectId,
    projectName,
    summaries,
    templates,
}) => {
    const { t } = useTranslation();
    const [isArchived, setIsArchived] = useState(false);

    useEffect(() => {
        fetchCatalogs(PROPOSAL_SUMMARY_TYPE);
        fetchCommercialOffers({ projectId });
        fetchOfferTemplates(branchOffice, types.MULTIPROPOSAL_TYPE);
    }, []);

    useEffect(() => {
        fetchSummaries({ isArchived, projectId });
    }, [isArchived]);

    const isLoading =
        isFetchingSummaries ||
        isFetchingCommercialOffers ||
        isFetchingTemplates;

    const isDisabledCreateButton =
        isLoading ||
        getGeneratedCommercialOffers(commercialOffers).length <= 1 ||
        templates.length === 0;

    const handleOnClickCreate = () =>
        prepareSummaryCreate({
            projectName,
            summaries,
        });

    if (!canView) return null;

    return (
        <Box mb={2}>
            <Grid container spacing={0} sx={{ mb: 2 }}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 2,
                        alignItems: { xs: 'start', md: 'center' },
                    }}
                >
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ color: '#cdcdcd' }}
                    >
                        {t('Filter by')}
                    </Typography>
                    <FilterDropdown
                        defaultValue={isArchived}
                        handleSelected={(item) => {
                            setIsArchived(item.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={18}
                    md={6}
                    sx={{ textAlign: 'right', pt: { xs: 2, md: 0 } }}
                >
                    <Button
                        color="secondary"
                        disabled={isDisabledCreateButton}
                        endIcon={<AddIcon />}
                        onClick={handleOnClickCreate}
                        variant="outlined"
                        visible={canModify && !isArchived}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                        }}
                    >
                        {t('Create summary')}
                    </Button>
                </Grid>
            </Grid>

            <Divider sx={{ mb: 2 }} />

            <SummaryTable
                canDelete={canDelete}
                canModify={canModify}
                commercialOffers={getGeneratedCommercialOffers(
                    commercialOffers
                )}
                handleClickDownloadCSV={handleClickDownloadCSV}
                isArchivedFilter={isArchived}
                isDisabledCreateButton={isDisabledCreateButton}
                isLoading={isLoading}
                prepareSummaryCreate={handleOnClickCreate}
                summaries={summaries}
                templates={templates}
            />

            <SummaryModal
                catalogs={catalogs}
                commercialOffers={getGeneratedCommercialOffers(
                    commercialOffers
                )}
                handleClickClose={handleClickClose}
                isLocked={isLoading || !canModify}
                isOpen={isOpen}
                projectId={projectId}
                templates={templates}
            />
        </Box>
    );
};
const mapStateToProps = createStructuredSelector({
    branchOffice: selectors.getBranchOfficeSelected,
    catalogs: selectors.getCompaniesCatalogsData,
    commercialOffers:
        projectCommercialOfferTableSelectors.getCommercialOffersData,
    isFetchingCommercialOffers:
        projectCommercialOfferTableSelectors.getIsFetchingCommercialOffers,
    isFetchingSummaries: selectors.getSummariesIsFetching,
    isFetchingTemplates: selectors.getIsFetchingTemplates,
    isOpen: selectors.getIsOpenModal,
    summaries: selectors.getSummariesData,
    templates: selectors.getOfferTemplatesData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCatalogs: (type) => dispatch(actions.fetchCatalogs(type)),
    fetchCommercialOffers: (params) =>
        dispatch(
            projectCommercialOfferTableActions.fetchCommercialOffers(params)
        ),
    fetchOfferTemplates: (branchOffice, type) =>
        dispatch(actions.fetchOfferTemplates(branchOffice, type)),
    fetchSummaries: ({ isArchived, projectId }) =>
        dispatch(actions.fetchSummaries({ isArchived, projectId })),
    handleClickClose: () => dispatch(actions.closeModal()),
    handleClickDownloadCSV: (id, name) =>
        dispatch(actions.downloadCSV(id, name)),
    prepareSummaryCreate: ({ projectName, summaries } = {}) =>
        dispatch(
            actions.prepareSummaryCreate({
                projectName,
                summaries,
                value: true,
            })
        ),
});

Container.propTypes = {
    branchOffice: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    catalogs: PropTypes.array,
    commercialOffers: PropTypes.array,
    fetchCatalogs: PropTypes.func,
    fetchCommercialOffers: PropTypes.func,
    fetchOfferTemplates: PropTypes.func,
    fetchSummaries: PropTypes.func,
    handleClickClose: PropTypes.func,
    handleClickDownloadCSV: PropTypes.func,
    isFetchingCommercialOffers: PropTypes.bool,
    isFetchingSummaries: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    isOpen: PropTypes.bool,
    prepareSummaryCreate: PropTypes.func,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    summaries: PropTypes.array,
    templates: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION)
)(Container);
