import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Chart, registerables, Tooltip } from 'chart.js';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Chart as ChartJs } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';
import { exportChartToCSV } from 'common/utils/helpersChart';

const ChartJsContainer = ({
    chartRef,
    data,
    height = '250px',
    options,
    plugins,
    type = 'bar',
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    Chart.register(...registerables);
    const showToolbar = get(options, 'toolbar.show', false);

    Tooltip.positioners.mouse = (_, evtPos) => evtPos;

    Chart.defaults.animation.duration = 500;
    Chart.defaults.color = theme.palette.text.primary;
    Chart.defaults.datasets.line.fill = 'start';
    Chart.defaults.datasets.line.pointRadius = 0;
    Chart.defaults.datasets.line.tension = 0.1;
    Chart.defaults.font.family = theme.typography.fontFamily;
    Chart.defaults.font.size = 11;
    Chart.defaults.maintainAspectRatio = false;
    Chart.defaults.plugins.filler.drawTime = 'beforeDraw';
    Chart.defaults.plugins.filler.propagate = true;
    Chart.defaults.plugins.legend.position = 'bottom';
    Chart.defaults.responsive = true;
    Chart.defaults.scale.beginAtZero = true;
    Chart.defaults.scale.ticks.count = 5;
    Chart.defaults.plugins.legend.labels = {
        ...Chart.defaults.plugins.legend.labels,
        borderRadius: 2,
        boxHeight: 12,
        boxWidth: 12,
        useBorderRadius: true,
    };

    let colorsBackup = [];

    Chart.defaults.plugins.legend.onHover = (event, legendItem, legend) => {
        const datasetIndex = legendItem.datasetIndex;
        legend.chart.data.datasets.forEach((dataset, index) => {
            colorsBackup.push({
                backgroundColor: dataset?.backgroundColor,
                borderColor: dataset?.borderColor,
            });
            if (index !== datasetIndex) {
                dataset.backgroundColor = 'rgba(0,0,0,0.05)';
                dataset.borderColor = 'rgba(0,0,0,0)';
            }
        });
        legend.chart.update();
    };

    Chart.defaults.plugins.legend.onLeave = (event, legendItem, legend) => {
        legend.chart.data.datasets.forEach((dataset, index) => {
            if (!colorsBackup[index]) return;
            dataset.backgroundColor = colorsBackup[index].backgroundColor;
            dataset.borderColor = colorsBackup[index].borderColor;
        });
        colorsBackup = [];
        legend.chart.update();
    };

    return (
        <>
            {showToolbar && (
                <Box sx={{ textAlign: 'right', mb: 2 }}>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () => exportChartToCSV(data),
                                label: t('Download CSV'),
                            },
                        ]}
                    />
                </Box>
            )}

            <Box sx={{ height, my: 2, position: 'relative', width: '100%' }}>
                <ChartJs
                    data={data}
                    options={{ color: theme.palette.text.primary, ...options }}
                    type={type}
                    plugins={plugins}
                    ref={chartRef}
                />
            </Box>
        </>
    );
};

ChartJsContainer.propTypes = {
    chartRef: PropTypes.object,
    data: PropTypes.object,
    height: PropTypes.string,
    options: PropTypes.object,
    plugins: PropTypes.array,
    type: PropTypes.string,
};

export default ChartJsContainer;
