import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* consumption history */
export const getConsumptionHistoryModel = createSelector(
    getModel,
    (model) => model.consumptionHistory
);

export const getConsumptionHistoryData = createSelector(
    getConsumptionHistoryModel,
    (model) => model?.data || {}
);

export const getConsumptionHistoryItems = createSelector(
    getConsumptionHistoryData,
    (data) => data?.items
);

export const getConsumptionHistoryPagination = createSelector(
    getConsumptionHistoryData,
    (data) => data?.pagination
);

export const getConsumptionHistoryYearsForSelect = createSelector(
    getConsumptionHistoryData,
    (data) => {
        const options = data?.years?.map((item) => ({
            label: `${item}`,
            value: `${item}`,
        }));

        return [{ label: '', options }];
    }
);

export const getConsumptionHistoryErrors = createSelector(
    getConsumptionHistoryModel,
    (model) => model?.errors
);

export const getConsumptionHistoryIsLoading = createSelector(
    getConsumptionHistoryModel,
    (model) => model?.isLoading
);

export const getIsModalFormOpen = createSelector(
    getModel,
    (model) => model.isModalFormOpen
);

export const getSaveDataModel = createSelector(
    getModel,
    (model) => model.saveData
);

export const getIsSavingData = createSelector(
    getSaveDataModel,
    (model) => model?.isLoading
);

/* measured energy */
export const getMeasuredEnergyModel = createSelector(
    getModel,
    (model) => model.measuredEnergy
);

export const getMeasuredEnergyData = createSelector(
    getMeasuredEnergyModel,
    (model) => model?.data
);

export const getIsFetchingMeasuredEnergy = createSelector(
    getMeasuredEnergyModel,
    (model) => model?.isLoading
);

/* cups info */
export const getCupsInfoModel = createSelector(
    getModel,
    (model) => model.cupsInfo
);

export const getCupsInfoData = createSelector(
    getCupsInfoModel,
    (model) => model?.data
);

export const getIsFetchingCupsInfo = createSelector(
    getCupsInfoModel,
    (model) => model?.isLoading
);

/* csv importer */
export const getUploadFileModel = createSelector(
    getModel,
    (model) => model.uploadFile
);

export const getIsUploadingFile = createSelector(
    getUploadFileModel,
    (model) => model?.isLoading
);

export const getConfigCsvImportModal = createSelector(
    getModel,
    (model) => model.configCsvImportModal
);

export const getIsConfigCsvImportModalOpen = createSelector(
    getConfigCsvImportModal,
    (model) => model.isOpen
);

/* available bag */
export const getAvailableBagModel = createSelector(
    getModel,
    (model) => model.availableBag
);

export const getAvailableBagData = createSelector(
    getAvailableBagModel,
    (model) => model?.data
);

export const getAvailableBag = createSelector(
    getAvailableBagData,
    (data) => data?.available_bag || 0
);

export const getIsFetchingAvailableBag = createSelector(
    getAvailableBagModel,
    (model) => model?.isLoading
);

/* today available bag */
export const getTodayAvailableBagModel = createSelector(
    getModel,
    (model) => model.todayAvailableBag
);

export const getTodayAvailableBagData = createSelector(
    getTodayAvailableBagModel,
    (model) => model?.data
);

export const getTodayAvailableBag = createSelector(
    getTodayAvailableBagData,
    (data) => data?.available_bag || 0
);

export const getIsFetchingTodayAvailableBag = createSelector(
    getTodayAvailableBagModel,
    (model) => model?.isLoading
);
