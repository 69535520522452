import React, { useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, UiDialog as Dialog } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as companyGeneralSelectors from '../../companyGeneral/selectors';
import * as contactFormActions from '../../contactForm/actions';
import * as contactFormSelectors from '../../contactForm/selectors';
import * as projectConsumptionCardActions from '../../projectConsumptionCard/actions';
import * as projectConsumptionCardSelectors from '../../projectConsumptionCard/selectors';
import * as integrationsActions from '../actions';
import * as integrationsSelectors from '../selectors';

import * as actions from './actions';
import IntegrationForm from './components/IntegrationForm';
import * as selectors from './selectors';

const StyledCodeWrapper = styled('textarea')`
    background: #141414;
    color: #ffffff;
    font-size: 18px;
    line-height: 27px;
    margin: 20px 0;
    padding: 50px;
    resize: none;
    width: 100%;
`;

const FormContainer = ({
    agents = [],
    canModify,
    defaultCurrency,
    fetchAgents,
    fetchIntegration,
    fetchItemData,
    fetchOriginContact,
    fetchStatusProjects,
    fetchTemplates,
    integrationData,
    isFetchingAgents,
    isFetchingIntegration,
    isFetchingOrigins,
    isFetchingStatusProject,
    isFetchingTemplates,
    match,
    originContactOptions = [],
    resetForm,
    save,
    statusProjectsOptions = [],
    templatesOptions = [],
}) => {
    const { t } = useTranslation();
    const textAreaRef = useRef(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchIntegration(match.params.uidParent);
        fetchStatusProjects();
        fetchOriginContact();
        resetForm();
    }, []);

    useEffect(() => {
        if (match.params.id) {
            fetchItemData(match.params.id);
        }
    }, [match.params]);

    const copyToClipboard = () => {
        textAreaRef.current.select();
        document.execCommand('copy');
    };

    return (
        <>
            <IntegrationForm
                agents={agents}
                canModify={canModify}
                defaultCurrency={defaultCurrency}
                fetchAgents={fetchAgents}
                fetchTemplates={fetchTemplates}
                isFetchingAgents={isFetchingAgents}
                isFetchingIntegration={isFetchingIntegration}
                isFetchingOrigins={isFetchingOrigins}
                isFetchingStatusProject={isFetchingStatusProject}
                isFetchingTemplates={isFetchingTemplates}
                match={match}
                originContactOptions={originContactOptions}
                save={save}
                setShowModal={setShowModal}
                statusProjectsOptions={statusProjectsOptions}
                templatesOptions={templatesOptions}
            />
            <Dialog
                onClose={() => setShowModal(false)}
                open={showModal}
                title={t('Copy the code')}
            >
                <p>
                    {t(
                        'Copy and paste the code into the {{tag}} tag on your web page',
                        { tag: '<body>' }
                    )}
                </p>

                {integrationData && (
                    <StyledCodeWrapper
                        ref={textAreaRef}
                        value={`${'<div id="root"></div>\n<script src="'}${
                            process.env.REACT_APP_WIDGET_URL
                        }bundle.min.js"></script>\n${'<script type="text/javascript">MyApp.init({apiKey: "'}${
                            integrationData.api_key
                        }${'"});</script>'}`}
                        readOnly
                        rows="5"
                    />
                )}

                <BottomActions>
                    <Box sx={{ pt: 2 }}>
                        <Button onClick={copyToClipboard}>
                            {t('Copy the code')}
                        </Button>
                    </Box>
                </BottomActions>
            </Dialog>
        </>
    );
};

FormContainer.propTypes = {
    agents: PropTypes.array,
    canModify: PropTypes.bool,
    defaultCurrency: PropTypes.object,
    fetchAgents: PropTypes.func,
    fetchIntegration: PropTypes.func,
    fetchItemData: PropTypes.func,
    fetchOriginContact: PropTypes.func,
    fetchStatusProjects: PropTypes.func,
    fetchTemplates: PropTypes.func,
    integrationData: PropTypes.object,
    isFetchingAgents: PropTypes.bool,
    isFetchingIntegration: PropTypes.bool,
    isFetchingOrigins: PropTypes.bool,
    isFetchingStatusProject: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    match: PropTypes.object,
    originContactOptions: PropTypes.array,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    statusProjectsOptions: PropTypes.array,
    templatesOptions: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    agents: contactFormSelectors.getAgentsData,
    defaultCurrency: companyGeneralSelectors.getCurrentCompanyCurrency,
    integrationData: integrationsSelectors.getIntegrationData,
    isFetchingAgents: contactFormSelectors.getIsFetchingAgents,
    isFetchingIntegration: integrationsSelectors.getIntegrationIsFetching,
    isFetchingOrigins: contactFormSelectors.getIsFetchingOrigins,
    isFetchingStatusProject:
        projectConsumptionCardSelectors.getIsFetchingStatusProject,
    isFetchingTemplates: selectors.getIsFetchingTemplates,
    originContactOptions: contactFormSelectors.getOriginContactOptions,
    statusProjectsOptions:
        projectConsumptionCardSelectors.getStatusProjectOptions,
    templatesOptions: selectors.getTemplatesToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAgents: (branchOffice) =>
        dispatch(contactFormActions.fetchAgents(branchOffice)),
    fetchItemData: (uuid) => dispatch(actions.fetchItemData(uuid)),
    fetchOriginContact: () => dispatch(contactFormActions.fetchOriginContact()),
    fetchStatusProjects: () =>
        dispatch(projectConsumptionCardActions.fetchStatusProjects()),
    fetchTemplates: (branch) =>
        dispatch(actions.fetchTemplatesFilteredByBranch(branch)),
    fetchIntegration: (uuid) =>
        dispatch(integrationsActions.fetchIntegration(uuid)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (values, onSuccessCallback) =>
        dispatch(actions.save(values, onSuccessCallback)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.EMBEDDED_PROPOSAL_PERMISSION)
)(FormContainer);
