import i18next from 'i18next';
import { get, isEmpty } from 'lodash';

import { DO_NOT_ASSIGN_VALUE } from 'common/constants';
import {
    MAX_USERS_PRO_PLAN,
    MAX_USERS_PRO_SCALE,
    PLAN_TYPES,
} from 'common/constants';
import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import { fullNameBuild } from 'common/utils/helpers';

const getRoleOptions = (roles, system) => {
    if (!isEmpty(roles))
        return roles
            .filter(({ is_system_role }) => is_system_role === system)
            .map((role) => ({
                label: role.name,
                value: role.id,
            }));
    return [];
};

export const getBranchOfficesAvailable = (branchOffices) => {
    const branches = branchOffices.filter(
        (branch) => branch.id !== 'company' && branch.checked
    );
    if (!isEmpty(branches)) return branches;
    return [];
};

export const getBranchOfficesDictionaryOptions = (branchOffices) => {
    return branchOffices.reduce((acc, current) => {
        return { ...acc, [current.id]: current.name };
    }, {});
};

export const getBranchOfficesToString = (branchOffices) => {
    if (!isEmpty(branchOffices)) {
        return branchOffices.map((branch) => branch.id).join();
    }
    return '';
};

export const getBranchOfficesWithoutGlobal = (branchOffices) => {
    const branches = branchOffices.filter(
        (branch) => !['company', 'all'].includes(branch.value)
    );
    if (!isEmpty(branches)) return branches;
    return [];
};

export const getIsBManagerOrSales = (roleLevel) =>
    [ROLE_LEVELS.BRANCH_MANAGER, ROLE_LEVELS.SALES_AGENT].includes(roleLevel);

export const getIsCustomOrGM = (roleLevel) =>
    [ROLE_LEVELS.CUSTOMIZED, ROLE_LEVELS.GENERAL_MANAGER].includes(roleLevel);

export const getIsDisabledAddMemberButton = (
    activeUsers,
    counters,
    subscriptionType
) => {
    switch (subscriptionType) {
        case PLAN_TYPES.PRO:
            return (
                activeUsers >=
                get(counters, 'maximum_users', MAX_USERS_PRO_PLAN)
            );
        case PLAN_TYPES.SCALE:
            return (
                activeUsers >=
                get(counters, 'maximum_users', MAX_USERS_PRO_SCALE)
            );
        default:
            return false;
    }
};

export const getGroupedRoleOptions = (roles) => {
    return [
        {
            label: i18next.t('System'),
            options: getRoleOptions(roles, true),
        },
        {
            label: i18next.t('Custom', { count: 2 }),
            options: getRoleOptions(roles, false),
        },
    ];
};

export const getRoleLevelName = (roleLevel) => {
    switch (roleLevel) {
        case ROLE_LEVELS.CUSTOMIZED:
            return i18next.t('Custom');
        case ROLE_LEVELS.BRANCH_MANAGER:
            return i18next.t('Branch office manager');
        case ROLE_LEVELS.GENERAL_MANAGER:
            return i18next.t('General manager');
        case ROLE_LEVELS.OWNER:
            return i18next.t('Owner');
        case ROLE_LEVELS.SALES_AGENT:
            return i18next.t('Sales agent');
        default:
            return '---';
    }
};

export const prepareReassignContactsPayload = (
    auditData = [],
    fecthAgents,
    currentUserId
) =>
    new Promise((mainResolve) => {
        if (auditData.length === 0) {
            mainResolve({
                reassignBranches: [],
                branchAgentsDictionary: {},
            });
        }
        const reassignBranches = auditData.map((item) => ({
            branchId: item.branch_id || 'company',
            branchName: item.branch_name,
            newAgent: DO_NOT_ASSIGN_VALUE,
        }));
        Promise.all(
            reassignBranches.map(
                (item) =>
                    new Promise((resolve, reject) => {
                        fecthAgents(item.branchId)
                            .then((response) =>
                                resolve({
                                    branchId: item.branchId,
                                    agents: response.data.data.filter(
                                        (agent) =>
                                            agent.is_active && agent.is_enabled
                                    ),
                                })
                            )
                            .catch(() => reject());
                    })
            )
        ).then((values) => {
            let branchAgentsDictionary = {};
            values.forEach((item) => {
                branchAgentsDictionary[item.branchId] = [
                    {
                        label: i18next.t('No solar advisor'),
                        value: DO_NOT_ASSIGN_VALUE,
                    },
                    ...item.agents
                        .filter((agent) => agent.id !== currentUserId)
                        .map((agent) => ({
                            label: fullNameBuild({
                                firstName: agent.first_name,
                                lastName: agent.last_name,
                                secondSurname: agent.second_surname,
                            }),
                            value: agent.id,
                        })),
                ];
            });
            mainResolve({ reassignBranches, branchAgentsDictionary });
        });
    });
