import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchAccessories = createSelector(
    getModel,
    (model) => model.fetchAccessories
);
export const getFetchAccessoriesData = createSelector(
    getFetchAccessories,
    (model) => model.data
);

export const getFetchAdditionals = createSelector(
    getModel,
    (model) => model.fetchAdditionals
);
export const getFetchAdditionalsData = createSelector(
    getFetchAdditionals,
    (model) => model.data
);

export const getFetchBatteries = createSelector(
    getModel,
    (model) => model.fetchBatteries
);
export const getFetchBatteriesData = createSelector(
    getFetchBatteries,
    (model) => model.data
);

export const getFetchInverters = createSelector(
    getModel,
    (model) => model.fetchInverters
);
export const getFetchInvertersData = createSelector(
    getFetchInverters,
    (model) => model.data
);

export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems
);
export const getIsFetchingItems = createSelector(
    getFetchItems,
    (model) => model.isFetching
);
export const getFetchItemsData = createSelector(
    getFetchItems,
    (model) => model?.data?.data
);
export const getFetchItemsPagination = createSelector(
    getFetchItems,
    (model) => model?.data?.pagination
);

export const getFetchPanels = createSelector(
    getModel,
    (model) => model.fetchPanels
);
export const getFetchPanelsData = createSelector(
    getFetchPanels,
    (model) => model.data
);

export const getFetchPlan = createSelector(
    getModel,
    (model) => model.fetchPlan
);

export const getIsFetchingPlan = createSelector(
    getFetchPlan,
    (model) => model.isFetching
);

export const getFetchPlanData = createSelector(
    getFetchPlan,
    (model) => model.data
);

export const getFetchStructures = createSelector(
    getModel,
    (model) => model.fetchStructures
);
export const getFetchStructuresData = createSelector(
    getFetchStructures,
    (model) => model.data
);

export const getFetchTemplates = createSelector(
    getModel,
    (model) => model.fetchTemplates
);
export const getFetchTemplatesData = createSelector(
    getFetchTemplates,
    (model) => model.data
);

export const getFetchWorkforce = createSelector(
    getModel,
    (model) => model.fetchWorkforce
);
export const getFetchWorkforceData = createSelector(
    getFetchWorkforce,
    (model) => model.data
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getIsInitializing = createSelector(
    getModel,
    (model) => model.isInitializing
);
export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);
export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);
export const getIsOpenModalProduct = createSelector(
    getModel,
    (model) => model.isOpenModalProduct
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);
export const getIsSavingItem = createSelector(
    getSaveItem,
    (model) => model.isSaving
);
export const getSaveItemErrors = createSelector(
    getSaveItem,
    (model) => model.errors
);
