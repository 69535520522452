import fetchProjectMeasuredEnergyByDate from '../../afterSalesMonitoring/actions/fetchProjectMeasuredEnergyByDate';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import {
    FETCH_MEASURED_ENERGY,
    FETCH_MEASURED_ENERGY_SUCCESS,
} from '../actionTypes';
import {
    getDateDayAfter,
    getHasTimestampGaps,
    populateFieldsFromMeasurements,
} from '../helpers';
import { actions } from '../reducer';

export default ({
        finalDate,
        getValues,
        initialDate,
        projectId,
        rate,
        setEnergyBalanceErrors,
        setValue,
    }) =>
    (dispatch, getState) => {
        if (!projectId || !initialDate || !finalDate) return;
        const _initialDate = getDateDayAfter(initialDate);

        dispatch(actions[FETCH_MEASURED_ENERGY]());

        dispatch(
            fetchProjectMeasuredEnergyByDate(projectId, {
                finalDate,
                force: true,
                freq: '5T',
                initialDate: _initialDate,
            })
        )
            .then((data) => {
                const rateConfiguration =
                    afterSalesSettingsSelectors.getScheduleRateConfiguration(
                        getState()
                    );

                dispatch(actions[FETCH_MEASURED_ENERGY_SUCCESS](data));

                populateFieldsFromMeasurements({
                    data,
                    getValues,
                    rate,
                    rateConfiguration,
                    setEnergyBalanceErrors,
                    setValue,
                });

                const hasTimestampGaps = getHasTimestampGaps({
                    finalDate: getDateDayAfter(finalDate),
                    initialDate: _initialDate,
                    timestamp: data.timestamp,
                });

                setValue('hasTimestampGaps', hasTimestampGaps);
            })
            .catch((error) => {
                dispatch(actions[FETCH_MEASURED_ENERGY_SUCCESS](error));
            });
    };
