import { createSlice } from '@reduxjs/toolkit';

import { SET_IS_OPEN_MODAL } from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    offerInfoPatern: {
        isFetching: false,
        data: {},
        errors: [],
    },
    isOpenModal: false,
};

const proposalReviewProSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const proposalReviewProActions = proposalReviewProSlice.actions;

export default proposalReviewProSlice.reducer;
