import { updateTemplate } from 'common/api/v1/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RENAME_ITEM,
    RENAME_ITEM_FAILURE,
    RENAME_ITEM_SUCCESS,
    UPDATE_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';
import * as selectors from '../selectors';

export default (values) => (dispatch, getState) => {
    if (!values.id) return;

    const state = getState();
    let data = selectors.getTemplatesData(state);

    const newValues = { title: values.title };

    dispatch(templateLibraryActions[RENAME_ITEM]());

    updateTemplate(values.id, newValues)
        .then(() => {
            dispatch(templateLibraryActions[RENAME_ITEM_SUCCESS]());
            const newItems = data.map((item) =>
                item.id === values.id ? values : item
            );

            dispatch(
                templateLibraryActions[UPDATE_TEMPLATES_SUCCESS](newItems)
            );

            showToast();
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[RENAME_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
