import { get, isEmpty } from 'lodash';

import { getContactsToReassign } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { arraytoDictionary } from 'common/utils/helpers';

import { getUserOptions } from '../../contactForm/helpers';
import {
    FETCH_CONTACTS_TO_REASING,
    FETCH_CONTACTS_TO_REASING_FAILURE,
    FETCH_CONTACTS_TO_REASING_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchAgentsByBranchOffice from './fetchAgentsByBranchOffice';
import initializeReasignForm from './initializeReasignForm';
import openReasignModal from './openReasignModal';

const getAgentsToReasign = (data, dispatch) =>
    new Promise((resolve) => {
        let resignList = [];
        Promise.all(
            data.map((item) =>
                dispatch(fetchAgentsByBranchOffice(item.branch_id))
            )
        )
            .then((values) => {
                const agentsDictionary = arraytoDictionary(values, 'id');
                data.forEach((item) => {
                    let agentsToSelect = [];
                    const agents = get(
                        agentsDictionary[item.branch_id],
                        'agents',
                        []
                    );
                    if (!isEmpty(agents))
                        agentsToSelect = getUserOptions(agents);
                    resignList.push({
                        agent: null,
                        agentsToSelect,
                        branchName: item.branch_name,
                        contacts: item.contacts_ids,
                    });
                });
                resolve(resignList);
            })
            .catch((error) => {
                dispatch(
                    actions[FETCH_CONTACTS_TO_REASING_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    });

export default (ids, callback) => (dispatch) => {
    dispatch(actions[FETCH_CONTACTS_TO_REASING]());

    const contacts = ids.map((id) => id);

    getContactsToReassign({ contacts })
        .then(async (response) => {
            dispatch(actions[FETCH_CONTACTS_TO_REASING_SUCCESS](response.data));
            if (response?.data) {
                const reasignAgents = await getAgentsToReasign(
                    response?.data,
                    dispatch
                );
                if (!isEmpty(reasignAgents))
                    dispatch(
                        initializeReasignForm({ reasignAgents, callback })
                    );
                dispatch(openReasignModal(true));
            }
        })
        .catch((error) => {
            dispatch(
                actions[FETCH_CONTACTS_TO_REASING_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
