import isEmpty from 'lodash/isEmpty';

import { HOURS_IN_DAY, HOURS_IN_YEAR, MS_IN_HOUR } from 'common/constants';
import { addDate, isInvalidLeapYearDay, isLeapYear } from 'common/utils/dates';
import { getTierValue } from 'common/utils/helpers/rates';

import { formatDateDefault, parseDateDefault } from './helpers';

const getConsumptionProfileForPeriods = ({
    consumptionProfilesShifted,
    summary,
    tiersEnergyDistribution,
    uniqueYears,
}) => {
    const consumptionProfileForPeriods = [];
    const consumptionProfileGroupedPercentage = [];
    const periodsDistribution = [];
    const tiersDistribution = [];

    const date_index_0 = Date.UTC(summary[0].initial_date.split('/')[2], 0, 1);

    for (let index = 0; index < summary.length; index++) {
        const period = summary[index];

        const [finalDay, finalMonth, finalYear] = (
            summary[index + 1]?.initial_date || period.final_date
        ).split('/');
        const finalUTCDate = new Date(
            Date.UTC(finalYear, finalMonth - 1, finalDay)
        );
        const [initDay, intMonth, initYear] = period.initial_date.split('/');
        const initUTCDate = Date.UTC(initYear, intMonth - 1, initDay);
        const fixedInitialDate = new Date(initUTCDate);

        let offset = (initUTCDate - date_index_0) / MS_IN_HOUR;

        if (uniqueYears.includes(initYear - 1) && isLeapYear(initYear - 1))
            offset -= HOURS_IN_DAY;

        let hoursCounter = 0;
        for (
            let date = fixedInitialDate;
            date < finalUTCDate && tiersDistribution.length < HOURS_IN_YEAR;
            date.setUTCHours(date.getUTCHours() + 1)
        ) {
            const year = date.getUTCFullYear();
            const month = date.getUTCMonth();
            const day = date.getUTCDate();

            if (isInvalidLeapYearDay({ day, month, year })) continue;

            const tierValue = getTierValue({
                day: date.getUTCDay(),
                hour: date.getUTCHours(),
                month,
                tiers_distribution: tiersEnergyDistribution,
            });
            const consumptionProfileValue =
                consumptionProfilesShifted[offset + hoursCounter];

            consumptionProfileForPeriods.push(consumptionProfileValue);
            tiersDistribution.push(tierValue);
            periodsDistribution.push(index);

            if (!consumptionProfileGroupedPercentage[index])
                consumptionProfileGroupedPercentage[index] = {};

            if (!consumptionProfileGroupedPercentage[index][tierValue])
                consumptionProfileGroupedPercentage[index][tierValue] = 0;

            consumptionProfileGroupedPercentage[index][tierValue] +=
                consumptionProfileValue;

            hoursCounter++;
        }
    }

    return {
        consumptionProfileForPeriods,
        consumptionProfileGroupedPercentage,
        periodsDistribution,
        tiersDistribution,
    };
};

const getDay = (year) => parseDateDefault(`01/01/${year}`).getDay();

const getConsumptionProfilesShifted = ({
    consumption_profile,
    consumption_profile_year,
    uniqueYears,
}) => {
    const consumptionProfilesShifted = [];

    for (const year of uniqueYears) {
        const diffDays = getDay(year) - getDay(consumption_profile_year);
        const rollIndex = HOURS_IN_DAY * ((7 + diffDays) % 7);

        consumptionProfilesShifted.push(
            ...consumption_profile.slice(rollIndex),
            ...consumption_profile.slice(0, rollIndex)
        );
    }

    return consumptionProfilesShifted;
};

const getFieldValue = ({ kWh, tierObject }) => {
    const summaryField = kWh?.[tierObject?.name?.toLowerCase()];
    return (
        Number.parseFloat(summaryField?.value || summaryField?.placeholder) || 0
    );
};

const getCorrectionFactors = ({
    consumptionProfileGroupedPercentage,
    summary,
    tiers,
    totalConsumption,
}) =>
    consumptionProfileGroupedPercentage.map((period, index) => {
        const { kWh, total } = summary?.[index] || {};
        const newValues = {};
        const periodTiers = Object.entries(period);

        if (periodTiers?.length > 1)
            for (const [tier] of periodTiers) {
                const tierObject = tiers?.find((el) => el?.identifier == tier);
                const fieldValue = getFieldValue({ kWh, tierObject });
                newValues[tier] =
                    fieldValue / (totalConsumption * period[tier]);
            }
        else
            newValues[0] =
                (Number.parseFloat(total?.value || total?.placeholder) || 0) /
                (totalConsumption * period[0]);
        return newValues;
    });

const getNormalizedSummaryValues = ({ summary }) => {
    let totalConsumption = 0;
    const uniqueYears = new Set();

    const summaryParsedDates = summary.map((el) => ({
        ...el,
        initial_date: parseDateDefault(el.initial_date),
        final_date: parseDateDefault(el.final_date),
    }));

    summaryParsedDates.sort((a, b) => a.initial_date - b.initial_date);

    for (const period of summaryParsedDates) {
        const { final_date, initial_date, total } = period;

        const finalDate = addDate(final_date, { days: 1 });
        const initialDate = addDate(initial_date, { days: 1 });

        period.final_date = formatDateDefault(finalDate);
        period.initial_date = formatDateDefault(initialDate);

        totalConsumption +=
            Number.parseFloat(total?.value || total?.placeholder) || 0;

        uniqueYears.add(finalDate.getFullYear());
        uniqueYears.add(initialDate.getFullYear());
    }

    return {
        normalizedSummary: summaryParsedDates,
        totalConsumption,
        uniqueYears: [...uniqueYears],
    };
};

export const getConsumptionProfileDistributed = ({
    consumption_profile,
    consumption_profile_year,
    rate_configuration,
    summary,
}) => {
    if (
        isEmpty(consumption_profile) ||
        isEmpty(rate_configuration) ||
        isEmpty(summary)
    )
        return [];
    const { tiers_energy_distribution } = rate_configuration;

    const { normalizedSummary, totalConsumption, uniqueYears } =
        getNormalizedSummaryValues({ summary });

    const consumptionProfilesShifted = getConsumptionProfilesShifted({
        consumption_profile,
        consumption_profile_year,
        uniqueYears,
    });

    const {
        consumptionProfileForPeriods,
        consumptionProfileGroupedPercentage,
        periodsDistribution,
        tiersDistribution,
    } = getConsumptionProfileForPeriods({
        consumptionProfilesShifted,
        summary: normalizedSummary,
        tiersEnergyDistribution: tiers_energy_distribution[0],
        uniqueYears,
    });

    const correctionFactors = getCorrectionFactors({
        consumptionProfileGroupedPercentage,
        summary: normalizedSummary,
        tiers: tiers_energy_distribution?.[0]?.tiers,
        totalConsumption,
    });

    const consumptionProfileFixed = consumptionProfileForPeriods.map(
        (value, index) => {
            const tier = tiersDistribution[index];
            const period = periodsDistribution[index];
            return 100 * correctionFactors[period][tier] * value || 0;
        }
    );

    if (consumptionProfileFixed.length < HOURS_IN_YEAR)
        consumptionProfileFixed.push(
            ...new Array(HOURS_IN_YEAR - consumptionProfileFixed.length).fill(0)
        );

    return consumptionProfileFixed;
};
