import { doesRateRequireDivision } from 'common/utils/helpers/rates';

import { CONSUMPTIONS_CAPTURE_MODE } from '../constants';
import * as selectors from '../selectors';

import changeMonthsWithCsvData from './changeMonthsWithCsvData';
import setConsumptionProfileCsvData from './setConsumptionProfileCsvData';
import setConsumptionsCaptureMode from './setConsumptionsCaptureMode';
import setIsOpenMissingFieldsModal from './setIsOpenMissingFieldsModal';

export default ({ getValues, setValue, values = {} }) =>
    (dispatch, getState) => {
        const url = values?.data?.url;

        setValue('file', url);
        setValue('consumptions_csv', url);
        dispatch(setConsumptionProfileCsvData(values));
        dispatch(setConsumptionsCaptureMode(CONSUMPTIONS_CAPTURE_MODE.CSV));

        const politicalDivision = getValues('political_division1');
        const rateId = getValues('rate');

        const state = getState();
        const ratesDictionary = selectors.getRatesDictionary(state);
        const rateConfiguration =
            selectors.getFetchScheduleRateConfigurationData(state);

        const rate = ratesDictionary[rateId];

        const politicalDivisionRequired = doesRateRequireDivision(
            rate?.name,
            rate?.isCertified
        );

        if ((!politicalDivisionRequired || politicalDivision) && rate) {
            dispatch(
                changeMonthsWithCsvData({
                    formValues: getValues(),
                    rate,
                    rateConfiguration,
                })
            );
        } else dispatch(setIsOpenMissingFieldsModal(true));
    };
