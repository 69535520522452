import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import { Card, DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import BulkOperationsCard from 'common/components/BulkOperationsCard';
import { DEBOUNCE_TIME } from 'common/constants';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMNS_CONFIG } from '../constants';

import ProductColumns from './ProductColumns';

const ListItems = ({
    branchOfficesDictionary,
    branchSelected,
    canDelete,
    canModify,
    companyUsersData,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickDelete,
    handleClickDuplicate,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    isForSummaryTemplates,
    items,
    languagesDictionary,
    paginationData,
    setFilterData,
    tableSettings,
    templateType,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedBulkOption, setSelectedBulkOption] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) =>
        setFilterData({ ...filterData, page: page });

    const handleOnSelectStatus = (value) => {
        const filter = { ...filterData, page: 0, status: value };
        setFilterData(filter);
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const handleRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };

    const handleRowEditStop = (_, event) => (event.defaultMuiPrevented = true);

    const processRowUpdate = (newRow) => {
        handleSaveRow(newRow);
        return newRow;
    };

    const getColumnKeys = () =>
        templateType === types.MULTIPROPOSAL_TYPE
            ? { ...COLUMNS_CONFIG, proposals_number: 'Proposal_plural' }
            : COLUMNS_CONFIG;

    const getTableKey = () => {
        switch (templateType) {
            case types.MULTIPROPOSAL_TYPE:
                return 'summaries';
            case types.SMART_DOCUMENTS_TYPE:
                return 'smart_documents';
            case types.REPORT_TYPE:
                return 'reports';
            default:
                return 'proposals';
        }
    };

    return (
        <>
            <BulkOperationsCard
                canDelete={canDelete}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectStatus={handleOnSelectStatus}
                prefix={t('Template', {
                    count: selectionModel.length,
                }).toLocaleLowerCase()}
                selectedBulkOption={selectedBulkOption}
                selectionModel={selectionModel}
                setSelectedBulkOption={setSelectedBulkOption}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnsConfig={getColumnKeys()}
                        tableKey={getTableKey()}
                    />
                    <DataGrid
                        autoHeight
                        checkboxSelection={canDelete}
                        columns={ProductColumns({
                            branchOfficesDictionary,
                            branchSelected,
                            canDelete,
                            canModify,
                            companyUsersData,
                            filterData,
                            handleClickBulkItems,
                            handleClickDelete,
                            handleClickDuplicate,
                            handleClickUpdate,
                            hiddenColumns: getHiddenColumns(tableSettings),
                            isForSummaryTemplates,
                            languagesDictionary,
                            orders: getOrderedColumns(tableSettings),
                            rowModesModel,
                            selectedRowId,
                            setRowModesModel,
                            setSelectedRowId,
                        })}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onRowModesModelChange={(newModel) =>
                            setRowModesModel(newModel)
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                        sx={{
                            '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                                backgroundColor: 'rgba(255, 154, 0, 0.16)',
                            },
                            '& .MuiDataGrid-row--editing:hover': {
                                backgroundColor: 'rgba(255, 154, 0, 0.08)',
                            },
                            '& .MuiInputBase-formControl input': {
                                border: 0,
                                outline: 'none',
                                boxShadow: 'none',
                            },
                        }}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    branchOfficesDictionary: PropTypes.array,
    branchSelected: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    companyUsersData: PropTypes.array,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isForSummaryTemplates: PropTypes.bool,
    items: PropTypes.array,
    languagesDictionary: PropTypes.object,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    tableSettings: PropTypes.array,
    templateType: PropTypes.number,
};

export default ListItems;
