import i18next from 'i18next';

import {
    createAccessKey,
    updateAccessKey,
} from 'common/api/afterSales/accessConfig';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    SAVE_CREDENTIAL,
    SAVE_CREDENTIAL_FAILURE,
    SAVE_CREDENTIAL_SUCCESS,
} from '../actionTypes';
import { credentialIntegrationsActions } from '../reducer';

import fetchCredentials from './fetchCredentials';
import setIsOpenModal from './setIsOpenModal';

const removeFalsyValues = (obj) =>
    Object.fromEntries(
        Object.entries(obj).filter(([, value]) => Boolean(value))
    );

export default (values) => (dispatch) => {
    dispatch(credentialIntegrationsActions[SAVE_CREDENTIAL]());

    const newValues = removeFalsyValues({
        description: values.description,
        name: values.name,
        provider_id: values.provider_id,
        ...values.credential,
    });

    const saveAction = values.id
        ? updateAccessKey(values.id, newValues)
        : createAccessKey(newValues);

    saveAction
        .then(() => {
            dispatch(credentialIntegrationsActions[SAVE_CREDENTIAL_SUCCESS]());

            dispatch(setIsOpenModal(false));
            dispatch(fetchCredentials());
        })
        .catch((error) => {
            const errorDetail = error?.response?.data?.detail;

            dispatch(
                credentialIntegrationsActions[SAVE_CREDENTIAL_FAILURE](
                    errorDetail
                )
            );

            if (typeof errorDetail === 'string') {
                const dataAlert = {
                    confirmText: i18next.t('Accept'),
                    messages: [errorDetail],
                    title: i18next.t('Error'),
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'warning',
                };
                dispatch(alerts.actions.show(dataAlert));
            } else {
                showToast({
                    type: 'danger',
                    body: i18next.t('An error occurred during the process'),
                });
            }
        });
};
