import i18next from 'i18next';

import { importTemplatePages } from 'common/api/v1/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SET_CLONE_PAGES,
    SET_CLONE_PAGES_FAILURE,
    SET_CLONE_PAGES_SUCCESS,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (customTemplateId, cloneTemplateId, pages, addNewPages) =>
    (dispatch) => {
        dispatch(editTemplateViewActions[SET_CLONE_PAGES]());

        importTemplatePages(customTemplateId, {
            from_template_id: cloneTemplateId,
            pages_to_import: pages.split(','),
        })
            .then((response) => {
                dispatch(editTemplateViewActions[SET_CLONE_PAGES_SUCCESS]());
                addNewPages(
                    response.data.pages.map((page) => ({
                        ...page,
                        content: JSON.stringify(page.content),
                    }))
                );
                showToast({ body: i18next.t('New pages added') });
            })
            .catch((error) => {
                dispatch(
                    editTemplateViewActions[SET_CLONE_PAGES_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
