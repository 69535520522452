import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormSwitch,
    ReactHookFormArrayEmails,
    ReactHookFormArrayPhones,
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import MainBranchOfficeSelectorRHF from 'common/modules/multiBranches/components/MainBranchOfficeSelectorRHF';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { getIsOnlyContacts } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as contactActions from '../../contact/actions';
import * as recordActions from '../../record/actions';
import * as actions from '../actions';
import { getUserOptions } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const ContactForm = ({
    agents,
    branchOffices,
    canDelete,
    canModify,
    CustomFooter,
    fetchAgents,
    fetchContact,
    fetchOriginContact,
    fetchPhoneCodes,
    fetchStatusContact,
    handleAfterSave,
    handleClickBulkItems,
    initialValues,
    isNewRecord,
    onSubmit,
    originContactToSelect,
    phoneCodes,
    redirectToRecord = true,
    saveErrors,
    statusContactToSelect,
}) => {
    const { t } = useTranslation();

    const { control, getValues, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const [advanced, branchOffice, isGlobal, statusFlag] = watch([
        'advanced',
        'branch_office',
        'is_global',
        'status_flag',
    ]);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        fetchPhoneCodes();
        fetchStatusContact();
        fetchOriginContact();
    }, []);

    useEffect(() => {
        fetchAgents(branchOffice);
    }, [branchOffice]);

    const isArchived = statusFlag === 'archived';
    const isDisabled = !isNewRecord && !canModify;
    const isOnlyContacts = getIsOnlyContacts();

    const handleOnSubmit = (values) =>
        onSubmit(values, handleAfterSave, redirectToRecord);

    const renderCustomFooter = ({ agents, handleSubmit }) => {
        if (CustomFooter)
            return <CustomFooter agents={agents} handleSubmit={handleSubmit} />;
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Box display="flex" justifyContent="end" sx={{ mb: 2 }}>
                <ReactHookFormSwitch
                    control={control}
                    fullWidth
                    label={t('Advanced', { count: 2 })}
                    name="advanced"
                />
            </Box>

            <ReactHookFormInput
                className="__intercom_contact_name_field"
                control={control}
                fullWidth
                dataIntercomTarget="Contact Name (Field)"
                disabled={isDisabled}
                label={`${t('Name', { count: 2 })} *`}
                name="name"
            />

            <ReactHookFormInput
                control={control}
                fullWidth
                disabled={isDisabled}
                label={t('Last name')}
                name="first_lastname"
            />

            <ReactHookFormInput
                control={control}
                fullWidth
                disabled={isDisabled}
                label={t('Second last name')}
                name="second_lastname"
            />

            <ReactHookFormInput
                control={control}
                fullWidth
                disabled={isDisabled}
                label={t('Business')}
                name="company_name"
            />

            {advanced && (
                <>
                    <ReactHookFormInput
                        control={control}
                        fullWidth
                        disabled={isDisabled}
                        label={t('Position')}
                        name="company_position"
                    />
                    <ReactHookFormInput
                        control={control}
                        fullWidth
                        disabled={isDisabled}
                        label={t('Tax ID')}
                        name="rfc"
                    />
                    <ReactHookFormSelect
                        control={control}
                        fullWidth
                        disabled={isDisabled}
                        label={t('Contact status')}
                        name="status_contact"
                        options={statusContactToSelect}
                    />
                </>
            )}

            <ReactHookFormArrayPhones
                codes={phoneCodes}
                control={control}
                isDisabled={isDisabled}
                name="telephones"
            />

            <ReactHookFormArrayEmails
                control={control}
                isDisabled={isDisabled}
                name="emails"
            />

            {advanced && (
                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabled}
                    label={t('Contact origin')}
                    name="contact_origin"
                    options={originContactToSelect}
                />
            )}

            <MainBranchOfficeSelectorRHF
                control={control}
                disabled={
                    !isNewRecord || isGlobal || branchOffices?.length === 1
                }
                hideCompany={isGlobal ? false : true}
                label={t('Branch office')}
                name="branch_office"
            />

            <ReactHookFormSelect
                control={control}
                disabled={isDisabled || isOnlyContacts}
                label={t('User')}
                name="agent"
                options={getUserOptions(agents)}
            />

            <ShowErrors errors={saveErrors} />

            <Grid container mt={1}>
                <Grid
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    item
                    justifyContent={{ xs: 'center', md: 'right' }}
                    xs
                >
                    {!isNewRecord && (
                        <>
                            <Button
                                color="secondary"
                                onClick={() => {
                                    const id = getValues('id');
                                    handleClickBulkItems([id], 'archived', () =>
                                        fetchContact(id)
                                    );
                                }}
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    order: { xs: 2, md: 1 },
                                    mr: { xs: 0, md: 2 },
                                }}
                                type="button"
                                variant="text"
                                visible={canDelete && !isArchived}
                            >
                                {t('Archive')}
                            </Button>
                            <Button
                                color="secondary"
                                onClick={() => {
                                    const id = getValues('id');
                                    handleClickBulkItems([id], 'active', () =>
                                        fetchContact(id)
                                    );
                                }}
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    order: { xs: 2, md: 1 },
                                    mr: { xs: 0, md: 2 },
                                }}
                                type="button"
                                variant="text"
                                visible={canDelete && isArchived}
                            >
                                {t('Unarchive')}
                            </Button>
                        </>
                    )}

                    {renderCustomFooter({ agents, handleSubmit })}

                    {!CustomFooter && (
                        <Button
                            className="__intercom_contact_save_button"
                            dataIntercomTarget="Contact Save (Button)"
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                order: { xs: 1, md: 2 },
                                mb: { xs: 2, md: 0 },
                            }}
                            type="submit"
                            variant="outlined"
                            visible={canModify && !isArchived}
                        >
                            {t('Save')}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsData,
    branchOffices: multiBranchesSelectors.getFilteredBranchesData,
    dataDuplicateModal: selectors.getDataDuplicateModal,
    initialValues: selectors.getInitialValues,
    isOpenDuplicateModal: selectors.getIsOpenDuplicateModal,
    originContactToSelect: selectors.getOriginContactToSelect,
    phoneCodes: selectors.getPhoneCodesData,
    saveErrors: selectors.getSaveContactErrors,
    statusContactToSelect: selectors.getStatusContactToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAgents: (branchOffice) => dispatch(actions.fetchAgents(branchOffice)),
    fetchContact: (id) => dispatch(recordActions.fetchContact(id, false, true)),
    fetchOriginContact: () => dispatch(actions.fetchOriginContact()),
    fetchPhoneCodes: () => dispatch(actions.fetchPhoneCodes()),
    fetchStatusContact: () => dispatch(actions.fetchStatusContact()),
    handleClickBulkItems: (ids, value, callback) =>
        dispatch(contactActions.prepareBulkItems(ids, value, {}, callback)),
    onSubmit: (values, handleAfterSave, redirectToRecord = true) =>
        dispatch(
            actions.validateDuplicatedContact(
                values,
                handleAfterSave,
                redirectToRecord
            )
        ),
    openDuplicateModal: (value) => dispatch(actions.openDuplicateModal(value)),
    resetDataDuplicateModal: () => dispatch(actions.resetDataDuplicateModal()),
    resetForm: () => dispatch(actions.resetForm()),
    showModal: (isOpen) => dispatch(actions.showModal(isOpen)),
});

ContactForm.propTypes = {
    agents: PropTypes.array,
    branchOffices: PropTypes.array,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    CustomFooter: PropTypes.func,
    fetchAgents: PropTypes.func,
    fetchContact: PropTypes.func,
    fetchOriginContact: PropTypes.func,
    fetchPhoneCodes: PropTypes.func,
    fetchStatusContact: PropTypes.func,
    handleAfterSave: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    initialValues: PropTypes.object,
    isNewRecord: PropTypes.bool,
    onSubmit: PropTypes.func,
    originContactToSelect: PropTypes.array,
    phoneCodes: PropTypes.object,
    redirectToRecord: PropTypes.bool,
    saveErrors: PropTypes.array,
    statusContactToSelect: PropTypes.array,
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, stateProps, dispatchProps, ownProps);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ContactForm);
