import { createSelector } from 'reselect';

import { arraytoDictionary } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getCarouselTemplates = createSelector(
    getModel,
    (model) => model.carouselTemplates
);
export const getIsFetchingCarouselTemplates = createSelector(
    getCarouselTemplates,
    (model) => model.isFetching
);
export const getCarouselTemplatesData = createSelector(
    getCarouselTemplates,
    (model) => {
        const { featuredTemplates, newTemplates, regularTemplates } =
            model.data;
        return {
            featuredTemplates: featuredTemplates?.map((item) => ({
                ...item,
                is_featured: true,
            })),
            newTemplates: newTemplates?.map((item) => ({
                ...item,
                is_new: item.new_template,
            })),
            regularTemplates: regularTemplates?.map((item) => ({
                ...item,
                is_regular: true,
            })),
        };
    }
);

export const getCreateItem = createSelector(
    getModel,
    (model) => model.createItem
);
export const getIsCreatingItem = createSelector(
    getCreateItem,
    (model) => model.isCreating
);

export const getCustomBaseTemplates = createSelector(
    getModel,
    (model) => model.customBaseTemplates
);
export const getIsFetchingCustomBaseTemplates = createSelector(
    getCustomBaseTemplates,
    (model) => model.isFetching
);
export const getCustomBaseTemplatesData = createSelector(
    getCustomBaseTemplates,
    (model) => model?.data?.data
);
export const getCustomBaseTemplatesPagination = createSelector(
    getCustomBaseTemplates,
    (model) => model?.data?.pagination
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getIsInitializing = createSelector(
    getModel,
    (model) => model.isInitializing
);
export const getIsOpenModalTemplate = createSelector(
    getModel,
    (model) => model.isOpenModalTemplate
);

export const getLanguages = createSelector(
    getModel,
    (model) => model.languages
);
export const getIsFetchingLanguages = createSelector(
    getLanguages,
    (model) => model.isFetching
);
export const getLanguagesData = createSelector(
    getLanguages,
    (model) => model.data
);
export const getLanguagesDictionary = createSelector(
    getLanguagesData,
    (data) => arraytoDictionary(data, 'key') || {}
);
export const getLanguagesToSelect = createSelector(getLanguagesData, (data) => [
    ...data.map((item) => ({ label: item.language, value: item.key })),
]);

export const getTemplates = createSelector(
    getModel,
    (model) => model.templates
);
export const getIsFetchingTemplates = createSelector(
    getTemplates,
    (model) => model.isFetching
);
export const getTemplatesData = createSelector(
    getTemplates,
    (model) => model?.data?.data
);
export const getTemplatesPagination = createSelector(
    getTemplates,
    (model) => model?.data?.pagination
);

export const getPage = createSelector(getModel, (model) => model.page);

export const getRenameItem = createSelector(
    getModel,
    (model) => model.renameItem
);
export const getIsRenamingTemplate = createSelector(
    getRenameItem,
    (model) => model.isUpdating
);

export const getTemplateCategories = createSelector(
    getModel,
    (model) => model.templateCategories
);
export const getIsFetchingTemplateCategories = createSelector(
    getTemplateCategories,
    (model) => model.isFetching
);
export const getTemplateCategoriesData = createSelector(
    getTemplateCategories,
    (model) => model.data
);

export const getTemplateMode = createSelector(
    getModel,
    (model) => model.templateMode
);
export const getTemplateType = createSelector(
    getInitialValues,
    (model) => model.type
);
export const getWizardStep = createSelector(
    getModel,
    (model) => model.wizardStep
);

export const getCompanyUsers = createSelector(
    getModel,
    (model) => model.companyUsers
);

export const getCompanyUsersIsFetching = createSelector(
    getCompanyUsers,
    (model) => model.isFetching
);

export const getCompanyUsersData = createSelector(
    getCompanyUsers,
    (model) => model.data
);

export const getCompanyUsersErrors = createSelector(
    getCompanyUsersData,
    (data) => data.errors
);
