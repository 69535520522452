import { getUsers } from 'common/api/v1/companies';

import {
    FETCH_COMPANY_USERS,
    FETCH_COMPANY_USERS_FAILURE,
    FETCH_COMPANY_USERS_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default () => (dispatch) => {
    new Promise((resolve) => {
        dispatch(templateLibraryActions[FETCH_COMPANY_USERS]());

        getUsers()
            .then((response) => {
                dispatch(
                    templateLibraryActions[FETCH_COMPANY_USERS_SUCCESS](
                        response.data.data
                    )
                );
            })
            .catch((error) => {
                dispatch(
                    templateLibraryActions[FETCH_COMPANY_USERS_FAILURE](error)
                );
            })
            .finally(resolve);
    });
};
