import { push } from 'connected-react-router';

import {
    createItem,
    updateItem,
} from 'common/api/v1/integrationsEmbeddedProposals';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { handleCheckboxField } from '../helpers';
import { integrationWebActions } from '../reducer';

export default (values, onSuccessCallback) => (dispatch) => {
    const newValues = {
        agent: values.agent,
        branch_office: values.branch_office,
        contact_origin: values.contact_origin,
        external_custom_template_id: values.external_custom_template_id,
        install_type: handleCheckboxField(values.install_type),
        method_consumtion: handleCheckboxField(values.method_consumtion),
        status_project: values.status_project,
        styles: values.style,
        with_service_number: values.service_number[1] === true,
    };

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem({ ...newValues, application: values.application });

    dispatch(integrationWebActions[SAVE]());

    save()
        .then((response) => {
            dispatch(integrationWebActions[SAVE_SUCCESS](response.data));

            dispatch(
                push(`/web-quoter/${values.application}/${response.data.id}`)
            );

            if (onSuccessCallback) onSuccessCallback();
        })
        .catch((error) => {
            dispatch(
                integrationWebActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
