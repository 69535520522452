import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import recalculateConsumptionHistory from './recalculateConsumptionHistory';

export default (projectId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Confirm'),
        haveTextCaptcha: true,
        messages: [
            i18next.t(
                'Are you sure you want to recalculate the pool? This action may modify the billing of each period, causing inconsistencies in the reports. Please consider the impact on historical analysis before proceeding'
            ),
        ],
        onSuccess: () => dispatch(recalculateConsumptionHistory(projectId)),
        textToValidate: i18next.t('Recalculate pool').toUpperCase(),
        title: i18next.t('Recalculate pool'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
