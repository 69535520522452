import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';

import { UPDATE_TEMPLATE_MODE } from '../constants';

import initializeForm from './initializeForm';
import setTemplateMode from './setTemplateMode';
import toggleModalTemplate from './toggleModalTemplate';

const getTemplateBranchOffices = ({ is_global, branch_office_ids }, state) => {
    const branchSelected = multiBranchesSelectors.getBranchSelected(state);
    if (is_global || branchSelected === 'company') return 'company';
    if (branch_office_ids.length === 1) return branch_office_ids[0];
    if (branchSelected === 'all')
        return branch_office_ids.map((id) => id).join();
    return branchSelected;
};

export default (template) => (dispatch, getState) => {
    dispatch(setTemplateMode(UPDATE_TEMPLATE_MODE));
    dispatch(
        initializeForm({
            ...template,
            branch_offices: getTemplateBranchOffices(template, getState()),
            language: template.language,
            proposals_number: `${template.proposalsNumber}`,
        })
    );
    dispatch(toggleModalTemplate(true));
};
