import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getTrackingPurpose = createSelector(
    getModel,
    (model) => model.trackingPurpose
);

export const getTrackingPurposeToSelect = createSelector(
    getTrackingPurpose,
    (trackingsPurposes) => getTrackingPurposeToDropdown(trackingsPurposes)
);

const getTrackingPurposeToDropdown = (trackingsPurposes) => {
    let list = trackingsPurposes.data
        ? trackingsPurposes.data.map((trackingPurpose) => ({
              value: trackingPurpose.id,
              label: trackingPurpose.name,
          }))
        : [];

    list.unshift({
        disabled: true,
        value: '',
        label: i18next.t('Select a goal'),
    });

    return list;
};

export const getUsers = createSelector(getModel, (model) => model.users);

export const getUsersData = createSelector(getUsers, (model) => model.data);
