import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import BranchOfficesDropdown from 'common/modules/multiBranches/components/BranchOfficesDropdown';

import { getIsCustomOrGM } from '../helpers';

const BranchOfficeFormatter = ({ row }) => {
    const { t } = useTranslation();

    const {
        branch_office_name,
        branch_offices = [],
        id,
        role_level,
        settings,
    } = row;
    const isCustomOrGM = getIsCustomOrGM(role_level);
    const hasAllBranchOfficesCurrentUser = get(
        settings,
        'has_all_branchoffices',
        false
    );

    if (role_level === ROLE_LEVELS.OWNER) return '---';

    if (isCustomOrGM) {
        return (
            <BranchOfficesDropdown
                branchOffices={branch_offices.filter(
                    ({ checked, id }) => checked || id === 'company'
                )}
                keyName={`branch-office-name-${id}`}
                label={
                    hasAllBranchOfficesCurrentUser
                        ? t('All text', { context: 'female', count: 2 })
                        : t('Branch office', { count: 2 })
                }
            />
        );
    }

    if (branch_office_name) {
        return <Typography variant="caption">{branch_office_name}</Typography>;
    }

    return <Typography variant="caption">{t('No branch')}</Typography>;
};

BranchOfficeFormatter.propTypes = {
    row: PropTypes.object,
};

export default BranchOfficeFormatter;
