import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

const getIsDisabledOption = (branchKey, branchOfficesIdsAuthUser) => {
    return !branchOfficesIdsAuthUser.includes(branchKey);
};

const getBranchOfficesOptions = (
    branchOfficesDictionary,
    branchOfficesIdsAuthUser
) => {
    return Object.keys(branchOfficesDictionary)
        .filter((branchKey) => !['all', 'company'].includes(branchKey))
        .map((branchKey) => ({
            disabled: getIsDisabledOption(branchKey, branchOfficesIdsAuthUser),
            label: branchOfficesDictionary[branchKey],
            value: branchKey,
        }));
};

const UserBranchOfficeSelect = ({
    branchOfficesDictionary,
    branchOfficesIdsAuthUser,
    control,
    disabled,
    label,
    name,
}) => {
    const { t } = useTranslation();
    const options = getBranchOfficesOptions(
        branchOfficesDictionary,
        branchOfficesIdsAuthUser
    );

    if (!getIsMultibranchesAccount()) return null;
    return (
        <ReactHookFormSelect
            control={control}
            disabled={disabled}
            label={label}
            name={name}
            options={[
                {
                    disabled: true,
                    label: t('Select a branch office'),
                    value: '',
                },
                ...options,
            ]}
        />
    );
};

UserBranchOfficeSelect.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    branchOfficesIdsAuthUser: PropTypes.array,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default UserBranchOfficeSelect;
