import React, { useEffect, useRef, useState } from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ListIcon from '@mui/icons-material/List';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, IconButton, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import InfoTooltip from 'common/components/InfoToolTip';
import yupResolver from 'common/utils/yupResolver';

import * as afterSalesMeasurementSourceSelectors from '../../afterSalesMeasurementSource/selectors';
import * as actions from '../actions';
import { DEBOUNCE_TIME, VIEW_OPTIONS } from '../constants';
import {
    getDefaultChartType,
    getReferencePeriods,
    getGenerationRatioSeries,
    getGenerationRatioChartConfig,
    getParamsMeasurementSourceByPeriods,
} from '../helpers';
import * as selectors from '../selectors';
import { generationAccuracyValidation } from '../validations';

import ChartJs from './ChartJs';

const chartTypes = [
    { icon: <ShowChartIcon />, value: 'line' },
    { icon: <BarChartIcon />, value: 'bar' },
    { icon: <ListIcon />, value: 'table' },
];

const GenerationRatioChart = ({
    afterSalesStartDate,
    countryCurrencyLocale,
    dateRangeOptions,
    handleFetchProjectMeasuredEnergyByRange,
    initialValues,
    initializeGenerationRatio,
    isFetchingMeasuredEnergy,
    isFetchingReferenceInfo,
    loadedData,
    measuredEnergy,
    powerStationsIds,
    referenceInfoConsumptionHistory,
    referenceInfoIsBimonthly,
    resetMeasurementSourceData,
    selectedProjectId,
    setLoadedData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        categories: [],
        options: {},
        series: [],
    });
    const [chartType, setChartType] = useState('line');
    const {
        control,
        formState: { isDirty },
        handleSubmit,
        reset,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(generationAccuracyValidation),
    });
    const { t } = useTranslation();
    const viewOption = watch('view_option');
    const showChart =
        powerStationsIds?.length > 0 &&
        referenceInfoConsumptionHistory?.length > 0;

    const resetForm = (values, measuredData) => {
        setLoadedData(true);
        if (!values) return;
        reset(values);

        const newChartType = getDefaultChartType(values?.view_option);
        setChartType(newChartType);
        handleChartChange({
            chartType: newChartType,
            dateRange: values?.date_range,
            measuredEnergy: measuredData,
            viewOption: values?.view_option,
        });
    };

    const handleChartChange = (config) => {
        if (!showChart) {
            setChartConfig({ categories: [], options: {}, series: [] });
            return;
        }
        const periods = getReferencePeriods({
            dateRange: config.dateRange,
            dateRangeOptions,
            isBimonthly: referenceInfoIsBimonthly,
            referencePeriods: referenceInfoConsumptionHistory,
            startDate: afterSalesStartDate,
            viewOption: config.viewOption,
        });

        const { categories, series } = getGenerationRatioSeries({
            measuredEnergy: config?.measuredEnergy,
            periods,
        });

        const newConfig = getGenerationRatioChartConfig({
            categories,
            config,
            countryCurrencyLocale,
            periods,
            series,
        });
        setChartConfig(newConfig);
    };

    const handleFetchMeasuredEnergy = useRef(
        debounce((config) => {
            setLoadedData(false);
            const values = getParamsMeasurementSourceByPeriods({
                dateRange: config.date_range,
                dateRangeOptions: config.dateRangeOptions,
                isBimonthly: config.referenceInfoIsBimonthly,
                referencePeriods: config.referenceInfoConsumptionHistory,
                startDate: config.afterSalesStartDate,
                viewOption: config.viewOption,
            });
            handleFetchProjectMeasuredEnergyByRange({
                callback: config.callback,
                selectedProjectId: config.selectedProjectId,
                values,
            });
        }, DEBOUNCE_TIME)
    ).current;

    useEffect(() => {
        return () => resetMeasurementSourceData();
    }, []);

    useEffect(() => {
        const date_range =
            initialValues?.date_range || dateRangeOptions?.[0]?.value;

        handleFetchMeasuredEnergy({
            afterSalesStartDate,
            callback: (data) =>
                resetForm({ ...initialValues, date_range }, data),
            dateRangeOptions,
            date_range,
            referenceInfoConsumptionHistory,
            referenceInfoIsBimonthly,
            selectedProjectId,
            viewOption,
        });
    }, [
        dateRangeOptions,
        initialValues,
        powerStationsIds,
        referenceInfoConsumptionHistory,
    ]);

    const handleOnChangeChartType = (value) => {
        setChartType(value);
        handleChartChange({
            chartType: value,
            dateRange:
                initialValues?.date_range || dateRangeOptions?.[0]?.value,
            measuredEnergy,
            viewOption: initialValues?.view_option,
        });
    };

    const disabled =
        isFetchingMeasuredEnergy || isFetchingReferenceInfo || !loadedData;
    const isPeriod = viewOption === VIEW_OPTIONS.MONTHLY;

    return (
        <Grid container alignItems="flex-end" px={2}>
            {showChart && (
                <>
                    <Grid
                        item
                        md={4}
                        sm={isPeriod ? 18 : 9}
                        xs={isPeriod ? 18 : 14}
                    >
                        <ReactHookFormSelect
                            control={control}
                            disabled={disabled}
                            label={t('Time range')}
                            name="view_option"
                            options={[
                                {
                                    label: t('Annual'),
                                    value: VIEW_OPTIONS.ANNUAL,
                                },
                                {
                                    label: t('Period'),
                                    value: VIEW_OPTIONS.MONTHLY,
                                },
                            ]}
                            variant="standard"
                        />
                    </Grid>
                    {viewOption === VIEW_OPTIONS.MONTHLY && (
                        <Grid item xs={14} sm={9} md={4}>
                            <ReactHookFormSelect
                                control={control}
                                disabled={disabled}
                                label={t('Period')}
                                name="date_range"
                                options={dateRangeOptions}
                                variant="standard"
                            />
                        </Grid>
                    )}
                    <Grid
                        alignItems="center"
                        display="flex"
                        gap={1}
                        item
                        xs={4}
                        sm={2}
                    >
                        <IconButton
                            disabled={disabled || !isDirty}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'text.primary',
                                '&:hover': { backgroundColor: 'primary.dark' },
                            }}
                            onClick={handleSubmit(initializeGenerationRatio)}
                        >
                            <RefreshIcon />
                        </IconButton>

                        <InfoTooltip variant="no-wrapper">
                            {t(
                                'This data is automatically generated from measurement sources and proposal. Actual billing periods may vary slightly. For more precise information, please generate a report'
                            )}
                        </InfoTooltip>
                    </Grid>
                    <Grid item xs={18} sm textAlign="right">
                        <ToggleButtonGroup
                            disabled={disabled}
                            exclusive
                            onChange={(_, value) =>
                                value !== null && handleOnChangeChartType(value)
                            }
                            size="small"
                            value={chartType}
                        >
                            {chartTypes.map((type) => (
                                <ToggleButton
                                    key={type.value}
                                    value={type.value}
                                >
                                    {type.icon}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                    </Grid>
                </>
            )}

            <Grid item xs={18}>
                <ChartJs
                    chartConfig={chartConfig}
                    chartType={chartType}
                    emptyDescription={t(
                        'Make sure you have a proposal and a measurement source assigned to the project'
                    )}
                    formConfig={initialValues}
                    isLoading={disabled}
                    showIndicators={false}
                    tableDateLabelDefault
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesGenerationRatio,
    isFetchingMeasuredEnergy: selectors.getIsFetchingMeasuredEnergy,
    isFetchingReferenceInfo: selectors.getIsFetchingReferenceInfo,
    measuredEnergy: selectors.getMeasuredEnergyData,
    powerStationsIds: afterSalesMeasurementSourceSelectors.getPowerStationsIds,
    referenceInfoConsumptionHistory:
        selectors.getReferenceInfoDataConsumptionHistory,
    referenceInfoIsBimonthly: selectors.getReferenceInfoDataIsBimonthly,
});

const mapDispatchToProps = (dispatch) => ({
    handleFetchProjectMeasuredEnergyByRange: (params) =>
        dispatch(actions.handleFetchProjectMeasuredEnergyByRange(params)),
    initializeGenerationRatio: (values) =>
        dispatch(actions.initializeGenerationRatio(values)),
    resetMeasurementSourceData: () =>
        dispatch(actions.resetMeasurementSourceData()),
});

GenerationRatioChart.propTypes = {
    afterSalesStartDate: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    dateRangeOptions: PropTypes.array,
    handleFetchProjectMeasuredEnergyByRange: PropTypes.func,
    initialValues: PropTypes.object,
    initializeGenerationRatio: PropTypes.func,
    isFetchingMeasuredEnergy: PropTypes.bool,
    isFetchingReferenceInfo: PropTypes.bool,
    loadedData: PropTypes.bool,
    measuredEnergy: PropTypes.object,
    powerStationsIds: PropTypes.array,
    referenceInfoConsumptionHistory: PropTypes.array,
    referenceInfoIsBimonthly: PropTypes.bool,
    resetMeasurementSourceData: PropTypes.func,
    selectedProjectId: PropTypes.string,
    setLoadedData: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerationRatioChart);
