import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import ChartJs from 'common/components/ChartJs';

const ChartWrapper = styled(Box)`
    filter: url('#blur');
    filter: blur(3px);

    &:after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
`;

const WrapperText = styled(Box)`
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 5px;
    color: #fff;
    height: fit-content;
    margin: auto;
    padding: 8px;
    text-align: center;
`;

const EmptyChart = ({ description }) => {
    const { t } = useTranslation();

    const CHART_COLORS = ['#008ffb', '#00e396'];
    const data = {
        datasets: [
            {
                backgroundColor: CHART_COLORS[0],
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57],
                label: '',
            },
            {
                backgroundColor: CHART_COLORS[1],
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101],
                label: '',
            },
        ],
        labels: new Array(12).fill('---'),
    };
    const options = {
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: { grid: { display: false }, ticks: { display: false } },
            y: { grid: { display: false }, ticks: { display: false } },
        },
    };

    return (
        <Box position="relative">
            <ChartWrapper>
                <ChartJs data={data} height="300px" options={options} />
            </ChartWrapper>

            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    left: 0,
                    maxWidth: { md: '500px', xs: '100%' },
                    mx: 'auto',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
            >
                <WrapperText>
                    <Typography variant="h6">
                        {t(
                            "Oops! It seems there isn't enough data available to display at the moment"
                        )}
                    </Typography>

                    {description && (
                        <Typography variant="body2">{description}</Typography>
                    )}
                </WrapperText>
            </Box>
        </Box>
    );
};

EmptyChart.propTypes = {
    description: PropTypes.string,
};

export default EmptyChart;
