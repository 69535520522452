import React, { forwardRef, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

const ResultItem = forwardRef(
    ({ action, active, currentRootActionId }, ref) => {
        const theme = useTheme();
        const ancestors = useMemo(() => {
            if (!currentRootActionId) return action.ancestors;
            const index = action.ancestors.findIndex(
                (ancestor) => ancestor.id === currentRootActionId
            );
            return action.ancestors.slice(index + 1);
        }, [action.ancestors, currentRootActionId]);

        return (
            <Box
                ref={ref}
                sx={{
                    alignItems: 'center',
                    background: active
                        ? theme.palette.background.neutral
                        : 'transparent',
                    borderLeft: `2px solid ${
                        active ? theme.palette.primary.main : 'transparent'
                    }`,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '12px 16px',
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        fontSize: 14,
                        gap: '8px',
                    }}
                >
                    {action.icon && action.icon}

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box>
                            {ancestors.length > 0 &&
                                ancestors.map((ancestor) => (
                                    <React.Fragment key={ancestor.id}>
                                        <span
                                            style={{
                                                marginRight: 8,
                                                opacity: 0.5,
                                            }}
                                        >
                                            {ancestor.name}
                                        </span>
                                        <span style={{ marginRight: 8 }}>
                                            &rsaquo;
                                        </span>
                                    </React.Fragment>
                                ))}
                            <span>{action.name}</span>
                        </Box>

                        {action.subtitle && (
                            <span style={{ fontSize: 12 }}>
                                {action.subtitle}
                            </span>
                        )}
                    </Box>
                </Box>

                {action.shortcut?.length ? (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'grid',
                            gap: '4px',
                            gridAutoFlow: 'column',
                        }}
                    >
                        {action.shortcut.map((sc, index) => (
                            <React.Fragment key={`${sc}-${index}`}>
                                <kbd
                                    style={{
                                        background: 'rgba(0 0 0 / .1)',
                                        borderRadius: '4px',
                                        fontSize: 14,
                                        padding: '4px 6px',
                                    }}
                                >
                                    {sc}
                                </kbd>

                                {index < action.shortcut.length - 1 && (
                                    <span style={{ fontSize: '12px' }}>+</span>
                                )}
                            </React.Fragment>
                        ))}
                    </Box>
                ) : null}
            </Box>
        );
    }
);

ResultItem.displayName = 'ResultItem';

ResultItem.propTypes = {
    action: PropTypes.object,
    active: PropTypes.bool.isRequired,
    currentRootActionId: PropTypes.string,
};

export default ResultItem;
