import arrayMove from 'array-move';
import i18next from 'i18next';

import {
    differenceInDaysDate,
    formatDate,
    parseDate,
    subDate,
} from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import {
    getChartOptionsEconomic,
    getProposalChartInfoPriceSeries,
} from 'common/utils/helpersChart';

import {
    DATE_FORMATS,
    DEFAULT_DATE_FORMAT,
    INDICATORS_NAMES,
    PERIOD_TYPES,
} from './constants';

export const getPeriodLabel = (finalDate, isBimonthly) => {
    if (!finalDate) return '';
    const parsedFinalDate = parseDate(finalDate, DEFAULT_DATE_FORMAT);
    const labelDate = subDate(parsedFinalDate, { days: isBimonthly ? 30 : 15 });
    return formatDate(labelDate, 'MMM yy').toUpperCase();
};

export const formatNumber = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    decimals = 2,
    style = 'decimal',
    value,
}) => {
    return numberFormat(value, {
        currency: currencyIso,
        decimals,
        locale: currencyLocale,
        style,
        symbol: currencySymbol,
    });
};

const handleUpdateOrderItems = (
    items,
    oldIndex,
    newIndex,
    setIndicatorsList
) => {
    const copiedItems = [...items];
    const updatedItems = arrayMove(copiedItems, oldIndex, newIndex);

    let newItems = [];

    updatedItems.forEach((item, index) => {
        newItems.push({ ...item, order: index });
    });

    setIndicatorsList(newItems);
};

export const getActiveIndicators = (indicatorsList) => {
    return indicatorsList.reduce((acc, current) => {
        if (current.is_active) return acc + 1;
        return acc;
    }, 0);
};

export const getIndicatorName = (key) => {
    return i18next.t(INDICATORS_NAMES[key] || '');
};

export const getIsShowIndicator = (indicatorsSettingsData, key) => {
    const indicator = indicatorsSettingsData.find((item) => item.name === key);
    return indicator?.is_active || false;
};

export const getIndicatorValue = ({
    currencyIso,
    currencyLocale,
    isCurrency,
    isNumber,
    value,
}) => {
    if (!value && value !== 0) return null;
    if (!isNumber) return value;
    if (isCurrency)
        return numberFormat(value, {
            currency: currencyIso,
            decimals: 2,
            locale: currencyLocale,
            style: 'currency',
        });

    return numberFormat(value, {
        decimals: 2,
        locale: currencyLocale,
        style: 'decimal',
    });
};

export const handleOnDragEnd = (items, result, setIndicatorsList) => {
    const { destination, source } = result;

    if (!destination) return;
    if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
    )
        return;

    handleUpdateOrderItems(
        items,
        source.index,
        destination.index,
        setIndicatorsList
    );
};

export const handleOnSelectItem = (
    indicatorsList,
    id,
    setIndicatorsList,
    value
) => {
    const newItems = indicatorsList.map((indicator) => {
        if (indicator.id === id) return { ...indicator, is_active: value };
        return indicator;
    });
    setIndicatorsList(newItems);
};

export const getCustomOfferTemplatesToSelect = (templates) => {
    if (templates === null || templates?.length === 0) return [];

    const tempFilteredTemplates = {};
    const ordered = {};
    const group = '';

    templates.forEach((template) => {
        if (!tempFilteredTemplates[group]) {
            tempFilteredTemplates[group] = {
                group: group,
                label: group,
                options: [],
            };
        }

        tempFilteredTemplates[group].options.push({
            group: group,
            label: template.title,
            value: template.id,
        });
    });

    Object.keys(tempFilteredTemplates)
        .sort()
        .forEach((key) => {
            ordered[key] = tempFilteredTemplates[key];
            ordered[key].options = tempFilteredTemplates[key].options.sort();
        });

    return Object.values(ordered);
};

const getPeriodTypeLabel = (periodType) => {
    switch (periodType) {
        case PERIOD_TYPES.HISTORICAL:
            return i18next.t('Historical');
        case PERIOD_TYPES.PROPOSAL:
        case PERIOD_TYPES.PROPOSAL_LEGACY:
            return i18next.t('Proposal');
        default:
            return '';
    }
};

const getTooltipMessage = ({ initialDate, finalDate, label }) => {
    if (!initialDate || !finalDate) return '';

    const parsedInitialDate = parseDate(initialDate, DATE_FORMATS.SUNWISE);
    const parsedFinalDate = parseDate(finalDate, DATE_FORMATS.SUNWISE);
    const labelInitialDate = formatDate(
        parsedInitialDate,
        DATE_FORMATS.PERIOD_LABEL_2
    );
    const labelFinalDate = formatDate(
        parsedFinalDate,
        DATE_FORMATS.PERIOD_LABEL_2
    );
    const diffDays = differenceInDaysDate(parsedFinalDate, parsedInitialDate);
    return `${label} - (${labelInitialDate.toUpperCase()} - ${labelFinalDate.toUpperCase()}) - (${diffDays} ${i18next.t(
        'Day',
        { count: diffDays }
    )})`;
};

export const getChartSettings = ({
    categories,
    currencyIso,
    currencyLocale,
    currencySymbol,
    energyDates,
    isCurrency,
    themeMode,
}) => {
    const formatter = (val) => {
        let config = {
            decimals: 0,
            locale: currencyLocale,
            style: 'decimal',
            unit: 'kWh',
        };

        if (isCurrency)
            config = {
                currency: currencyIso,
                locale: currencyLocale,
                style: 'currency',
                symbol: currencySymbol,
            };

        return numberFormat(val, config);
    };

    return {
        key: categories?.join('-') + Math.random(),
        options: {
            chart: { background: '#ffffff00', toolbar: { show: false } },
            chartJsOptions: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) =>
                                context.dataset.label +
                                ': ' +
                                formatter(context.parsed.y),
                            title: (context) => {
                                const ctx = context[0];
                                const { dataIndex, datasetIndex } = ctx;
                                return getTooltipMessage(
                                    energyDates?.[datasetIndex]?.[dataIndex]
                                );
                            },
                        },
                    },
                },
                scales: {
                    x: { grid: { display: false } },
                    y: { beginAtZero: true, ticks: { callback: formatter } },
                },
            },
            dataLabels: { enabled: false },
            fill: { opacity: 1 },
            legend: { show: true },
            theme: { mode: themeMode },
            tooltip: {
                x: {
                    formatter: (_, { dataPointIndex, seriesIndex }) =>
                        getTooltipMessage(
                            energyDates?.[seriesIndex]?.[dataPointIndex]
                        ),
                },
                y: {
                    title: {
                        formatter: (
                            seriesName,
                            { dataPointIndex, seriesIndex }
                        ) => {
                            const periodType = getPeriodTypeLabel(
                                energyDates?.[seriesIndex]?.[dataPointIndex]
                                    ?.periodType
                            );
                            const periodTypeText = periodType
                                ? ` (${periodType})`
                                : '';
                            return `${seriesName}${periodTypeText}:`;
                        },
                    },
                },
            },
            xaxis: { categories },
            yaxis: { decimalsInFloat: 0, labels: { formatter } },
        },
    };
};

export const getPaymentChartSettings = ({
    categories,
    currencyIso,
    currencyLocale,
    paymentPeriods,
    themeMode,
}) => {
    const _categories = categories?.reduce((acc, current) => {
        acc.push(
            ` ${i18next.t('Cost without solar')}  (${current})`,
            ` ${i18next.t('Cost with solar')}  (${current})`
        );
        return acc;
    }, []);

    return {
        options: {
            ...getChartOptionsEconomic({
                consumptionHistory: paymentPeriods,
                countryCurrencyIso: currencyIso,
                countryCurrencyLocale: currencyLocale,
                yearCategories: _categories,
            }),
            theme: { mode: themeMode },
        },
    };
};

export const getTotalInfoPrice = (priceInfo = {}) => {
    if (!priceInfo) return 0;
    let total = Object.values(priceInfo).reduce((acc, current) => {
        return acc + current;
    }, 0);
    if (typeof priceInfo?.['Billing type'] === 'number')
        total -= priceInfo?.['Billing type'];
    return total;
};

export const getChartsInfo = ({
    groupSelected = 0,
    historicalInfo,
    periodConfig,
    proposalInfo,
    proposalInfoIsBimonthly,
}) => {
    const periods = periodConfig?.groups?.[groupSelected]?.periods || [];
    const proposalPeriods = proposalInfo?.consumption_history || [];

    const categories = [];
    const energyDates = [[], []];
    const consumptionSeries = { historical: [], compared: [] };
    const generationSeries = { historical: [], compared: [] };
    let currentLabel = '';
    let paymentSeriesData = { periods: [], keys: [] };
    let paymentSeries = [];

    for (const period of periods) {
        // Current real period
        const historical = historicalInfo?.find(
            (item) => item.id === period.current_period
        );

        if (historical) {
            currentLabel = getPeriodLabel(
                historical?.final_date,
                historical?.is_bimonthly
            );
        } else {
            currentLabel = 'N/A';
        }
        categories.push(currentLabel);
        consumptionSeries.historical.push(
            historical?.total?.consumption?.value || 0
        );
        generationSeries.historical.push(
            historical?.total?.generation?.value || 0
        );
        energyDates[1].push({
            finalDate: historical?.final_date,
            initialDate: historical?.initial_date,
            label: currentLabel,
        });

        paymentSeriesData.periods.push(
            { ...(historical ?? {}), label: currentLabel },
            { ...(historical ?? {}), label: currentLabel }
        );
        paymentSeriesData.keys.push(
            'total_info_price_consumption',
            'real_info_price_consumption'
        );

        // Compared period
        switch (period?.period_type) {
            case PERIOD_TYPES.PROPOSAL_LEGACY:
            case PERIOD_TYPES.PROPOSAL: {
                const proposalPeriod = proposalPeriods?.find(
                    (item) => item?.id === period?.comparison_period
                );

                consumptionSeries.compared.push(
                    proposalPeriod?.consumption || 0
                );
                generationSeries.compared.push(proposalPeriod?.generation || 0);
                energyDates[0].push({
                    finalDate: proposalPeriod?.date_finish,
                    initialDate: proposalPeriod?.date_start,
                    label: getPeriodLabel(
                        proposalPeriod?.date_finish,
                        proposalInfoIsBimonthly
                    ),
                    periodType: period?.period_type,
                });

                break;
            }
            case PERIOD_TYPES.HISTORICAL: {
                const historical = historicalInfo?.find(
                    (item) => item.id === period?.comparison_period
                );

                consumptionSeries.compared.push(
                    historical?.total?.consumption?.value || 0
                );
                generationSeries.compared.push(
                    historical?.total?.generation?.value || 0
                );
                energyDates[0].push({
                    finalDate: historical?.final_date,
                    initialDate: historical?.initial_date,
                    label: getPeriodLabel(
                        historical?.final_date,
                        historical?.is_bimonthly
                    ),
                    periodType: period?.period_type,
                });

                break;
            }
            default: {
                consumptionSeries.compared.push(0);
                generationSeries.compared.push(0);
                energyDates[0].push({
                    finalDate: '',
                    initialDate: '',
                    label: 'N/A',
                    periodType: period?.period_type,
                });
            }
        }
    }

    paymentSeries = getProposalChartInfoPriceSeries(
        paymentSeriesData.periods,
        paymentSeriesData.keys
    );

    return {
        categories,
        consumptionSeries: [
            {
                name: i18next.t('Compared period'),
                data: consumptionSeries.compared,
            },
            {
                name: i18next.t('Current period'),
                data: consumptionSeries.historical,
            },
        ],
        energyDates,
        generationSeries: [
            {
                name: i18next.t('Compared period'),
                data: generationSeries.compared,
            },
            {
                name: i18next.t('Current period'),
                data: generationSeries.historical,
            },
        ],
        paymentPeriods: paymentSeriesData.periods,
        paymentSeries,
    };
};

export const getUniqueYearsFromPeriods = (periods, dateFields) => {
    if (!periods?.length || !dateFields?.length) return [];
    const years = new Set();
    periods
        .flatMap((row) => dateFields.map((field) => row[field]?.split('/')[2]))
        .forEach((year) => year && years.add(year));
    const mappedYears = Array.from(years, (year) => ({
        label: year,
        value: Number(year) || 0,
    }));
    return mappedYears.sort((a, b) => a.value - b.value);
};

export const getFilteredPeriodsByYear = (rows, dateFields, year) => {
    if (!rows?.length) return [];
    if (!year || !dateFields?.length) return rows;
    return rows.filter((row) =>
        dateFields.some((field) => row[field]?.includes(year))
    );
};
