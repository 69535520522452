import { types } from 'sunwise-template-core';

import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_DOCUMENT_TEMPLATES,
    FETCH_DOCUMENT_TEMPLATES_FAILURE,
    FETCH_DOCUMENT_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(multiTemplateReviewActions[FETCH_DOCUMENT_TEMPLATES]());

    getTemplates({
        orderBy: 'title',
        perPage: 100,
        sortBy: 'asc',
        type: types.SMART_DOCUMENTS_TYPE,
    })
        .then((response) => {
            const data = response.data.results.map((template) => ({
                ...template,
                id: template.external_uuid,
            }));
            dispatch(
                multiTemplateReviewActions[FETCH_DOCUMENT_TEMPLATES_SUCCESS](
                    data
                )
            );
        })
        .catch((error) =>
            dispatch(
                multiTemplateReviewActions[FETCH_DOCUMENT_TEMPLATES_FAILURE](
                    error
                )
            )
        );
};
