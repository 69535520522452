import { ZAP_COLOR } from 'common/modules/zap/constants';

export const NAME = 'supplier-integrations';

export const PROVIDERS = {
    SIPS: 0,
    ZAP: 1,
};

export const FIELD_NAMES = {
    api_key: 'API Key',
    password: 'Password',
    secret: 'Secret',
    username: 'User',
};

export const VISIBLE_USER_FIELDS = {
    api_key: true,
    username: true,
};

export const PROVIDERS_SETTINGS = {
    [PROVIDERS.ZAP]: {
        color: ZAP_COLOR,
        url: `${process.env.REACT_APP_ZAP_API_URL}/create-account`,
        name: 'Zap',
    },
    [PROVIDERS.SIPS]: {
        name: 'SIPS',
    },
};
