import get from 'lodash/get';

import contactForm from '../../contactForm';
import * as selectors from '../selectors';

export default () => (dispatch, getState) => {
    const state = getState();
    const contactData = selectors.getDataContact(state);
    const {
        advanced,
        agent,
        branch_office,
        company_name,
        company_position,
        contact_origin,
        emails,
        first_lastname,
        id,
        name,
        rfc,
        second_lastname,
        status_contact,
        status_flag,
        telephones,
    } = contactData;

    const isGlobal = branch_office === null;

    dispatch(
        contactForm.actions.initializeForm({
            advanced,
            agent: get(agent, 'id', ''),
            branch_office: isGlobal
                ? 'company'
                : get(contactData, 'branch_office.id', ''),
            company_name,
            company_position,
            contact_origin: get(contact_origin, 'id', ''),
            emails,
            first_lastname,
            id,
            is_global: isGlobal,
            name,
            rfc,
            second_lastname,
            status_contact: get(status_contact, 'id', ''),
            status_flag,
            telephones,
        })
    );
    dispatch(contactForm.actions.setIsNewRecord(false));
    dispatch(contactForm.actions.showModal(true));
};
