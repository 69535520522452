import Bugsnag from '@bugsnag/js';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import { SUBSCRIPTION_STATUS } from 'common/constants';
import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import {
    getUserSettings,
    getIanaTimezoneKey,
} from 'common/utils/helpers/multiregion';

import { arraytoDictionary, hasValue } from './index';

export const getCountryCurrency = () => {
    const session = getSessionLocalStorage();
    return get(session, 'currency_company_locale', {});
};

export const getCountryCurrencyDecimalSeparator = () => {
    const parts = getNumberFormatPartsByCountryCurrency();
    const decimalSeparator = parts.find((p) => p.type === 'decimal');
    if (decimalSeparator) return decimalSeparator.value;
    return '.';
};

export const getCountryCurrencyIso = () => {
    const currency = getCountryCurrency();
    if (!hasValue(currency, 'abbreviation')) return 'USD';
    return get(currency, 'abbreviation', 'USD');
};

export const getCountryCurrencyLocale = () => {
    const currency = getCountryCurrency();
    if (!isEmpty(currency) && !isNull(currency) && hasValue(currency, 'locale'))
        return get(currency, 'locale', 'en-US').replace(/_/g, '-');
    return 'en-US';
};

export const getCountryCurrencySymbol = () => {
    const currency = getCountryCurrency();
    if (!hasValue(currency, 'symbol')) return '$';
    return get(currency, 'symbol', '$');
};

export const getCountryCurrencyThousandSeparator = () => {
    const parts = getNumberFormatPartsByCountryCurrency();
    const thousandSeparator = parts.find((p) => p.type === 'group');
    if (thousandSeparator) return thousandSeparator.value;
    return ',';
};

export const getHasActiveSubscription = () => {
    const session = getSessionLocalStorage();
    return get(session, 'has_active_subscription', false);
};

export const getHasAllBranchOfficesAuthUser = () => {
    const session = getSessionLocalStorage();
    return get(session, 'settings.has_all_branchoffices', false);
};

export const getIsOnlyContacts = () => {
    const session = getSessionLocalStorage();
    return get(session, 'settings.has_limited_contacts', false);
};

export const getHasCancelledSubscription = () => {
    const session = getSessionLocalStorage();
    return session?.status_subscription === SUBSCRIPTION_STATUS.CANCELLED;
};

export const getHasOnboarding = () => {
    const session = getSessionLocalStorage();
    return session?.hasOnboarding;
};

export const getHasPaymentDue = () => {
    const session = getSessionLocalStorage();
    return get(session, 'has_payment_due', false);
};

export const getIsActiveSunwiseNotification = () => {
    const session = getSessionLocalStorage();
    return get(session, 'has_sunwise_update_notification', false);
};

export const getIsGeneralManagerUser = () => {
    const roleLevel = getRoleLevel();
    return roleLevel === ROLE_LEVELS.GENERAL_MANAGER;
};

export const getIsGuest = () => !localStorage.getItem('token');

export const getIsColombianAccount = () => {
    const iso = getCountryCurrencyIso();
    return iso === 'COP';
};

export const getIsMexicanAccount = () => {
    const iso = getCountryCurrencyIso();
    return iso === 'MXN';
};

export const getIsMultibranchesAccount = () => {
    const session = getSessionLocalStorage();
    if (!isEmpty(session)) return get(session, 'is_multibranches', false);
    return false;
};

export const getIsOwnerUser = () => {
    const roleLevel = getRoleLevel();
    return roleLevel === ROLE_LEVELS.OWNER;
};

export const getMainBranchOffice = () => {
    const session = getSessionLocalStorage();
    return get(session, 'main_branch_office.id', null);
};

export const getCompanyGroup = () => {
    const session = getSessionLocalStorage();
    return get(session, 'company_group', {});
};

export const getMaximumDiscount = () => {
    const companyGroup = getCompanyGroup();
    return get(companyGroup, 'maximum_discount', 0);
};

export const getNumberFormatPartsByCountryCurrency = () =>
    new Intl.NumberFormat(getCountryCurrencyLocale(), {
        style: 'currency',
        currency: getCountryCurrencyIso(),
    }).formatToParts(Math.PI * 10000);

export const getPermissionsLocalStorage = () =>
    typeof localStorage.getItem('permissions') !== 'undefined'
        ? JSON.parse(localStorage.getItem('permissions'))
        : [];

export const getPermissionsDictionary = () =>
    arraytoDictionary(getPermissionsLocalStorage(), 'id');

export const getRoleLevel = () => {
    const companyGroup = getCompanyGroup();
    return get(companyGroup, 'role_level', null);
};

export const getSessionLocalStorage = () =>
    typeof localStorage.getItem('session') !== 'undefined'
        ? JSON.parse(localStorage.getItem('session'))
        : {};

export const getToken = () => localStorage.getItem('token');

export const getUserPermissions = () => {
    const permissionsLE = getPermissionsLocalStorage();
    if (permissionsLE) {
        const enabledPermissions = permissionsLE.filter(
            (item) => item.is_enabled
        );
        return enabledPermissions.map((permission) => permission.id);
    }
    return [];
};

export const initSessionActions = (setCurrentValues) => {
    const session = getSessionLocalStorage();
    if (session) {
        if (setCurrentValues) {
            setCurrentValues({
                first_name: session.first_name,
                last_name: session.last_name,
                profile_image: session.profile_image
                    ? session.profile_image + '?' + new Date().getTime()
                    : null,
            });
        }
        if (window.Intercom && process.env.REACT_APP_INTERCOM_APP_ID) {
            const intercomInitObj = {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                created_at: Math.round(new Date().getTime() / 1000),
                email: session.email,
                name: session.first_name + ' ' + session.last_name,
            };
            const intercomExtraData = session.ss_data ? session.ss_data : {};
            window.Intercom('boot', {
                ...intercomInitObj,
                ...intercomExtraData,
            });
        }
        if (window.userGuiding) {
            const settings = getUserSettings();
            window.userGuiding.identify(session?.id, {
                email: session.email,
                isGeneralManager: getIsGeneralManagerUser(),
                isLite: false,
                isOwner: getIsOwnerUser(),
                name: session.first_name + ' ' + session.last_name,
                language: session?.settings?.language?.key,
                timezone: get(settings, 'timezone.key', getIanaTimezoneKey()),
            });
        }
        Bugsnag.addMetadata('user', {
            first_name: session.first_name,
            last_name: session.last_name,
            email: session.email,
        });
    } else {
        Bugsnag.clearMetadata('user');
    }
};

export const logoutActions = () => {
    localStorage.clear();
    window.location.replace('/login');
};

export const updateSession = (props) => {
    const session = getSessionLocalStorage();
    if (session && props) {
        localStorage.setItem(
            'session',
            JSON.stringify(Object.assign(session, props))
        );
    }
};
