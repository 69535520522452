import i18next from 'i18next';

import { updateItem } from 'common/api/v2/offerComplements';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { proposalGeneratorConfigModalActions } from '../reducer';

import closeModal from './closeModal';

export default (proposalId, values) => (dispatch) => {
    const newValues = {
        bag_years: values.bag_years,
        considerations: values.considerations,
        currency: values.currency,
        custom_offer_template: values.custom_offer_template,
        custom_rate: values.custom_rate,
        dap_value: values.dap_value,
        degradation_percentage: values.degradation_percentage,
        deprecated: values.deprecated,
        energy_export_price: values.energy_export_price,
        generation_delay: values.generation_delay,
        has_low_tension_concept: values.has_low_tension_concept || false,
        id: values.id,
        increase_percentage: values.increase_percentage,
        isr: values.isr,
        isr_percentage: values.isr_percentage,
        netted_exported_generation_price:
            values.netted_exported_generation_price,
        opex: values.opex,
        opex_percentage: values.opex_percentage_increase,
        opex_periodicity: values.opex_periodicity,
        other_concepts: values.other_concepts,
        ppa_active: values.ppa_active,
        ppa_percentage_increase: values.ppa_percentage_increase,
        ppa_price: values.ppa_price,
        proposal_plan: values.proposal_plan,
        tax_rate_percentage: values.tax_rate_percentage,
        taxes_concepts: values.taxes_concepts,
        type_change: values.type_change,
        type_change_offer: values.type_change_offer,
        type_discount: values.type_discount,
        zero_export: values.zero_export,
    };

    if (values.type_discount === '1') {
        newValues.price_watt = values.price_watt;
    } else if (values.type_discount === '2') {
        newValues.price_total = values.price_total;
    } else {
        newValues.discount = values.discount;
    }

    if (values.showPowerFactor) {
        newValues.apply_desired_power_factor =
            values.apply_desired_power_factor;
        newValues.desired_power_factor = values.desired_power_factor || 0;
    }

    dispatch(proposalGeneratorConfigModalActions[SAVE]());

    updateItem(newValues, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorConfigModalActions[SAVE_SUCCESS](
                    response.data.data
                )
            );
            dispatch(closeModal());
            showToast({
                type: 'success',
                body: i18next.t('A new configuration has been applied', {
                    name: values.name,
                }),
            });
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorConfigModalActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
