import { getPlan } from 'common/api/v1/plans';

import {
    FETCH_PLAN,
    FETCH_PLAN_FAILURE,
    FETCH_PLAN_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

import prepareUpdate from './prepareUpdate';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(planConfigurationActions[FETCH_PLAN]());

        getPlan(id)
            .then((response) => {
                const data = response.data;
                dispatch(planConfigurationActions[FETCH_PLAN_SUCCESS](data));
                dispatch(prepareUpdate(data));
            })
            .catch((error) => {
                dispatch(planConfigurationActions[FETCH_PLAN_FAILURE](error));
            })
            .finally(resolve);
    });
