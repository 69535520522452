import { doesRateRequireDivision } from 'common/utils/helpers/rates';

import { CONSUMPTIONS_CAPTURE_MODE } from '../constants';
import { getFirstDataFoundInLisaFiles } from '../helpers';
import * as selectors from '../selectors';

import fetchLocationsByZipCode from './fetchLocationsByZipCode';
import handleLisaResponseByPeriod from './handleLisaResponseByPeriod';
import setConsumptionsCaptureMode from './setConsumptionsCaptureMode';
import setConsumptionsRawData from './setConsumptionsRawData';
import setIsOpenMissingFieldsModal from './setIsOpenMissingFieldsModal';

export default ({
        consumptionsCaptureMode = CONSUMPTIONS_CAPTURE_MODE.LISA,
        fileInfo,
        fileOrigin,
        getValues,
        rawData,
        setValue,
    }) =>
    (dispatch, getState) => {
        if (!rawData) return;

        const state = getState();
        const ratesDictionary = selectors.getRatesDictionary(state);

        if (fileOrigin || fileOrigin === 0) {
            const rateConfiguration =
                selectors.getFetchScheduleRateConfigurationData(state);

            dispatch(
                handleLisaResponseByPeriod({
                    fileInfo,
                    fileOrigin,
                    getValues,
                    rateConfiguration,
                    ratesDictionary,
                    setValue,
                })
            );

            return;
        }

        dispatch(setConsumptionsRawData(rawData));
        dispatch(setConsumptionsCaptureMode(consumptionsCaptureMode));

        const isBimonthly = getFirstDataFoundInLisaFiles(
            rawData,
            'is_bimonthly'
        );
        const rateName = getFirstDataFoundInLisaFiles(rawData, 'rate');
        const zipCode = getFirstDataFoundInLisaFiles(rawData, 'zip_code');

        const ratesNameIdDictionary = selectors.getRatesNameIdDictionary(state);
        const rateId = ratesNameIdDictionary[rateName];
        const tempRate = ratesDictionary[rateId];
        const rateIsBimonhtly = tempRate?.periodicityType == '1';
        let rate = null;

        if (tempRate?.isCertified || isBimonthly === rateIsBimonhtly)
            rate = tempRate;

        const zipCodeRequired = doesRateRequireDivision(
            rate?.name,
            rate?.isCertified
        );

        setValue('political_division1', null);
        setValue('political_division2', null);
        setValue('rate_division', null);
        setValue('rate', rate?.id || null);
        setValue('zip_code', zipCode || null);

        if (!rate?.id || (zipCodeRequired && !zipCode)) {
            dispatch(setIsOpenMissingFieldsModal(true));
            return;
        }

        dispatch(
            fetchLocationsByZipCode({
                getValues,
                rate,
                setValue,
                value: zipCode,
            })
        );
    };
