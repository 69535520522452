import isEmpty from 'lodash/isEmpty';

import setShowLisaLoading from 'common/modules/lisaFiles/actions/setShowLisaLoading';
import { parseDate, subDate } from 'common/utils/dates';
import {
    calcEnergyDistributionPercentages,
    getFieldsInPeriod,
} from 'common/utils/helpers/rates';

import { DATE_FORMATS } from '../constants';
import {
    formatDateDefault,
    formatDateLabel,
    getContractedDemandFields,
    getCorrectLisaDateFormat,
    getDefaultSummerMonth,
    getFirstDataFoundInLisaFiles,
    getPeriodFromLisa,
    getSortedPeriods,
    parseDateDefault,
} from '../helpers';
import * as selectors from '../selectors';

import prepareConsumptions from './prepareConsumptions';
import resetConsumptionProfile from './resetConsumptionProfile';
import { updatePlaceholder } from './updatePlaceholders';

const STATUS_SUCCESS = 0;

const getValidHistory = ({
    consumption,
    dateFormat = DATE_FORMATS.LISA,
    daysToSubtract,
    history = [],
    representativeMonth,
}) => {
    const filteredHistory = history?.filter(
        (period) => period?.final_date && period?.initial_date
    );
    if (!filteredHistory?.length) return [];

    const sortedHistory = getSortedPeriods(filteredHistory, dateFormat);

    const firstRepresentativeMonth = getRepresentativeMonth(
        getCorrectLisaDateFormat(sortedHistory[0]?.final_date),
        daysToSubtract
    );

    if (
        firstRepresentativeMonth === representativeMonth ||
        consumption === sortedHistory?.consumption
    )
        return history.slice(1);
    return sortedHistory;
};

const getRepresentativeMonth = (date, days) =>
    subDate(parseDateDefault(date), { days }).getMonth();

const getBackupHistory = ({
    consumption,
    consumptionsRawData,
    daysToSubtract,
}) => {
    const period = consumptionsRawData.find(
        (data) => data?.completion?.history?.length > 0
    );
    const history = period?.completion?.history;
    if (!history?.length) return [];

    return getValidHistory({ consumption, daysToSubtract, history });
};

export default ({
        formValues,
        hourlyContractedDemand,
        rate,
        rateConfiguration,
    }) =>
    (dispatch, getState) => {
        dispatch(setShowLisaLoading(false));
        const state = getState();
        const consumptionsRawData = selectors.getConsumptionsRawData(state);

        if (!consumptionsRawData?.length) return;

        const ratesDictionary = selectors.getRatesDictionary(state);
        const summerMonths = selectors.getMonthsForSelect(state);
        const profilesConsumptionData =
            selectors.getProfileConsumptionData(state);
        const { tiers_energy_distribution } = rateConfiguration || {};
        const isBimonthly = rate?.isCertified
            ? getFirstDataFoundInLisaFiles(consumptionsRawData, 'is_bimonthly')
            : rate?.periodicityType == '1';
        const newValues = {
            ...formValues,
            ...getContractedDemandFields(
                getFirstDataFoundInLisaFiles(
                    consumptionsRawData,
                    'contracted_demand'
                ),
                hourlyContractedDemand
            ),
            address: getFirstDataFoundInLisaFiles(
                consumptionsRawData,
                'address'
            ),
            cnmc_data_modified: false,
            consumption_profile: null,
            csv_data_modified: false,
            has_incomplete_files: consumptionsRawData.some(
                (data) => data?.status !== STATUS_SUCCESS
            ),
            has_low_tension_concept: getFirstDataFoundInLisaFiles(
                consumptionsRawData,
                'has_low_tension_concept'
            ),
            holder: getFirstDataFoundInLisaFiles(consumptionsRawData, 'name'),
            is_bimonthly: isBimonthly ? '1' : '0',
            periodicity_type: isBimonthly ? 1 : 0,
            political_division1:
                formValues.political_division1 ||
                getFirstDataFoundInLisaFiles(
                    consumptionsRawData,
                    'political_division'
                ),
            rate: rate.id,
            rate_division_summer: getDefaultSummerMonth({
                formValues: {},
                selectedRate: rate,
                summerMonths,
            }),
            service_number: `${
                getFirstDataFoundInLisaFiles(
                    consumptionsRawData,
                    'service_number'
                ) || ''
            }`,
        };

        const totalPeriods = isBimonthly ? 6 : 12;
        const daysToSubtract = isBimonthly ? 30 : 15;

        let summaryData = getSortedPeriods(
            consumptionsRawData
                .map((data) =>
                    getPeriodFromLisa({
                        data: data?.completion,
                        file: data?.file,
                        isBimonthly,
                        rate,
                        tiers_energy_distribution,
                        url_type: data?.url_type,
                    })
                )
                .filter(Boolean)
        );

        const distribution = calcEnergyDistributionPercentages(summaryData);

        if (!isEmpty(distribution)) newValues.distribution = distribution;

        const summary = [];
        let backupHistory = [];
        let currDate = new Date();
        let history = [];
        let shiftPeriods = 0;

        const firstPeriod = summaryData.shift();
        if (firstPeriod) {
            shiftPeriods = 1;
            currDate = parseDateDefault(firstPeriod.initial_date);
            newValues.file = firstPeriod?.file;
            summary.push(firstPeriod);
        }

        if (!isEmpty(firstPeriod?.history)) {
            history = getValidHistory({
                consumption: firstPeriod?.total?.value,
                daysToSubtract,
                history: firstPeriod.history,
                representativeMonth: firstPeriod.representativeMonth,
            });
            delete firstPeriod?.history;
        } else {
            backupHistory = getBackupHistory({
                consumption: firstPeriod?.total?.value,
                consumptionsRawData,
                daysToSubtract,
            });
            if (backupHistory?.length) history = backupHistory;
        }

        delete firstPeriod?.representativeMonth;

        if (!firstPeriod && history?.[0]?.final_date)
            currDate = parseDate(history[0].final_date, DATE_FORMATS.LISA);

        for (let i = 0; i < totalPeriods - shiftPeriods; i++) {
            const newInitialDate = subDate(currDate, {
                months: isBimonthly ? 2 : 1,
            });
            let newPeriod = null;

            const representativeDate = subDate(currDate, {
                days: daysToSubtract,
            });

            if (
                representativeDate.getMonth() ===
                summaryData?.[0]?.representativeMonth
            ) {
                const final_date = formatDateDefault(currDate);
                const label = formatDateLabel(representativeDate);
                const period = summaryData.shift();

                if (!isEmpty(period.history)) {
                    history = getValidHistory({
                        consumption: period?.total?.value,
                        daysToSubtract,
                        history: period.history,
                        representativeMonth: period.representativeMonth,
                    });
                    delete period.history;
                } else history?.shift();
                delete period.representativeMonth;

                newPeriod = {
                    ...period,
                    final_date,
                    label,
                    ...getFieldsInPeriod({
                        discardValues: true,
                        finalDate: final_date,
                        initialDate: period.initial_date,
                        period,
                        rate,
                        tiers_energy_distribution,
                    }),
                };
            } else {
                const final_date = formatDateDefault(currDate);
                const initial_date = formatDateDefault(newInitialDate);
                const label = formatDateLabel(
                    subDate(currDate, { days: daysToSubtract })
                );

                newPeriod = {
                    final_date,
                    initial_date,
                    label,
                    power_factor: history?.[0]?.fp || 90,
                    season_change: false,
                    ...getFieldsInPeriod({
                        distribution: newValues.distribution,
                        finalDate: final_date,
                        initialDate: initial_date,
                        tmp_kW: history?.[0]?.demand,
                        period: {
                            total: {
                                placeholder: '0',
                                value: history?.[0]?.consumption ?? null,
                            },
                        },
                        rate,
                        tiers_energy_distribution,
                    }),
                };
                history?.shift();
            }

            summary.push(newPeriod);
            currDate = parseDateDefault(newPeriod.initial_date);
        }
        newValues.summary = summary;

        newValues.summary = updatePlaceholder({
            formValues: newValues,
            profilesConsumptionData,
            rateId: rate.id,
            ratesDictionary,
        });
        newValues.last_consumption = summary[0].final_date;

        if (formValues?.consumption_profile)
            dispatch(resetConsumptionProfile());
        dispatch(
            prepareConsumptions({
                automaticHasChanges: true,
                initialValues: newValues,
            })
        );
    };
