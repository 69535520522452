import i18next from 'i18next';
import { isEqual, isNaN, isNull } from 'lodash';

import { MAX_MARGIN_PERCENTAGE } from 'common/constants';
import { getPriceByWatt, numberFormat } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { PIE_CHART_COLORS } from './constants';

export const getDonutChartConfig = (
    currencyIso,
    currencyLocale,
    priceByWatt,
    systemSize,
    typeChange
) => {
    return {
        colors: PIE_CHART_COLORS,
        dataLabels: { enabled: false },
        labels: [
            i18next.t('Solar panel', { count: 2 }),
            i18next.t('Inverter', { count: 2 }),
            i18next.t('Structure', { count: 2 }),
            i18next.t('Workforce and electrical equipment'),
            i18next.t('Accessory', { count: 2 }),
            i18next.t('Additional', { count: 2 }),
            i18next.t('Storage'),
        ],
        legend: { show: false },
        chart: { background: '#ffffff00' },
        plotOptions: {
            pie: {
                donut: {
                    background: 'transparent',
                    size: '88%',
                    labels: {
                        show: true,
                        name: { offsetY: 25, show: true },
                        total: {
                            fontSize: '13px',
                            formatter: () =>
                                getPriceByWattFormatter(priceByWatt),
                            label: i18next.t('Price per watt without taxes'),
                            show: true,
                        },
                        value: {
                            fontSize: '18px',
                            fontWeight: 600,
                            formatter: (val) =>
                                numberFormat(val, {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                }),
                            offsetY: -20,
                            show: true,
                        },
                    },
                },
            },
        },
        stroke: { show: false },
        tooltip: {
            y: {
                formatter: (val) =>
                    getTooltipFormatter(
                        currencyIso,
                        currencyLocale,
                        systemSize,
                        typeChange,
                        val
                    ),
            },
        },
    };
};

export const getDonutChartJsConfig = ({
    currencyIso,
    currencyLocale,
    priceByWatt,
    series,
    systemSize,
    theme,
    typeChange,
}) => {
    const data = {
        datasets: [{ backgroundColor: PIE_CHART_COLORS, data: series }],
        labels: [
            i18next.t('Solar panel', { count: 2 }),
            i18next.t('Inverter', { count: 2 }),
            i18next.t('Structure', { count: 2 }),
            i18next.t('Workforce and electrical equipment'),
            i18next.t('Accessory', { count: 2 }),
            i18next.t('Additional', { count: 2 }),
            i18next.t('Storage'),
        ],
    };

    const options = {
        cutout: '80%',
        plugins: {
            doughnutLabel: {
                labels: {
                    formatter: (val) =>
                        numberFormat(val, {
                            currency: currencyIso,
                            decimals: 2,
                            locale: currencyLocale,
                            style: 'currency',
                        }),
                    text: {
                        color: theme.palette.text.primary,
                        offsetY: 10,
                        value: i18next.t('Price per watt without taxes'),
                    },
                    total: {
                        color: theme.palette.text.primary,
                        offsetY: -10,
                        value: getPriceByWattFormatter(priceByWatt),
                    },
                },
            },
            legend: { display: false },
            tooltip: {
                mode: 'index',
                position: 'mouse',
                callbacks: {
                    label: (context) =>
                        getTooltipFormatter(
                            currencyIso,
                            currencyLocale,
                            systemSize,
                            typeChange,
                            context.parsed
                        ),
                },
            },
        },
    };

    const doughnutLabel = {
        afterDraw(chart, args, pluginOptions) {
            const { ctx } = chart;
            const total = pluginOptions.labels?.total;
            const totalFont = {
                family: 'Montserrat',
                size: '18px',
                weight: 'bold',
                ...total.font,
            };

            const text = pluginOptions.labels?.text;
            const textFont = {
                family: 'Montserrat',
                size: '13px',
                weight: 'normal',
                ...text.font,
            };

            let color = text?.color ? text.color : '#000';
            let title = total?.value || '';
            let subtitle = text?.value || '';

            ctx.save();

            const x = chart.getDatasetMeta(0).data[0].x;
            const y = chart.getDatasetMeta(0).data[0].y;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            if (chart._active.length > 0) {
                title =
                    chart.config.data.datasets[chart._active[0].datasetIndex]
                        .data[chart._active[0].index];
                const formatter = pluginOptions.labels.formatter;
                if (formatter) title = formatter(title);
                subtitle = chart.config.data.labels[chart._active[0].index];
                color =
                    chart.config.data.datasets[0].backgroundColor[
                        chart._active[0].index
                    ];
            }

            ctx.font = `${totalFont.weight} ${totalFont.size} ${totalFont.family}`;
            ctx.fillStyle = total?.color ? total.color : '#000';
            ctx.fillText(title, x, y + (total?.offsetY || 0));

            ctx.font = `${textFont.weight} ${textFont.size} ${textFont.family}`;
            ctx.fillStyle = color;
            ctx.fillText(subtitle, x, y + (text?.offsetY || 0));
        },
        id: 'doughnutLabel',
    };

    return { data, options, plugins: [doughnutLabel] };
};

export const getFinalTotal = (infonavitAvailableBalance, total) =>
    infonavitAvailableBalance > total ? 0 : total - infonavitAvailableBalance;

export const getPriceByWattFormatter = (value) => {
    const priceByWattFormatted = numberFormat(value, {
        currency: 'USD',
        decimals: 4,
        style: 'currency',
    });
    return `${priceByWattFormatted} USD/Watt`;
};

export const getProfitPercentage = (subtotalWithDiscount, profit) => {
    const value = (profit * 100) / subtotalWithDiscount;
    return value.toFixed(2);
};

const getTooltipFormatter = (
    currencyIso,
    currencyLocale,
    systemSize,
    typeChange,
    val
) => {
    const priceByWattProduct = getPriceByWatt(
        currencyIso,
        val,
        systemSize,
        typeChange
    );
    const valueFormatted = numberFormat(val, {
        currency: currencyIso,
        decimals: 2,
        locale: currencyLocale,
        style: 'currency',
    });

    const priceByWattFormatted = numberFormat(priceByWattProduct, {
        currency: 'USD',
        decimals: 4,
        style: 'currency',
    });

    return `${valueFormatted} (${priceByWattFormatted} USD/Watt)`;
};

export const getTotalWithTaxes = ({ subtotalWithDiscount, totalTaxes = 0 }) =>
    subtotalWithDiscount + totalTaxes;

export const handleClickDelete = (remove, handleRemove, index, productItem) => {
    if (productItem.is_new) {
        return remove(index);
    }
    if (productItem.id) {
        return handleRemove(productItem.id, () => remove(index));
    }
    return false;
};

export const handleNormalizeFieldsBuild =
    (handleOnCreate, handleOnSave, proposalId) =>
    (index, value, previousValue, allValues, name) => {
        if (
            value &&
            !isNull(value) &&
            !isNaN(value) &&
            Number(value) != previousValue
        ) {
            const values = allValues.items[index];
            if (!isEqual(Number(value), values[`temp_${name}`])) {
                handleAction(handleOnCreate, handleOnSave, proposalId, {
                    ...values,
                    [name]: Number(value),
                });
            }
        }
    };

export const handleNormalizeDiscountField =
    (handleNormalizeFields, index, maxDiscountByUnitPrice, productItem) =>
    (value, previousValue, allValues, name) => {
        if (value > maxDiscountByUnitPrice) {
            return handleNormalizeFields(
                index,
                productItem.temp_discount,
                previousValue,
                allValues,
                name
            );
        } else {
            return handleNormalizeFields(
                index,
                value,
                previousValue,
                allValues,
                name
            );
        }
    };

export const handleNormalizePercentageDiscountField =
    (handleNormalizeFields, index, maximumDiscount) =>
    (value, previousValue, allValues, name) => {
        if (value > maximumDiscount) {
            return handleNormalizeFields(
                index,
                maximumDiscount,
                previousValue,
                allValues,
                name
            );
        } else {
            return handleNormalizeFields(
                index,
                value,
                previousValue,
                allValues,
                name
            );
        }
    };

export const handleNormalizeMarginField =
    (handleNormalizeFields, index, productItem) =>
    (value, previousValue, allValues, name) => {
        if (value > MAX_MARGIN_PERCENTAGE) {
            showToast({
                body: i18next.t('It cannot be greater than {{value}}', {
                    value: MAX_MARGIN_PERCENTAGE,
                }),
                type: 'danger',
            });
            return handleNormalizeFields(
                index,
                productItem.temp_margin,
                previousValue,
                allValues,
                name
            );
        } else {
            return handleNormalizeFields(
                index,
                value,
                previousValue,
                allValues,
                name
            );
        }
    };

export const handleNormalizeModelFieldBuild =
    (handleOnCreate, handleOnSave, proposalId) =>
    (index, value, previousValue, allValues, name) => {
        if (!isNull(value) && !isNaN(value) && value != previousValue) {
            const nameItem = name.split('.');
            handleAction(handleOnCreate, handleOnSave, proposalId, {
                ...allValues.items[index],
                [nameItem[1]]: value,
            });
        }

        return value;
    };

export const handleNormalizeModel =
    (index, handleNormalizeModelField) =>
    (value, previousValue, allValues, name) =>
        handleNormalizeModelField(index, value, previousValue, allValues, name);

export const handleNormalizeField =
    (index, handleNormalizeFields) => (value, previousValue, allValues, name) =>
        handleNormalizeFields(index, value, previousValue, allValues, name);

const handleAction = (handleOnCreate, handleOnSave, proposalId, values) => {
    if (values.is_new) {
        return handleOnCreate(proposalId, values);
    }
    if (values.item) {
        return handleOnSave(proposalId, values);
    }
    return false;
};

export const handleRemoveBuild =
    (handleOnDelete, proposalId) => (id, successCallback) =>
        handleOnDelete(id, proposalId, successCallback);
