import { getReadsByDate } from 'common/api/afterSales/projects';

import * as afterSalesMeasurementSourceSelectors from '../../afterSalesMeasurementSource/selectors';
import { transformSunwiseDateToAfterSalesFormat } from '../helpers';

export default (projectId, data) => (_, getState) =>
    new Promise((resolve, reject) => {
        const powerStations =
            afterSalesMeasurementSourceSelectors.getPowerStationsList(
                getState()
            );

        const { finalDate, force, freq, initialDate, projects, useSummaries } =
            data || {};

        if (!initialDate || !freq || !powerStations?.length) {
            resolve();
            return;
        }
        const params = {
            final_date: transformSunwiseDateToAfterSalesFormat(finalDate),
            force,
            freq,
            initial_date: transformSunwiseDateToAfterSalesFormat(initialDate),
            use_summaries: useSummaries || false,
        };

        if (projects && !projects.includes('all')) params.projects = projects;

        getReadsByDate(projectId, params)
            .then((response) => resolve(response?.data))
            .catch((error) => reject(error));
    });
