import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from 'sunwise-ui';

import { ReactHookFormCheck } from 'common/components/form/bootstrap';
import { getIsColombianAccount } from 'common/utils/helpers/session';

import { getActiveNotifications } from '../helpers';

const ReduxFieldArray = ({ control, name }) => {
    const { t } = useTranslation();
    const { fields } = useFieldArray({ control, name });
    const isColombianAccount = getIsColombianAccount();

    const renderContent = (activeNotifications, index, item) => {
        if (
            isEmpty(activeNotifications) ||
            (isColombianAccount && item.category === t('Financing'))
        )
            return null;
        return (
            <Box
                display="flex"
                flexDirection="column"
                key={`category-${index}`}
                mb={2}
                sx={{ width: '100%' }}
            >
                <Typography fontWeight="bold" variant="body1">
                    {item.category}
                </Typography>
                {activeNotifications.map((notification, i) => (
                    <Box
                        alignItems="center"
                        display="flex"
                        key={`notification-${i}`}
                    >
                        <Typography variant="body2">
                            {notification.name}
                        </Typography>
                        <Box
                            display="flex"
                            gap={1}
                            sx={{
                                ml: 'auto',
                                '.MuiFormControl-root': { mb: 0 },
                                '.MuiFormControlLabel-root': {
                                    margin: 0,
                                },
                            }}
                        >
                            <ReactHookFormCheck
                                control={control}
                                disabled={notification.web.is_locked}
                                name={`${name}.${index}.notifications.${i}.web.is_checked`}
                            />
                            <ReactHookFormCheck
                                control={control}
                                disabled={notification.email.is_locked}
                                name={`${name}.${index}.notifications.${i}.email.is_checked`}
                            />
                        </Box>
                    </Box>
                ))}
                <Divider />
            </Box>
        );
    };

    return (
        <>
            {fields.map((item, index) => {
                const activeNotifications = getActiveNotifications(
                    isColombianAccount,
                    item?.notifications
                );
                return renderContent(activeNotifications, index, item);
            })}
        </>
    );
};

ReduxFieldArray.propTypes = {
    control: PropTypes.object,
    name: PropTypes.string,
};

export default ReduxFieldArray;
