import React, { useState } from 'react';

import { styled } from '@mui/material';
import { Table, TableBody, TableContainer } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { RATES_WITH_POWER_FACTOR } from 'common/constants/rates';
import * as lisaFilesActions from 'common/modules/lisaFiles/actions';
import { parseClipboardData } from 'common/utils/helpers';
import {
    formatTierLabel,
    getDaysInPeriod,
    getIsGDRate,
    getIsHourlyRate,
} from 'common/utils/helpers/rates';
import { scroll } from 'common/utils/mixins';

import * as actions from '../../actions';
import { BASE_COLORS } from '../../constants';
import {
    getMinInitialDate,
    handleNormalizeConsumption,
    handleNormalizeFinalDateBuild,
    handleNormalizeInitialDateBuild,
    handleNormalizeTotalKwh,
    handleOnPasteConsumptionHistory,
} from '../../helpers';

import Row from './Row';
import TableHeader from './TableHeader';

const StyledTableContainer = styled(TableContainer)`
    margin-bottom: 24px;
    max-height: 75dvh;
    border-radius: 10px;

    ${scroll.custom()}
`;

const getTierColumns = (tiers, isCertified, groupKey, onRowPaste) => {
    if (!tiers?.length) return null;
    return tiers.map((tier) => ({
        key: `${groupKey}.${tier.name.toLowerCase()}`,
        label: formatTierLabel(tier.name, isCertified),
        objectValue: true,
        onRowPaste,
    }));
};

const getDemandColumns = ({
    hasDemandTiers,
    isCollapsed,
    isGDRate,
    rateIsCertified,
    t,
    tiers,
}) => {
    if (isCollapsed)
        return [{ label: rateIsCertified ? t('Billed') : t('Max') }];
    if (hasDemandTiers)
        return getTierColumns(tiers, rateIsCertified, 'kW') || [];
    if (isGDRate)
        return [{ key: 'kW.peak', label: t('Demand'), objectValue: true }];
};

const getEnergyColumns = ({
    handleChangeKWh,
    handleOnChangeDailyAvg,
    handleOnChangeTotal,
    hasEnergyTiers,
    isCollapsed,
    rateIsCertified,
    showAvgDailyField,
    t,
    tiers,
}) => {
    const totalColumn = {
        key: 'total',
        label: t('Total'),
        objectValue: true,
        onRowPaste: handleOnChangeTotal,
    };

    if (isCollapsed) return [totalColumn];
    if (showAvgDailyField)
        return [
            {
                key: 'dailyAvg',
                label: t('Daily average'),
                objectValue: true,
                onRowPaste: handleOnChangeDailyAvg,
            },
        ];
    if (hasEnergyTiers)
        return getTierColumns(tiers, rateIsCertified, 'kWh', handleChangeKWh);

    return [totalColumn];
};

const getPeriodColumns = ({ isCollapsed, t }) => {
    if (isCollapsed) return [{ isSticky: true, label: t('Month') }];

    return [
        { label: t('File') },
        { label: t('Initial date') },
        { label: t('Final date') },
        { label: t('Days in period') },
    ];
};

const PeriodColumns = ({
    control,
    disabled,
    disabledConsumptionFieldsByCsv,
    getValues,
    handleDownloadLisaFile,
    handleNormalizeDates,
    handleUploadPDFReciptFile,
    name = 'summary',
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    selectedRate,
    setValue,
    summary,
    summaryWarnings,
}) => {
    const [collapsedSections, setCollapsedSections] = useState({
        demand: false,
        energy: false,
        period: true,
    });
    const { t } = useTranslation();

    const energyTiers =
        rateConfiguration?.tiers_energy_distribution?.[0]?.tiers;

    const rateIsCertified = selectedRate?.isCertified;
    const hasPowerFactor =
        rateIsCertified && RATES_WITH_POWER_FACTOR.includes(selectedRate?.name);
    const isHourlyRate = getIsHourlyRate(selectedRate);
    const isGDRate = getIsGDRate(selectedRate);
    const hasEnergyTiers = isHourlyRate && energyTiers?.length > 0;
    const showAvgDailyField =
        !rateIsCertified &&
        !isHourlyRate &&
        selectedRate?.periodicityType === '0';

    const minDate = getMinInitialDate();

    const toggleCollapse = (section) =>
        setCollapsedSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));

    const handleOnChangeDailyAvg = ({ index, updatePlaceholders = true }) => {
        const period = getValues(`${name}.${index}`);

        const dailyAvg = period?.dailyAvg?.value || 0;
        const daysInPeriod = getDaysInPeriod(period);

        const newValue = dailyAvg * daysInPeriod || 0;
        setValue(`${name}.${index}.updatedManual`, true);
        setValue(`${name}.${index}.total`, {
            placeholder: `${newValue || 0}`,
            value: newValue,
        });

        if (updatePlaceholders)
            actions.updatePlaceholder({
                formValues: getValues(),
                profilesConsumptionData,
                ratesDictionary,
                setValue,
            });
    };

    const handleOnChangeTotal = ({ index, updatePlaceholders }) =>
        handleNormalizeTotalKwh({
            getValues,
            index,
            profilesConsumptionData,
            ratesDictionary,
            setValue,
            updatePlaceholders,
        });

    const handleChangeKWh = ({ field, index, updatePlaceholders }) =>
        handleNormalizeConsumption({
            field,
            getValues,
            index,
            profilesConsumptionData,
            ratesDictionary,
            setValue,
            updatePlaceholders,
        });

    const columnGroups = [
        {
            bgColor: BASE_COLORS.PERIOD,
            columns: getPeriodColumns({
                isCollapsed: collapsedSections.period,
                t,
            }),
            isCollapsible: true,
            isExpanded: !collapsedSections.period,
            isSticky: collapsedSections.period,
            label: t('Period'),
            onClick: () => toggleCollapse('period'),
            useSameColor: true,
        },
        {
            bgColor: BASE_COLORS.ENERGY,
            label: `${t('Energy')} (kWh)`,
            onClick: () => toggleCollapse('energy'),
            columns: getEnergyColumns({
                handleChangeKWh,
                handleOnChangeDailyAvg,
                handleOnChangeTotal,
                hasEnergyTiers,
                isCollapsed: collapsedSections.energy,
                rateIsCertified,
                showAvgDailyField,
                t,
                tiers: energyTiers,
            }),
            isCollapsible: showAvgDailyField || hasEnergyTiers,
            isExpanded: !collapsedSections.energy,
        },
    ];

    if (isGDRate) {
        columnGroups.push({
            bgColor: BASE_COLORS.DEMAND,
            label: `${t('Demand')} (kW)`,
            onClick: () => toggleCollapse('demand'),
            columns: getDemandColumns({
                hasDemandTiers: hasEnergyTiers,
                isCollapsed: collapsedSections.demand,
                isGDRate,
                rateIsCertified,
                t,
                tiers: energyTiers,
            }),
            isCollapsible: hasEnergyTiers,
            isExpanded: !collapsedSections.demand,
        });
    }

    if (hasPowerFactor) {
        columnGroups.push({
            bgColor: BASE_COLORS.POWER_FACTOR,
            columns: [
                { key: 'power_factor', label: `${t('Power factor')} (%)` },
            ],
        });
    }

    const handleOnPaste = ({ columnOrigin, event, rowOrigin }) => {
        if (!event?.clipboardData) return;

        event.preventDefault();
        const data = parseClipboardData(
            event.clipboardData.getData('text/plain')
        );

        handleOnPasteConsumptionHistory({
            columnGroups,
            columnOrigin,
            data,
            getValues,
            rowOrigin,
            setValue,
        });

        actions.updatePlaceholder({
            formValues: getValues(),
            profilesConsumptionData,
            ratesDictionary,
            setValue,
        });
    };

    return (
        <StyledTableContainer>
            <Table stickyHeader>
                <TableHeader columnGroups={columnGroups} />

                <TableBody>
                    {summary?.map((period, index) => (
                        <Row
                            collapsedSections={collapsedSections}
                            control={control}
                            demandTiers={energyTiers}
                            disabled={disabled}
                            disabledConsumptionFieldsByCsv={
                                disabledConsumptionFieldsByCsv
                            }
                            energyTiers={energyTiers}
                            finalDateDisabled={index > 0}
                            handleChangeKWh={(field) =>
                                handleChangeKWh({ field, index })
                            }
                            handleDownloadLisaFile={handleDownloadLisaFile}
                            handleNormalizeFinalDateBuild={() => {
                                if (index > 0) return;
                                handleNormalizeFinalDateBuild({
                                    getValues,
                                    handleNormalizeDates,
                                    profilesConsumptionData,
                                    rateConfiguration,
                                    ratesDictionary,
                                    setValue,
                                });
                            }}
                            handleNormalizeInitialDateBuild={(value) =>
                                handleNormalizeInitialDateBuild({
                                    getValues,
                                    handleNormalizeDates,
                                    index,
                                    profilesConsumptionData,
                                    rateConfiguration,
                                    ratesDictionary,
                                    setValue,
                                    value,
                                })
                            }
                            handleOnChangeDailyAvg={() =>
                                handleOnChangeDailyAvg({ index })
                            }
                            handleOnChangeTotal={() =>
                                handleOnChangeTotal({ index })
                            }
                            handleOnPaste={(event, columnOrigin) =>
                                handleOnPaste({
                                    columnOrigin,
                                    event,
                                    rowOrigin: index,
                                })
                            }
                            handleUploadFile={(file) =>
                                handleUploadPDFReciptFile({
                                    file,
                                    getValues,
                                    index,
                                    rateConfiguration,
                                    ratesDictionary,
                                    setValue,
                                })
                            }
                            hasPowerFactor={hasPowerFactor}
                            isGDRate={isGDRate}
                            isHourlyRate={isHourlyRate}
                            isLastRow={index === summary.length - 1}
                            key={`${name}.${index}`}
                            minDate={minDate}
                            name={`${name}.${index}`}
                            period={period}
                            periodWarnings={summaryWarnings?.[index]}
                            selectedRate={selectedRate}
                            showAvgDailyField={showAvgDailyField}
                        />
                    ))}
                </TableBody>
            </Table>
        </StyledTableContainer>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleDownloadLisaFile: (fileName) =>
        dispatch(lisaFilesActions.downloadFileFromName(fileName)),
    handleUploadPDFReciptFile: (config = {}) =>
        dispatch(actions.handleUploadPDFReciptFile(config)),
});

PeriodColumns.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    getValues: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    handleUploadPDFReciptFile: PropTypes.func,
    name: PropTypes.string,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
    summary: PropTypes.array,
    summaryWarnings: PropTypes.array,
};

export default connect(null, mapDispatchToProps)(PeriodColumns);
