import { getTemplates } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATE_CATALOGS,
    FETCH_TEMPLATE_CATALOGS_FAILURE,
    FETCH_TEMPLATE_CATALOGS_SUCCESS,
} from '../actionTypes';
import { financialProductActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(financialProductActions[FETCH_TEMPLATE_CATALOGS]());

    return getTemplates({
        orderBy: 'title',
        perPage: 100,
        sortBy: 'asc',
        status: null,
    })
        .then((response) => {
            const data = response.data.results.map((template) => ({
                ...template,
                id: template.external_uuid,
            }));
            dispatch(
                financialProductActions[FETCH_TEMPLATE_CATALOGS_SUCCESS](data)
            );
        })
        .catch((error) =>
            dispatch(
                financialProductActions[FETCH_TEMPLATE_CATALOGS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
