import React, { useContext, useEffect, useState } from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Container as MaterialContainer,
    Grid,
    Tab,
    Tabs,
    TabPanel,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { useScrollTop } from 'common/hooks';
import { LoadingContext } from 'common/utils/contexts';
import { validateAccessWithPermissions } from 'common/utils/helpers/permissionsV2';
import parseQueryString from 'common/utils/parseQueryString';

import * as branchOfficcesActions from '../branchOffices/actions';
import * as companySelectors from '../companyGeneral/selectors';
import contactForm from '../contactForm';
import documentContactForm from '../documentContactForm';
import project from '../project';
import supportContactForm from '../supportContactForm';
import trackings from '../trackings';

import * as actions from './actions';
import { CardContact, CardLocation } from './components';
import {
    determineTab,
    determineType,
    determineView,
    isValidParam,
} from './helpers';
import * as selectors from './selectors';

const Container = ({
    companyPositionLatLng,
    contactPosition,
    dataContact,
    fetchBranchOfficces,
    fetchContact,
    fetchPositions,
    fetchStatusContact,
    getPermissionsByCode,
    history,
    isLoadingContact,
    isLoadingContactPosition,
    location,
    markFavoriteContact,
    match,
    prepareFormContact,
    savePosition,
    statusContactToSelect,
    updateContactsType,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const { search: locationSearch = '' } = location;
    const {
        tab = 'projects',
        project: projectId,
        view: tabView,
        type,
    } = parseQueryString(locationSearch);

    const [contactTab, setContactTab] = useState(tab);
    const [projectTab, setProjectTab] = useState(tabView);
    const [proposalTab, setProposalTab] = useState(type);
    const [editModeLocation, setEditModeLocation] = useState(false);
    const contactId = match?.params?.uid;

    useScrollTop();

    useEffect(() => {
        fetchBranchOfficces();
        fetchContact(contactId);
        fetchPositions(contactId);
    }, [contactId]);

    const handleChange = (_, newValue) => {
        setContactTab(newValue);
        history.push({
            pathname: location.pathname,
            search: `?tab=${newValue}`,
        });
    };

    const handleOpenProject = (value) => {
        if (!canViewContactProjects) return false;
        history.push({
            pathname: location.pathname,
            search: `?tab=projects${
                value ? `&project=${value}&view=${tabView}&type=${type}` : ''
            }`,
        });
    };

    const { canView: canViewContactDocuments } = getPermissionsByCode(
        PERMISSION_LIST.PERSONAL_DOCUMENTS_PERMISSION
    );
    const { canView: canViewContactInfo } = getPermissionsByCode(
        PERMISSION_LIST.CONTACTS_PERMISSION
    );
    const { canView: canViewContactLocation } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_LOCATION_PERMISSION
    );
    const { canView: canViewContactProjects } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION
    );
    const { canView: canViewSupport } = getPermissionsByCode(
        PERMISSION_LIST.SUPPORT_CONTACTS_PERMISSION
    );
    const { canView: canViewTrackingContact } = getPermissionsByCode(
        PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION
    );
    const showInformationTab = validateAccessWithPermissions([
        PERMISSION_LIST.CONTACT_LOCATION_PERMISSION,
        PERMISSION_LIST.CONTACTS_PERMISSION,
        PERMISSION_LIST.PERSONAL_DOCUMENTS_PERMISSION,
        PERMISSION_LIST.SUPPORT_CONTACTS_PERMISSION,
    ]);

    // Project permissions
    const { canView: canViewMonitoring } = getPermissionsByCode(
        PERMISSION_LIST.MONITORING_PERMISSION
    );
    const { canView: canViewReports } = getPermissionsByCode(
        PERMISSION_LIST.AFTER_SALES_PERMISSION
    );
    const showProposalsTab = validateAccessWithPermissions([
        PERMISSION_LIST.COMMERCIAL_OFFER_DOCUMENTS_PERMISSION,
        PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION,
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
        PERMISSION_LIST.PROJECT_LOCATION_PERMISSION,
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    ]);

    // Proposals permissions
    const { canView: canViewProposals } = getPermissionsByCode(
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION
    );
    const { canView: canViewSummaries } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION
    );

    useEffect(() => {
        const params = {};

        if (tab) {
            const newTab = determineTab(
                canViewContactProjects,
                canViewTrackingContact,
                showInformationTab,
                tab
            );
            if (isValidParam(newTab)) {
                params.tab = newTab;
                setContactTab(newTab);
            }
        }

        if (projectId) params.project = projectId;

        if (tabView) {
            const newTabView = determineView(
                canViewMonitoring,
                canViewReports,
                showProposalsTab,
                tabView
            );
            if (isValidParam(newTabView)) {
                params.view = newTabView;
                setProjectTab(newTabView);
            }
        }

        if (!tabView && params.tab === 'projects') {
            const defaultTabView = showProposalsTab
                ? 'proposals'
                : 'monitoring';

            const newTabView = determineView(
                canViewMonitoring,
                canViewReports,
                showProposalsTab,
                defaultTabView
            );
            if (isValidParam(newTabView)) {
                params.view = newTabView;
                setProjectTab(newTabView);
            }
        }

        if (type) {
            const newType = determineType(
                canViewProposals,
                canViewSummaries,
                type
            );
            if (isValidParam(newType)) {
                params.type = newType;
                setProposalTab(newType);
            }
        }

        const queryString = new URLSearchParams(params).toString();
        history.push({
            pathname: location.pathname,
            search: queryString,
        });
    }, [projectId, showInformationTab, showProposalsTab, tab, tabView, type]);

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon
                        icon={
                            <ArticleIcon
                                fontSize="medium"
                                sx={{ color: '#ff9a00' }}
                            />
                        }
                        title={t("{{userName}}'s record", {
                            userName: dataContact?.name || '',
                        })}
                    />
                </Grid>
            </HeaderContainer>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={contactTab} onChange={handleChange}>
                    {canViewContactProjects && (
                        <Tab
                            key="tab-projects"
                            id="tab-projects"
                            label={t('Project', { count: 2 })}
                            value="projects"
                        />
                    )}
                    {showInformationTab && (
                        <Tab
                            key="tab-information"
                            id="tab-information"
                            label={t('Information')}
                            value="information"
                        />
                    )}
                    {canViewTrackingContact && (
                        <Tab
                            key="tab-tracking"
                            id="tab-tracking"
                            label={t('Follow-up')}
                            value="tracking"
                        />
                    )}
                </Tabs>
            </Box>

            <TabPanel
                key="tab-panel-projects"
                selectedTab={contactTab}
                value="projects"
            >
                <project.Container
                    handleOpenProject={handleOpenProject}
                    projectTab={projectTab}
                    proposalTab={proposalTab}
                    setProjectTab={setProjectTab}
                    setProposalTab={setProposalTab}
                />
            </TabPanel>

            <TabPanel
                key="tab-panel-information"
                selectedTab={contactTab}
                value="information"
            >
                <Grid container>
                    <Grid
                        item
                        md={9}
                        xs={
                            canViewContactDocuments || canViewContactLocation
                                ? 9
                                : 18
                        }
                    >
                        <CardContact
                            contact={dataContact}
                            fetchStatusContact={fetchStatusContact}
                            handleClickFavorite={markFavoriteContact}
                            handleClickType={updateContactsType}
                            handleClickUpdate={() => prepareFormContact()}
                            isLoadingContact={isLoadingContact}
                            statusContactToSelect={statusContactToSelect}
                        />
                        <supportContactForm.Container contactId={contactId} />
                    </Grid>
                    <Grid
                        item
                        md={9}
                        xs={canViewContactInfo || canViewSupport ? 9 : 18}
                    >
                        <CardLocation
                            companyPositionLatLng={companyPositionLatLng}
                            contactPosition={contactPosition}
                            editModeLocation={editModeLocation}
                            isLoadingContact={isLoadingContact}
                            isLoadingContactPosition={isLoadingContactPosition}
                            loadingContext={loadingContext}
                            match={match}
                            savePosition={savePosition}
                            setEditModeLocation={setEditModeLocation}
                        />
                        <documentContactForm.Container contactId={contactId} />
                    </Grid>
                </Grid>
            </TabPanel>

            <TabPanel
                key="tab-panel-tracking"
                selectedTab={contactTab}
                value="tracking"
            >
                <trackings.Container
                    contactId={contactId}
                    dataContact={dataContact}
                />
            </TabPanel>

            <contactForm.Container
                handleAfterSave={() => fetchContact(contactId)}
            />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    companyPositionLatLng: companySelectors.getCompanyPositionLatLng,
    contactPosition: selectors.getPositionData,
    dataContact: selectors.getDataContact,
    isLoadingContact: selectors.getIsLoadingContact,
    isLoadingContactPosition: selectors.getIsLoadingPosition,
    statusContactToSelect: selectors.getStatusContactToSelect,
});

Container.propTypes = {
    companyPositionLatLng: PropTypes.object,
    contactPosition: PropTypes.object,
    dataContact: PropTypes.object,
    fetchBranchOfficces: PropTypes.func,
    fetchContact: PropTypes.func,
    fetchPositions: PropTypes.func,
    fetchStatusContact: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    history: PropTypes.object,
    isLoadingContact: PropTypes.bool,
    isLoadingContactPosition: PropTypes.bool,
    location: PropTypes.object,
    markFavoriteContact: PropTypes.func,
    match: PropTypes.object,
    prepareFormContact: PropTypes.func,
    savePosition: PropTypes.func,
    statusContactToSelect: PropTypes.array,
    updateContactsType: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    fetchBranchOfficces: () => dispatch(branchOfficcesActions.filterItems()),
    fetchContact: (uid) => dispatch(actions.fetchContact(uid)),
    fetchPositions: (uid) => dispatch(actions.fetchPositions(uid)),
    fetchStatusContact: () => dispatch(actions.fetchStatusContact()),
    markFavoriteContact: () => dispatch(actions.markFavoriteContact()),
    prepareFormContact: () => dispatch(actions.prepareFormContact()),
    redirect: (link) => dispatch(push(link)),
    savePosition: (values, contactId, successCallback, failureCallback) =>
        dispatch(
            actions.savePosition(
                values,
                contactId,
                successCallback,
                failureCallback
            )
        ),
    updateContactsType: (type) => dispatch(actions.updateContactsType(type)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.AFTER_SALES_PERMISSION,
        PERMISSION_LIST.COMMERCIAL_OFFER_DOCUMENTS_PERMISSION,
        PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION,
        PERMISSION_LIST.CONTACT_LOCATION_PERMISSION,
        PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION,
        PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
        PERMISSION_LIST.CONTACTS_PERMISSION,
        PERMISSION_LIST.MONITORING_PERMISSION,
        PERMISSION_LIST.PERSONAL_DOCUMENTS_PERMISSION,
        PERMISSION_LIST.PROJECT_LOCATION_PERMISSION,
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
        PERMISSION_LIST.SUPPORT_CONTACTS_PERMISSION,
        PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
    ]),
    withRouter
)(Container);
