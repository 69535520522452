import { updateTemplate } from 'common/api/v1/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';
import { getDataFetchCustomTemplate } from '../selectors';

export default (templateId, data) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        if (!templateId) return;

        const state = getState();
        const templatePrevData = getDataFetchCustomTemplate(state);

        const values = {
            page_size: data.page_size,
            pages: data.pages_template.map((page) => {
                const prevDataPage =
                    templatePrevData.pages_template.find(
                        (prevPage) => prevPage.id === page.id
                    ) || {};

                let data = {
                    ...prevDataPage,
                    content: JSON.parse(page.content),
                    page: page.page,
                };

                if (page.id !== '') data = { ...data, id: page.id };

                return data;
            }),
        };

        dispatch(editTemplateViewActions[UPDATE_TEMPLATE]());

        updateTemplate(templateId, values)
            .then((response) => {
                const data = {
                    ...response.data,
                    id: response.data.external_uuid,
                    pages: response.data.pages.length,
                    pages_template: response.data.pages.map((page) => ({
                        ...page,
                        content: JSON.stringify(page.content),
                    })),
                };

                dispatch(editTemplateViewActions[UPDATE_TEMPLATE_SUCCESS]());

                resolve(data);
            })
            .catch((error) => {
                dispatch(
                    editTemplateViewActions[UPDATE_TEMPLATE_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                reject();
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    });
