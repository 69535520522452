import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Skeleton } from 'sunwise-ui';

import CategoryPreviewItem from 'common/components/CategoryPreviewItem';
import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';
import IconPreviewItem from 'common/components/IconPreviewItem';
import { useBreakpoint } from 'common/hooks';

import PdfDocument from 'resources/pdfDocument.svg';

import * as templateLibrarySelectors from '../../../templateLibrary/selectors';
import * as actions from '../../actions';
import { getClonePagesTemplatesByCategoryAndLanguage } from '../../helpers';
import * as selectors from '../../selectors';

import DefaultTemplatePreviewItem from './DefaultTemplatePreviewItem';
import TemplatePagesSelector from './TemplatePagesSelector';

const DefaultTemplatesTab = ({
    categorySelected,
    cleanTemplatePreview,
    clonePages,
    customTemplate,
    fetchTemplatePreview,
    handleSelectTemplate,
    isFetchingCustomTemplate,
    isFetchingTemplateCategories,
    isForSummaryTemplate,
    languagesDictionary,
    selectedTemplate,
    proposalsNumber,
    selectCategory,
    selectedPages,
    setSelectedPages,
    setSelectedTemplate,
    templateBaseType,
    templateCategories,
    templatePreviewData,
    templatePreviewPages,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [items, setItems] = useState(6);

    useEffect(() => {
        return () => {
            cleanTemplatePreview();
        };
    }, []);

    useEffect(() => {
        if (['xs', 'sm'].includes(breakpoint)) setItems(2);
        if (['md'].includes(breakpoint)) setItems(3);
        setItems(6);
    }, [breakpoint]);

    if (isFetchingCustomTemplate || isFetchingTemplateCategories)
        return (
            <Grid container mb={2}>
                {Array.from(new Array(items)).map((_, index) => (
                    <Grid item key={`skeleton-${index}`} lg={3} md={6} xs={9}>
                        <Skeleton height={218} variant="rounded" />
                    </Grid>
                ))}
            </Grid>
        );

    if (isNull(categorySelected)) {
        const parsedTemplateCategories = templateCategories.map(
            (templateCategory) => {
                const count = getClonePagesTemplatesByCategoryAndLanguage({
                    category: templateCategory.id,
                    clonePages,
                    language: customTemplate.language,
                    proposalsNumber,
                    templateBaseType,
                }).length;
                return { ...templateCategory, count };
            }
        );

        return (
            <Grid container>
                <Grid item xs={18} md={6} lg={3}>
                    <IconPreviewItem
                        icon={<AddIcon fontSize="large" />}
                        isSelected={
                            selectedTemplate !== null &&
                            selectedTemplate.id === 'blank-page'
                        }
                        onClick={() =>
                            handleSelectTemplate({ id: 'blank-page', pages: 1 })
                        }
                        text={t('Blank template')}
                    />
                </Grid>

                {!isForSummaryTemplate &&
                    parsedTemplateCategories
                        .filter(({ count }) => count > 0)
                        .map((templateCategory) => (
                            <Grid
                                item
                                key={templateCategory.id}
                                lg={3}
                                md={6}
                                xs={18}
                            >
                                <CategoryPreviewItem
                                    id={templateCategory.id}
                                    name={templateCategory.name}
                                    onClick={() => {
                                        setSelectedTemplate(null);
                                        selectCategory(templateCategory.id);
                                    }}
                                    templatesCount={templateCategory.count}
                                    urlIcon={`${process.env.REACT_APP_S3_MEDIA_PATH}${templateCategory.file_url}`}
                                />
                            </Grid>
                        ))}
            </Grid>
        );
    }
    return (
        <Grid container>
            {selectedTemplate &&
                templatePreviewData &&
                templatePreviewPages && (
                    <>
                        <Grid item xs={18}>
                            <CollapsedBreadcrumbs
                                current={selectedTemplate?.title}
                                locations={[
                                    {
                                        action: () => {
                                            if (templatePreviewData) {
                                                cleanTemplatePreview();
                                                setSelectedTemplate(null);
                                            } else selectCategory(null);
                                        },
                                        label: t('Template', { count: 2 }),
                                    },
                                ]}
                            />
                        </Grid>
                        <TemplatePagesSelector
                            language={customTemplate.language}
                            selectedPages={selectedPages}
                            setSelectedPages={setSelectedPages}
                            templatePreviewPages={templatePreviewPages}
                        />
                    </>
                )}

            {!templatePreviewData && (
                <>
                    <Grid item lg={3} md={6} xs={18}>
                        <IconPreviewItem
                            icon={<ArrowBackIosNewIcon fontSize="large" />}
                            onClick={() => {
                                if (templatePreviewData) {
                                    cleanTemplatePreview();
                                    setSelectedTemplate(null);
                                } else selectCategory(null);
                            }}
                            text={t('Back')}
                        />
                    </Grid>

                    {getClonePagesTemplatesByCategoryAndLanguage({
                        category: categorySelected,
                        clonePages,
                        language: customTemplate.language,
                        proposalsNumber,
                        templateBaseType,
                    }).map((clonePage) => (
                        <Grid item xs={18} md={6} lg={3} key={clonePage.id}>
                            <DefaultTemplatePreviewItem
                                isLocked={clonePage.locked === 1}
                                isSelected={
                                    selectedTemplate?.id === clonePage.id
                                }
                                language={customTemplate.language}
                                languagesDictionary={languagesDictionary}
                                onClick={() => {
                                    fetchTemplatePreview(clonePage.id);
                                    handleSelectTemplate({
                                        id: clonePage.id,
                                        pages: clonePage.total_pages,
                                        title: clonePage.title,
                                    });
                                }}
                                previewImage={
                                    clonePage?.image_thumbnail
                                        ? clonePage.image_thumbnail
                                        : PdfDocument
                                }
                                text={clonePage.title}
                            />
                        </Grid>
                    ))}
                </>
            )}
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    clonePages: selectors.getDefaultClonePagesData,
    customTemplate: selectors.getDataFetchCustomTemplate,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isFetchingTemplateCategories:
        templateLibrarySelectors.getIsFetchingTemplateCategories,
    languagesDictionary: templateLibrarySelectors.getLanguagesDictionary,
    templateCategories: templateLibrarySelectors.getTemplateCategoriesData,
    templatePreviewData: selectors.getTemplatePreviewData,
    templatePreviewIsFetching: selectors.getTemplatePreviewIsFetching,
    templatePreviewPages: selectors.getTemplatePreviewPages,
});

const mapDispatchToProps = (dispatch) => ({
    cleanTemplatePreview: () => dispatch(actions.cleanTemplatePreview()),
    fetchTemplatePreview: (uuid) =>
        dispatch(actions.fetchTemplatePreview(uuid)),
});

DefaultTemplatesTab.propTypes = {
    categorySelected: PropTypes.string,
    cleanTemplatePreview: PropTypes.func,
    clonePages: PropTypes.array,
    customTemplate: PropTypes.object,
    fetchTemplatePreview: PropTypes.func,
    handleSelectTemplate: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingTemplateCategories: PropTypes.bool,
    isForSummaryTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    proposalsNumber: PropTypes.number,
    selectCategory: PropTypes.func,
    selectedPages: PropTypes.array,
    selectedTemplate: PropTypes.object,
    setSelectedPages: PropTypes.func,
    setSelectedTemplate: PropTypes.func,
    templateBaseType: PropTypes.number,
    templateCategories: PropTypes.array,
    templatePreviewData: PropTypes.object,
    templatePreviewPages: PropTypes.array,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DefaultTemplatesTab);
