import get from 'lodash/get';

import { getCategories } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATE_CATEGORIES,
    FETCH_TEMPLATE_CATEGORIES_FAILURE,
    FETCH_TEMPLATE_CATEGORIES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default () => (dispatch) =>
    new Promise((resolve) => {
        dispatch(templateLibraryActions[FETCH_TEMPLATE_CATEGORIES]());

        getCategories()
            .then((response) => {
                dispatch(
                    templateLibraryActions[FETCH_TEMPLATE_CATEGORIES_SUCCESS](
                        response.data.results
                    )
                );
            })
            .catch((error) =>
                dispatch(
                    templateLibraryActions[FETCH_TEMPLATE_CATEGORIES_FAILURE](
                        get(error, ['response', 'data', 'errors'], null)
                    )
                )
            )
            .finally(resolve);
    });
