import isEmpty from 'lodash/isEmpty';

import { formatDate, parseDate, subDate } from 'common/utils/dates';

export const getElectricBillPerMonths = ({ isBimonthly = false, lines }) => {
    if (lines && !isEmpty(lines)) {
        const filteredKwh = lines.filter((line) => line.url);

        const daysToSubstract = isBimonthly ? 30 : 15;

        return filteredKwh.map((item) => {
            const label = formatDate(
                subDate(parseDate(item.final_date, 'dd/MM/yyyy'), {
                    days: Math.floor(daysToSubstract),
                }),
                'MMM yy'
            );

            return {
                id: Math.random(),
                label: label,
                url: item.url,
                urlType: item.url_type,
            };
        });
    }
    return [];
};
