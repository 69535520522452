import { isEmpty } from 'lodash';
import { bool, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        agent: string().required(REQUIRED_TEXT).nullable(),
        branch_office: string().required(REQUIRED_TEXT).nullable(),
        contact_origin: string().required(REQUIRED_TEXT).nullable(),
        external_custom_template_id: string()
            .required(REQUIRED_TEXT)
            .nullable(),
        install_type: object()
            .shape({
                RATE: bool().nullable(),
                INSTALLATION_TYPE: bool().nullable(),
            })
            .required(REQUIRED_TEXT)
            .test(
                'required',
                REQUIRED_TEXT,
                (obj) =>
                    !isEmpty(obj) &&
                    Object.values(obj).some((type) => type === true)
            )
            .nullable(),
        method_consumtion: object()
            .shape({
                ENERGY: bool().nullable(),
                MONEY: bool().nullable(),
            })
            .required(REQUIRED_TEXT)
            .test(
                'required',
                REQUIRED_TEXT,
                (obj) =>
                    !isEmpty(obj) &&
                    Object.values(obj).some((meth) => meth === true)
            )
            .nullable(),
        status_project: string().required(REQUIRED_TEXT).nullable(),
    });
};
