import i18next from 'i18next';

import { fillReads } from 'common/api/afterSales/projects';
import showToast from 'common/utils/showToast';

import {
    FILL_EMPTY_READS,
    FILL_EMPTY_READS_FAILURE,
    FILL_EMPTY_READS_SUCCESS,
} from '../actionTypes';
import {
    getDateRange,
    transformSunwiseDateToAfterSalesFormat,
} from '../helpers';
import { actions } from '../reducer';

export default (sunwiseProjectId, data) => (dispatch) => {
    const { date, view_option } = data || {};

    if (!date) return;

    dispatch(actions[FILL_EMPTY_READS]());

    const { finalDate, initialDate } = getDateRange(date, view_option);

    const params = {
        final_date: transformSunwiseDateToAfterSalesFormat(finalDate),
        initial_date: transformSunwiseDateToAfterSalesFormat(initialDate),
    };

    fillReads(sunwiseProjectId, params)
        .then(() => {
            dispatch(actions[FILL_EMPTY_READS_SUCCESS]({ fetched: true }));

            showToast({
                body: i18next.t('Fetching missing reads in the background'),
            });
        })
        .catch((error) =>
            dispatch(actions[FILL_EMPTY_READS_FAILURE](error?.response))
        );
};
