import { format as formatDate, isMatch, parse as parseDate } from 'date-fns';
import i18next from 'i18next';
import Papaparse from 'papaparse';

import { getLocaleEquivalenceForDateFns } from 'common/utils/helpers/multiregion';

import {
    COLUMN_TYPES,
    DATE_FORMATS,
    DEFAULT_FORMATS,
    TIME_FORMATS,
} from './constants';

export const getFormatsByType = (type) => {
    switch (type) {
        case COLUMN_TYPES.DATE:
            return DATE_FORMATS;
        case COLUMN_TYPES.TIME:
            return TIME_FORMATS;
        case COLUMN_TYPES.NUMBER:
            return [
                {
                    label: `${i18next.t('Decimal separator')} (.)`,
                    value: '.',
                },
                {
                    label: `${i18next.t('Decimal separator')} (,)`,
                    value: ',',
                },
            ];
        default:
            return [];
    }
};

export const getValidColumnOptions = ({ columnsMatch, fields }) => {
    if (!fields?.length) return [];
    const columnsOptions = fields.filter(Boolean);
    const columnsMatched = Object.values(columnsMatch || {})
        .filter(Boolean)
        .reduce((acc, el) => ({ ...acc, [el]: true }), {});

    return columnsOptions.map((field) => ({
        disabled: columnsMatched[field],
        label: field,
        value: field,
    }));
};

const sanitizeNumberString = (value, decimalSeparator = '.') => {
    if (typeof value !== 'string') return Number(value);
    const cleaned = value.replace(
        new RegExp(`[^0-9${decimalSeparator}]`, 'g'),
        ''
    );
    const normalized = cleaned.replace(decimalSeparator, '.');
    return parseFloat(normalized);
};

export const getIsValidFormat = ({ format, type, value }) => {
    if (!value) return true;
    const defaultFormat = DEFAULT_FORMATS[type];
    switch (type) {
        case COLUMN_TYPES.DATE:
        case COLUMN_TYPES.TIME:
            return isMatch(value, format || defaultFormat);
        case COLUMN_TYPES.NUMBER:
            return !Number.isNaN(
                sanitizeNumberString(value, format || defaultFormat)
            );
        case COLUMN_TYPES.INTEGER:
            return Number.isInteger(
                sanitizeNumberString(value, format || defaultFormat)
            );
        default:
            return true;
    }
};

export const getIsVisible = (visible, getValues) => {
    if (!visible) return false;
    if (typeof visible === 'function') {
        const formValues = getValues();
        return visible(formValues.columnsMatch, formValues.columnsFormat);
    }
    return true;
};

const getFormattedData = ({
    format,
    localeEquivalenceForDateFns,
    referenceDate = new Date(),
    toFormat,
    type,
    value,
}) => {
    const defaultFormat = DEFAULT_FORMATS[type || ''];
    switch (type) {
        case COLUMN_TYPES.DATE:
        case COLUMN_TYPES.TIME: {
            try {
                const parsedDate = parseDate(
                    String(value),
                    format || defaultFormat,
                    referenceDate,
                    { locale: localeEquivalenceForDateFns }
                );
                if (
                    !(parsedDate instanceof Date) ||
                    isNaN(parsedDate.getTime())
                )
                    return null;
                if (!toFormat) return parsedDate;
                return (
                    formatDate(parsedDate, toFormat, {
                        locale: localeEquivalenceForDateFns,
                    }) || null
                );
            } catch {
                return null;
            }
        }
        case COLUMN_TYPES.NUMBER:
            return sanitizeNumberString(value, format || defaultFormat) || 0;
        case COLUMN_TYPES.INTEGER:
            return (
                Math.floor(
                    sanitizeNumberString(value, format || defaultFormat)
                ) || 0
            );
        default:
            return value;
    }
};

export const getMatchedDataWithFormats = ({
    columns,
    columnsFormat,
    matchedData,
}) => {
    if (!matchedData?.length) return matchedData;

    const localeEquivalenceForDateFns = getLocaleEquivalenceForDateFns();
    const referenceDate = new Date();

    const newMatchedData = matchedData.map((row) => {
        const newRow = { ...row };
        if (!columns?.length) return newRow;

        for (const column of columns) {
            const {
                columnName,
                shouldFormat = true,
                toFormat,
                type,
            } = column || {};
            const format = columnsFormat[columnName];

            if (!shouldFormat) continue;

            if (newRow.__errors?.[columnName]) newRow[columnName] = null;

            newRow[columnName] = getFormattedData({
                format,
                localeEquivalenceForDateFns,
                referenceDate,
                toFormat,
                type,
                value: newRow[columnName],
            });
        }
        return newRow;
    });

    return newMatchedData;
};

export const getMatchedData = ({
    columns,
    columnsFormat,
    columnsMatch,
    data,
}) => {
    const errorRows = new Set();
    let hasErrors = false;

    const getValues = () => ({ columnsMatch, columnsFormat });

    const matchedData = data?.filter(Boolean).map((row, i) => {
        const newRow = { id: i, __errors: {} };
        if (!columns?.length) return newRow;

        for (const column of columns) {
            const {
                columnName,
                required = false,
                type,
                validate,
                visible = true,
            } = column || {};
            const match = columnsMatch[columnName];
            const format = columnsFormat[columnName];

            newRow[columnName] = row[match]?.trim() ?? null;

            const isVisible = getIsVisible(visible, getValues);

            if (!isVisible) continue;

            const isValidFormat = getIsValidFormat({
                format,
                type,
                value: newRow[columnName],
            });

            if (!isValidFormat && newRow.__errors) {
                newRow.__errors[columnName] = i18next.t(
                    'The selected format does not match the value'
                );
                errorRows.add(i + 1);
                hasErrors = true;
                continue;
            }

            if (required && !newRow[columnName] && newRow.__errors) {
                newRow.__errors[columnName] = i18next.t(
                    'This field is required'
                );
                errorRows.add(i + 1);
                hasErrors = true;
                continue;
            }

            if (!validate || !newRow.__errors) continue;

            const validation = validate(newRow[columnName]);
            if (validation.success) continue;

            newRow.__errors[columnName] = validation.message || '';
            errorRows.add(i + 1);
            hasErrors = true;
        }
        return newRow;
    });

    return { errorRows: [...errorRows], hasErrors, matchedData };
};

export const readCsv = (file, onComplete, onError) => {
    if (!file) return { error: i18next.t('No file provided'), success: false };

    Papaparse.parse(file, {
        complete: onComplete,
        header: true,
        skipEmptyLines: true,
        error: (error) => {
            if (onError) onError({ error: error?.message, success: false });
        },
    });
};

/** Auto-match **/

// calculate string similarity using Levenshtein distance
const getLevenshteinDistance = (a, b) => {
    const an = a ? a.length : 0;
    const bn = b ? b.length : 0;
    if (an === 0) return bn;
    if (bn === 0) return an;

    const matrix = Array.from({ length: an + 1 }, (_, i) => [i]).map((row, i) =>
        row.concat(Array(bn).fill(i))
    );

    for (let i = 1; i <= an; i++) {
        for (let j = 1; j <= bn; j++) {
            if (a[i - 1] === b[j - 1]) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j] + 1
                );
            }
        }
    }
    return matrix[an][bn];
};

/**
 * Normalize and sanitize an identifier string by:
 * - Lowercasing
 * - Replacing spaces, underscores, hyphens, parentheses, brackets, and braces with underscores
 * - Removing any other character that is not a letter, number or underscore
 */
const normalizeAndSanitizeIdentifier = (str) =>
    str
        ?.toLowerCase()
        .replace(/[\s_\-()\[\]{}]+|[^a-z0-9_]+/g, (match) =>
            /[\s_\-()\[\]{}]+/.test(match) ? '_' : ''
        );

const calculateSimilarity = (a, b) => {
    if (!a || !b) return 0;

    const cleanA = normalizeAndSanitizeIdentifier(a);
    const cleanB = normalizeAndSanitizeIdentifier(b);

    const distance = getLevenshteinDistance(cleanA, cleanB);

    return 1 - distance / cleanA.length;
};

export const autoMatchColumns = ({ columns, getValues, originColumns }) => {
    const matches = {};
    const usedOriginColumns = new Set();

    // order columns by matching priority column.matchPriority
    const columnsByPriority = [...columns].sort(
        (a, b) => (b.matchPriority || 0) - (a.matchPriority || 0)
    );

    for (const column of columnsByPriority) {
        const {
            autoMatchThreshold = 0.8,
            columnName,
            matchAliases,
            visible = true,
        } = column || {};
        if (!columnName) continue;

        const isColumnVisible = getIsVisible(visible, getValues);

        if (!isColumnVisible) continue;

        let bestMatch = null;
        let bestSimilarity = 0;

        for (const originColumn of originColumns) {
            if (usedOriginColumns.has(originColumn)) continue;

            // Compare with the column name
            let similarity = calculateSimilarity(columnName, originColumn);

            // Compare with aliases if available
            if (matchAliases?.length) {
                for (const alias of matchAliases) {
                    const aliasSimilarity = calculateSimilarity(
                        alias,
                        originColumn
                    );

                    if (aliasSimilarity > similarity)
                        similarity = aliasSimilarity;
                }
            }

            if (similarity > bestSimilarity) {
                bestSimilarity = similarity;
                bestMatch = originColumn;
            }
        }

        if (!bestMatch || bestSimilarity < autoMatchThreshold) continue;

        matches[columnName] = bestMatch;
        usedOriginColumns.add(bestMatch);
    }

    return matches;
};

/** Auto-detect format **/
const autoDetectDateFormat = (values, locale) => {
    for (const format of DATE_FORMATS) {
        try {
            const isValid = values.every((value) =>
                isMatch(value, format.value, { locale })
            );

            if (isValid) return format.value;
        } catch {
            continue;
        }
    }

    return null;
};

const autoDetectNumberFormat = (values) => {
    const decimalSeparators = ['.', ','];

    for (const separator of decimalSeparators) {
        const isValid = values.every(
            (value) => !Number.isNaN(sanitizeNumberString(value, separator))
        );

        if (isValid) return separator;
    }

    return null;
};

export const autoDetectFormat = ({ columns, columnsMatch, values }) => {
    // Pick samples from the first 5 rows, last 5 rows and 5 random rows
    const samples = [
        ...values.slice(0, 5),
        ...values.slice(-5),
        ...Array.from(
            { length: Math.min(5, values.length) },
            () => values[Math.floor(Math.random() * values.length)]
        ),
    ];

    const localeEquivalenceForDateFns = getLocaleEquivalenceForDateFns();
    const detectedFormats = {};

    for (const column of columns) {
        if (!column?.columnName) continue;

        const { columnName, type } = column;

        const values = samples.map((row) => {
            const match = columnsMatch[columnName];
            return row[match || ''] || '';
        });

        switch (type) {
            case COLUMN_TYPES.DATE:
            case COLUMN_TYPES.TIME:
                detectedFormats[columnName] = autoDetectDateFormat(
                    values,
                    localeEquivalenceForDateFns
                );
                break;
            case COLUMN_TYPES.NUMBER:
                detectedFormats[columnName] = autoDetectNumberFormat(values);
                break;
            default:
                break;
        }
    }

    return detectedFormats;
};
