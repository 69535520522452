import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import fillEmptyReads from './fillEmptyReads';

export default (sunwiseProjectId, data) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Confirm'),
        haveTextCaptcha: true,
        messages: [
            i18next.t(
                'Are you sure you want to retry the missing reads? This process may take some time and could delay other read operations'
            ),
        ],
        onSuccess: () => dispatch(fillEmptyReads(sunwiseProjectId, data)),
        textToValidate: i18next.t('Retry missing reads').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
