import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { KeyIndicator } from 'common/components';
import ChartJs from 'common/components/ChartJs';
import { IconPanel } from 'common/components/icons';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as actions from '../../actions';
import {
    getCategories,
    getKeyIndicatorTitle,
    getSeries,
} from '../../IrradiationHelpers';
import * as selectors from '../../selectors';

import ChartControls from './ChartControls';

const IrradiationChart = ({
    hasChanges,
    irradiationData,
    formValues,
    segments,
}) => {
    const [controlValues, setControlValues] = useState({
        day: 0,
        month: 0,
        period: 0,
        segment: 'all',
        week: 0,
    });

    const countryCurrencyLocale = getCountryCurrencyLocale();

    useEffect(() => {
        setControlValues((values) => ({ ...values, ...formValues }));
    }, [formValues]);

    const handleOnControlsChange = (value, name) =>
        setControlValues((values) => ({ ...values, [name]: value }));

    if (irradiationData.length === 0 || segments.length === 0) return null;

    const series = getSeries(controlValues, irradiationData);

    const showKeyIndicator =
        Array.isArray(series[0].data) && series[0].data.length > 0;

    const data = {
        datasets: series.map(({ data, name }) => ({ data, label: name })),
        labels: getCategories(controlValues),
    };

    const options = {
        interaction: { axis: 'x', intersect: false, mode: 'nearest' },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            numberFormat(context.parsed.y / 1000, {
                                decimals: 2,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'kW',
                            })
                        );
                    },
                },
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: { ticks: { display: false } },
        },
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <ChartControls
                controlValues={controlValues}
                handleOnControlsChange={handleOnControlsChange}
                segments={segments}
            />

            <Box sx={{ mt: 1 }}>
                <ChartJs data={data} options={options} type="line" />

                {showKeyIndicator && (
                    <KeyIndicator
                        svgIcon={<IconPanel />}
                        title={getKeyIndicatorTitle(controlValues.period)}
                        value={numberFormat(
                            series[0].data.reduce(
                                (acc, cur) => acc + cur / 1000,
                                0
                            ),
                            {
                                decimals: 2,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'kWh',
                            }
                        )}
                    />
                )}

                {hasChanges && (
                    <Box
                        sx={{
                            backdropFilter: 'blur(10px)',
                            bottom: 0,
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: '-5px',
                            zIndex: 100,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

IrradiationChart.propTypes = {
    hasChanges: PropTypes.bool,
    formValues: PropTypes.object,
    irradiationData: PropTypes.array,
    segments: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    irradiationData: selectors.getIrradiationData,
});

const mapDispatchToProps = (dispatch) => ({
    saveIrradiationData: (uuid, values, callback) =>
        dispatch(actions.saveIrradiationData(uuid, values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IrradiationChart);
