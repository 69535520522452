import React from 'react';

import BoltIcon from '@mui/icons-material/Bolt';
import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AccordionCard from 'common/components/accordions/AccordionCard';
import ProposalChartJs from 'common/components/charts/ProposalChartJs';
import { getCurrencyLocale } from 'common/utils/helpers';

import { getAnualSolarGenerationData } from '../helpers';

import AdvancedSettings from './AdvancedSettings';
import CardForm from './CardForm';

const CardPanel = ({
    averageIrradiation,
    consumptionProjection,
    countryCurrency,
    isLoading,
    isLocked,
    offerConfigGeneration,
    offerData,
    proposalConfiguration,
    proposalId,
    solarConsumption,
    solarSimulationData,
    sunHours,
    systemSize,
}) => {
    const { t } = useTranslation();

    const countryCurrencyLocale = getCurrencyLocale(countryCurrency);

    let anualSolarGeneration = 0;

    if (consumptionProjection?.consumptionHistory?.[0])
        anualSolarGeneration = getAnualSolarGenerationData(
            consumptionProjection.consumptionHistory[0]
        );

    return (
        <AccordionCard
            cardId="proposal_generator_consumption_vs_generation_card"
            defaultExpanded
            isLoading={isLoading}
            svgIcon={<BoltIcon sx={{ color: 'primary.main' }} />}
            title={t('Consumption vs Generation Analysis')}
        >
            <CardForm
                isLoading={isLoading}
                isLocked={isLocked}
                solarConsumption={solarConsumption}
            />

            <AdvancedSettings
                anualSolarGeneration={anualSolarGeneration}
                averageIrradiation={averageIrradiation}
                currencyLocale={countryCurrencyLocale}
                isLoading={isLoading}
                isLocked={isLocked}
                offerConfigGeneration={offerConfigGeneration}
                proposalConfiguration={proposalConfiguration}
                proposalId={proposalId}
                sunHours={sunHours}
                systemSize={systemSize}
            />

            {offerData?.missing_pricing_rows === true && (
                <Alert severity="warning" sx={{ mt: 2 }}>
                    {t(
                        'One or more costing periods do not exist in our database. We have assumed the most recent price in our database for those periods'
                    )}
                </Alert>
            )}

            <ProposalChartJs
                consumptionProjection={consumptionProjection}
                countryCurrency={countryCurrency}
                countryCurrencyLocale={countryCurrencyLocale}
                proposalConfiguration={proposalConfiguration}
                solarSimulationData={solarSimulationData}
            />
        </AccordionCard>
    );
};

CardPanel.propTypes = {
    averageIrradiation: PropTypes.number,
    consumptionProjection: PropTypes.object,
    countryCurrency: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    offerConfigGeneration: PropTypes.object,
    offerData: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    proposalId: PropTypes.string,
    solarConsumption: PropTypes.number,
    solarSimulationData: PropTypes.object,
    sunHours: PropTypes.number,
    systemSize: PropTypes.number,
};

export default CardPanel;
